/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HasMany, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import { Attribute as FormAttribute } from 'ngx-form-object';

import { ModelMetadata } from '../classes/model-metadata/model-metadata';
import { Ingredient } from '../models/ingredient.model';
import { NutrientDetail } from '../models/nutrient-detail.model';

@JsonApiModelConfig({
	type: 'nutritionInfo',
	meta: ModelMetadata,
})
export class NutritionInfo extends JsonApiModel {
	public config = null;

	@Attribute()
	@FormAttribute()
	public id: string;

	@Attribute()
	@FormAttribute()
	public ifc: string;

	@Attribute({
		dataType: Date,
	})
	@FormAttribute()
	public lastRefreshed: Date;

	@Attribute()
	@FormAttribute()
	public provider: string;

	@Attribute()
	@FormAttribute()
	public providerId: string;

	@Attribute()
	@FormAttribute()
	public providerText: string;

	@Attribute()
	@FormAttribute()
	public referenceQuantity: number;

	@Attribute()
	@FormAttribute()
	public referenceUnit: string;

	@Attribute()
	@FormAttribute()
	public source: string;

	@HasMany()
	public ingredients: Array<Ingredient>;

	@HasMany()
	public nutrientDetails: Array<NutrientDetail>;
}
