<div class="container">
	<h2>Login</h2>

	<form *ngIf="!isLoading; else progressSpinner" [formGroup]="devLoginForm" (ngSubmit)="onSubmit()">
		<form-field label="{{ 'login.username' | translate }}">
			<input-field
				formControlName="username"
				id="js-e2e-username"
				placeholder="{{ 'login.username' | translate }}"
			></input-field>
		</form-field>

		<form-field label="{{ 'login.password' | translate }}">
			<input-field
				formControlName="password"
				id="js-e2e-password"
				type="password"
				placeholder="{{ 'login.password' | translate }}"
			></input-field>
		</form-field>

		<button class="button" color="primary" id="js-e2e-login-button" mat-raised-button type="submit">
			Login
		</button>
	</form>

	<div *ngIf="isLoading">
		<mat-progress-spinner class="progress-spinner" mode="indeterminate"> </mat-progress-spinner>
	</div>

	<div *ngIf="showFastLogins" class="fast-login">
		Fast logins are removed bacause of the privacy issues.
	</div>

	<ng-template #progressSpinner>
		<div *ngIf="showShortcuts" class="shortcuts">
			<div>S + L - show fast logins</div>
		</div>
	</ng-template>
</div>
