/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute } from 'ngx-form-object';
import { Attribute, ModelConfig, HasOne } from 'ngx-hal';

import { Translation } from './translation.model';
import { Content } from './content.model';

@ModelConfig({
	type: 'RecipeTranslation',
})
export class RecipeTranslation extends Translation {
	@Attribute({
		externalName: 'name',
	})
	@FormAttribute()
	public title: string;

	@Attribute()
	@FormAttribute()
	public description: string;

	@Attribute()
	@FormAttribute()
	public keywords: string;

	// Adding Recipe type here will cause a bug:
	// Refresh screen on a v1 content creation page and then navigate to
	// recipe creation screen -> An errors occurs modelClass.constructor is not a function
	@HasOne({
		propertyClass: null,
	})
	public recipe: Content;
}
