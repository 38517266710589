/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { sortDirectionData } from 'app/enums/sort-direction.enum';
import { ISortParam } from 'app/components/list-view/interfaces/sort-param.interface';

export function transformSortParams(sortParams: Array<ISortParam>): Array<string> {
	return sortParams.map((sortParam: ISortParam) => {
		if (sortParam && !(sortParam instanceof Function)) {
			return `${sortDirectionData[sortParam.direction].prefix}${sortParam.fieldName}`;
		}
	});
}
