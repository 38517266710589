/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Component } from '@angular/core';

@Component({
	selector: 'access-denied-container',
	templateUrl: './access-denied-container.component.html',
	styleUrls: ['./access-denied-container.component.scss'],
})
export class AccessDeniedContainerComponent {}
