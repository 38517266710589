/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Category } from '../../../models/category.model';

export function isCategoryAncestor(parentCategory: Category, ancestorSlug: string): boolean {
	if (!parentCategory) {
		return false;
	}

	if (parentCategory.slug === ancestorSlug) {
		return true;
	}

	return isCategoryAncestor(parentCategory.parent, ancestorSlug);
}
