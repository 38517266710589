/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as AttributeNgxHal, HasOne, ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from '../../services/datastore/models/hal-datastore-model.model';

@ModelConfig({
	type: 'AttributeOption',
	// endpoint is empty, because we'll fetch this resource only as a relationship on the Attribute resource
	endpoint: '',
})
export class AttributeOption extends HalDatastoreModel {
	public static modelType = 'AttributeOption';

	@AttributeNgxHal()
	public isPreselected: boolean;

	@AttributeNgxHal()
	public label: string;

	@AttributeNgxHal()
	public identifier: string;

	@HasOne({
		propertyClass: null,
	})
	public value: unknown;

	public get valueLink(): string {
		return this.links.value?.href;
	}
}
