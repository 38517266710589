/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { getTranslationForLanguage } from 'app/utils/helpers/helpers';
import { Language } from 'app/enums/language.enum';

@Pipe({ name: 'getTranslationForLanguage' })
export class GetTranslationForLanguage implements PipeTransform {
	public transform<T>(
		translations: Array<T>,
		language: Language,
		languageProperty?: string,
		defaultLanguage?: Language,
		skipDefault?: boolean,
	): T {
		return getTranslationForLanguage(
			translations,
			language,
			languageProperty,
			defaultLanguage,
			skipDefault,
		);
	}
}
