/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, BelongsTo, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';

import { ModelMetadata } from '../classes/model-metadata/model-metadata';
import { Language } from '../enums/language.enum';
import { TranslationStatus } from '../enums/translation-status.enum';
import { IModelTranslation } from '../interfaces/model-translation.interface';
import { NutritionClaimContent } from '../models/nutrition-claim-content.model';

@JsonApiModelConfig({
	type: 'nutritionClaimContentTranslations',
	meta: ModelMetadata,
})
export class NutritionClaimContentTranslation extends JsonApiModel implements IModelTranslation {
	public config = null;

	@Attribute()
	public description: string;

	@Attribute()
	public language: Language;

	@Attribute()
	public status: TranslationStatus;

	@Attribute()
	public title: string;

	@BelongsTo()
	public nutritionClaimContent: NutritionClaimContent;
}
