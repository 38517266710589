/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum RecipeStepOperation {
	PREPARATION = 'PREPARATION',
	PROCESSING = 'PROCESSING',
}

export const recipeStepOperationData: object = {
	[RecipeStepOperation.PREPARATION]: {
		translationKey: 'recipeStep.preparationStep.label',
	},
	[RecipeStepOperation.PROCESSING]: {
		translationKey: 'recipeStep.processingStep.label',
	},
};
