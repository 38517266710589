/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export function loadEnvironmentVariables() {
	return new Promise<any>((resolve) => {
		const xmlhttp = new XMLHttpRequest();
		const method = 'GET';
		const url = './assets/environments/environment-variables.json';

		xmlhttp.open(method, url, true);

		xmlhttp.onload = () => {
			if (xmlhttp.status === 200) {
				resolve(JSON.parse(xmlhttp.responseText));
			} else {
				resolve({});
			}
		};

		xmlhttp.send();
	});
}
