/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HasOne, ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Accessory } from './accessory.hal.model';
import { Device } from './device.hal.model';
import { RecipeStep } from './recipe-step.model';
import { Tool } from './tool.model';

@ModelConfig({
	type: 'RecipeStepProduct',
})
export class RecipeStepProduct extends HalDatastoreModel {
	@HasOne({
		propertyClass: Accessory,
		includeInPayload: true,
	})
	public accessory: Accessory;

	@HasOne({
		propertyClass: Device,
		includeInPayload: true,
	})
	public device: Device;

	@HasOne({
		propertyClass: Tool,
		includeInPayload: true,
	})
	public tool: Tool;

	public recipeStep: RecipeStep;
}
