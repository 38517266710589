/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

// Returns a new array with items which are present in array1 but not in array2
export function difference(array1: Array<any>, array2: Array<any>): Array<any> {
	const set2: Set<any> = new Set(array2);
	const result: Set<any> = new Set(array1.filter((item) => !set2.has(item)));

	return Array.from(result);
}
