/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { Language } from '../enums/language.enum';
import ar_AEJson from './ar_AE.json';
import ar_BHJson from './ar_BH.json';
import ar_JOJson from './ar_JO.json';
import ar_KWJson from './ar_KW.json';
import ar_OMJson from './ar_OM.json';
import ar_SAJson from './ar_SA.json';
import ar_QAJson from './ar_QA.json';
import bg_BGJson from './bg_BG.json';
import cs_CZJson from './cs_CZ.json';
import da_DKJson from './da_DK.json';
import de_CHJson from './de_CH.json';
import de_DEJson from './de_DE.json';
import el_CYJson from './el_CY.json';
import el_GRJson from './el_GR.json';
import en_AUJson from './en_AU.json';
import en_INJson from './en_IN.json';
import en_GBJson from './en_GB.json';
import en_NZJson from './en_NZ.json';
import en_PHJson from './en_PH.json';
import en_SGJson from './en_SG.json';
import en_USJson from './en_US.json';
import en_ZAJson from './en_ZA.json';
import es_ARJson from './es_AR.json';
import es_CLJson from './es_CL.json';
import es_ESJson from './es_ES.json';
import et_EEJson from './et_EE.json';
import fi_FIJson from './fi_FI.json';
import fr_BEJson from './fr_BE.json';
import fr_CAJson from './fr_CA.json';
import fr_CHJson from './fr_CH.json';
import fr_MUJson from './fr_MU.json';
import fr_FRJson from './fr_FR.json';
import he_ILJson from './he_IL.json';
import hi_INJson from './hi_IN.json';
import hr_HRJson from './hr_HR.json';
import hu_HUJson from './hu_HU.json';
import id_IDJson from './id_ID.json';
import it_ITJson from './it_IT.json';
import ko_KRJson from './ko_KR.json';
import lt_LTJson from './lt_LT.json';
import lv_LVJson from './lv_LV.json';
import mk_MKJson from './mk_MK.json';
import nb_NOJson from './nb_NO.json';
import nl_BEJson from './nl_BE.json';
import nl_NLJson from './nl_NL.json';
import pl_PLJson from './pl_PL.json';
import pt_BRJson from './pt_BR.json';
import pt_PTJson from './pt_PT.json';
import ro_ROJson from './ro_RO.json';
import ru_RUJson from './ru_RU.json';
import ru_UAJson from './ru_UA.json';
import sk_SKJson from './sk_SK.json';
import sl_SIJson from './sl_SI.json';
import sq_ALJson from './sq_AL.json';
import sr_RSJson from './sr_RS.json';
import sv_SEJson from './sv_SE.json';
import th_THJson from './th_TH.json';
import tr_TRJson from './tr_TR.json';
import uk_UAJson from './uk_UA.json';
import vi_VNJson from './vi_VN.json';
import zh_CNJson from './zh_CN.json';
import zh_HKJson from './zh_HK.json';
import zh_MOJson from './zh_MO.json';
import zh_TWJson from './zh_TW.json';

@Injectable()
export class TranslationsLoader implements TranslateLoader {
	private readonly translations: Partial<Record<Language, Record<string, unknown>>> = {
		[Language.ALBANIAN]: sq_ALJson,
		[Language.ARABIC_BAHRAIN]: ar_BHJson,
		[Language.ARABIC_JORDAN]: ar_JOJson,
		[Language.ARABIC_KUWAIT]: ar_KWJson,
		[Language.ARABIC_OMAN]: ar_OMJson,
		[Language.ARABIC_QATAR]: ar_QAJson,
		[Language.ARABIC_SAUDI_ARABIA]: ar_SAJson,
		[Language.ARABIC]: ar_AEJson,
		[Language.BAHASA_INDONESIA]: id_IDJson,
		[Language.BULGARIAN]: bg_BGJson,
		[Language.CHINESE]: zh_CNJson,
		[Language.CHINESE_HONG_KONG]: zh_HKJson,
		[Language.CHINESE_MACAU]: zh_MOJson,
		[Language.CHINESE_TAIWAN]: zh_TWJson,
		[Language.CROATIAN]: hr_HRJson,
		[Language.CZECH]: cs_CZJson,
		[Language.DANISH]: da_DKJson,
		[Language.DUTCH_BELGIUM]: nl_BEJson,
		[Language.DUTCH]: nl_NLJson,
		[Language.ENGLISH]: en_GBJson,
		[Language.ENGLISH_AUSTRALIA]: en_AUJson,
		[Language.ENGLISH_INDIA]: en_INJson,
		[Language.ENGLISH_NEW_ZEALAND]: en_NZJson,
		[Language.ENGLISH_PHILIPPINES]: en_PHJson,
		[Language.ENGLISH_SINGAPORE]: en_SGJson,
		[Language.ENGLISH_SOUTH_AFRICA]: en_ZAJson,
		[Language.ENGLISH_UNITED_STATES]: en_USJson,
		[Language.ESTONIAN]: et_EEJson,
		[Language.FINNISH]: fi_FIJson,
		[Language.FRENCH]: fr_FRJson,
		[Language.FRENCH_BELGIUM]: fr_BEJson,
		[Language.FRENCH_CANADA]: fr_CAJson,
		[Language.FRENCH_MAURITIUS]: fr_MUJson,
		[Language.FRENCH_SWITZERLAND]: fr_CHJson,
		[Language.GERMAN]: de_DEJson,
		[Language.GERMAN_SWITZERLAND]: de_CHJson,
		[Language.GREEK]: el_GRJson,
		[Language.GREEK_CYPRUS]: el_CYJson,
		[Language.HEBREW]: he_ILJson,
		[Language.HINDI_INDIA]: hi_INJson,
		[Language.HUNGARIAN]: hu_HUJson,
		[Language.ITALIAN]: it_ITJson,
		[Language.KOREAN]: ko_KRJson,
		[Language.LATVIAN]: lv_LVJson,
		[Language.LITHUANIAN]: lt_LTJson,
		[Language.MACEDONIAN]: mk_MKJson,
		[Language.NORWEGIAN]: nb_NOJson,
		[Language.POLISH]: pl_PLJson,
		[Language.PORTUGUESE]: pt_PTJson,
		[Language.PORTUGUESE_BRAZIL]: pt_BRJson,
		[Language.ROMANIAN]: ro_ROJson,
		[Language.RUSSIAN]: ru_RUJson,
		[Language.RUSSIAN_UKRAINE]: ru_UAJson,
		[Language.SERBIAN]: sr_RSJson,
		[Language.SLOVAK]: sk_SKJson,
		[Language.SLOVENIAN]: sl_SIJson,
		[Language.SPANISH]: es_ESJson,
		[Language.SPANISH_ARGENTINA]: es_ARJson,
		[Language.SPANISH_CHILE]: es_CLJson,
		[Language.SWEDISH]: sv_SEJson,
		[Language.THAI]: th_THJson,
		[Language.TURKISH]: tr_TRJson,
		[Language.UKRAINIAN]: uk_UAJson,
		[Language.VIETNAMESE]: vi_VNJson,
	};

	public getTranslation(
		lang: string,
	): Observable<Partial<Record<Language, Record<string, unknown>>>> {
		return of(this.translations[lang]);
	}
}
