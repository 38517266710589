/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { inject, Injectable, InjectionToken } from '@angular/core';

export const SESSION_STORAGE = new InjectionToken<Storage>('session_storage');

@Injectable({
	providedIn: 'root',
})
export class SessionStorageService {
	protected sessionStorage = inject(SESSION_STORAGE);

	public getItem<T>(key: string): T {
		const rawItem: string = this.sessionStorage.getItem(key);

		try {
			return JSON.parse(rawItem);
		} catch (e) {
			return null;
		}
	}

	public setItem<T>(key: string, value: T): void {
		this.sessionStorage.setItem(key, JSON.stringify(value));
	}

	public removeItem(key: string): void {
		this.sessionStorage.removeItem(key);
	}

	public clear(): void {
		this.sessionStorage.clear();
	}
}
