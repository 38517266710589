/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute } from 'ngx-form-object';
import { Attribute, ModelConfig } from 'ngx-hal';
import { Language } from '../enums/language.enum';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';

@ModelConfig({
	type: 'IngredientTranslation',
	endpoint: 'Translation',
})
export class IngredientTranslation extends HalDatastoreModel {
	@Attribute()
	@FormAttribute()
	public nameSingular: string;

	@Attribute()
	@FormAttribute()
	public namePlural: string;

	@Attribute({
		transformResponseValue: (rawValue: Language) => rawValue && rawValue.replace('-', '_'),
	})
	@FormAttribute()
	public language: Language;
}
