/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum ContentStatusHal {
	APPROVED = 'APPROVED',
	BLOCKED = 'BLOCKED',
	CREATED = 'CREATED',
	DELETED = 'DELETED',
	DELETED_CM = 'DELETED_CM',
	DELETED_CONSUMER = 'DELETED_CONSUMER',
	DRAFT = 'DRAFT',
	IN_REVIEW = 'IN_REVIEW',
	LIVE = 'LIVE',
	LIVE_FLAGGED = 'LIVE_FLAGGED',
	QUARANTINED = 'QUARANTINED',
}

export enum ContentStatus {
	CREATED = 0,
	DRAFT = 1,
	IN_REVIEW = 2,
	LIVE = 3,
	APPROVED = 4,
	DELETED = 5,
	LIVE_FLAGGED = 6,
	QUARANTINED = 7,
	BLOCKED = 8,
	DELETED_CONSUMER = <any>'DELETED_CONSUMER',
	DELETED_CM = <any>'DELETED_CM',
}

export interface IContentStatusData<T> {
	id: T;
	translateKey: string;
	translateKeyForHistory?: string;
	label: string;
	visibleInFilter: boolean;
}

export const contentStatusJsonApiData: Record<ContentStatus, IContentStatusData<ContentStatus>> = {
	[ContentStatus.DRAFT]: {
		id: 1,
		label: 'Draft',
		translateKey: 'status.draft',
		visibleInFilter: true,
	},
	[ContentStatus.IN_REVIEW]: {
		id: 2,
		label: 'In review',
		translateKey: 'status.inReview',
		visibleInFilter: true,
	},
	[ContentStatus.LIVE]: {
		id: 3,
		label: 'Live',
		translateKey: 'status.live',
		visibleInFilter: true,
	},
	[ContentStatus.APPROVED]: {
		id: 4,
		label: 'Approved',
		translateKey: 'status.approved',
		visibleInFilter: true,
	},
	[ContentStatus.DELETED]: {
		id: 5,
		label: 'Deleted',
		translateKey: 'status.deleted',
		visibleInFilter: true,
	},
	[ContentStatus.LIVE_FLAGGED]: {
		id: 6,
		label: 'Live flagged',
		translateKey: 'status.liveFlagged',
		visibleInFilter: true,
	},
	[ContentStatus.QUARANTINED]: {
		id: 7,
		label: 'Quarantined',
		translateKey: 'status.quarantined',
		visibleInFilter: true,
	},
	[ContentStatus.BLOCKED]: {
		id: 8,
		label: 'Blocked',
		translateKey: 'status.blocked',
		visibleInFilter: true,
	},
	[ContentStatus.DELETED_CONSUMER]: {
		id: ContentStatus.DELETED_CONSUMER,
		translateKey: 'status.deletedByConsumer',
		translateKeyForHistory: 'status.deleted',
		label: 'Deleted by consumer',
		visibleInFilter: false,
	},
	[ContentStatus.DELETED_CM]: {
		id: ContentStatus.DELETED_CM,
		translateKey: 'status.deletedByCM',
		translateKeyForHistory: 'status.deleted',
		label: 'Deleted by Content Manager',
		visibleInFilter: false,
	},
	[ContentStatus.CREATED]: {
		id: ContentStatus.CREATED,
		translateKey: 'status.created',
		translateKeyForHistory: 'status.created',
		label: 'Created',
		visibleInFilter: false,
	},
};

export const contentStatusHalData: Partial<
	Record<ContentStatusHal, IContentStatusData<ContentStatusHal>>
> = {
	[ContentStatusHal.APPROVED]: {
		id: ContentStatusHal.APPROVED,
		label: 'Approved',
		translateKey: 'status.approved',
		visibleInFilter: true,
	},
	[ContentStatusHal.BLOCKED]: {
		id: ContentStatusHal.BLOCKED,
		label: 'Blocked',
		translateKey: 'status.blocked',
		visibleInFilter: true,
	},
	[ContentStatusHal.DELETED]: {
		id: ContentStatusHal.DELETED,
		label: 'Deleted',
		translateKey: 'status.deleted',
		visibleInFilter: true,
	},
	[ContentStatusHal.DELETED_CM]: {
		id: ContentStatusHal.DELETED_CM,
		translateKey: 'status.deletedByCM',
		translateKeyForHistory: 'status.deleted',
		label: 'Deleted by consumer',
		visibleInFilter: false,
	},
	[ContentStatusHal.DELETED_CONSUMER]: {
		id: ContentStatusHal.DELETED_CONSUMER,
		translateKey: 'status.deletedByConsumer',
		translateKeyForHistory: 'status.deleted',
		label: 'Deleted by Content Manager',
		visibleInFilter: false,
	},
	[ContentStatusHal.DRAFT]: {
		id: ContentStatusHal.DRAFT,
		label: 'Draft',
		translateKey: 'status.draft',
		visibleInFilter: true,
	},
	[ContentStatusHal.IN_REVIEW]: {
		id: ContentStatusHal.IN_REVIEW,
		label: 'In review',
		translateKey: 'status.inReview',
		visibleInFilter: true,
	},
	[ContentStatusHal.LIVE]: {
		id: ContentStatusHal.LIVE,
		label: 'Live',
		translateKey: 'status.live',
		visibleInFilter: true,
	},
	[ContentStatusHal.LIVE_FLAGGED]: {
		id: ContentStatusHal.LIVE_FLAGGED,
		label: 'Live flagged',
		translateKey: 'status.liveFlagged',
		visibleInFilter: true,
	},
	[ContentStatusHal.QUARANTINED]: {
		id: ContentStatusHal.QUARANTINED,
		label: 'Quarantined',
		translateKey: 'status.quarantined',
		visibleInFilter: true,
	},
};

export const contentStatusData = {
	...contentStatusJsonApiData,
	...contentStatusHalData,
};
