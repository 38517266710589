/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { ILanguageConfig, ILanguageConfigHal } from '../../../interfaces/language-config.interface';
import { Language } from '../../../enums/language.enum';

export function transformLanguageForHalApi(language: Language): string {
	return language && language.replace('_', '-');
}

export function transformLanguageForJsonApi(language: Language | string): Language {
	return language && (language.replace('-', '_') as Language);
}

export function transformLanguageConfigForHalApi(
	languageConfig: ILanguageConfig,
): ILanguageConfigHal {
	const language = transformLanguageForHalApi(languageConfig.language);
	const languageOfOrigin = languageConfig.languageOfOrigin
		? transformLanguageForHalApi(languageConfig.languageOfOrigin)
		: null;
	const fallbacks = languageConfig.fallbacks
		? languageConfig.fallbacks.map((fallbackLanguage) =>
				transformLanguageForHalApi(fallbackLanguage),
		  )
		: null;

	const transformedLanguageConfig: ILanguageConfigHal = { language };

	if (languageOfOrigin) {
		transformedLanguageConfig.languageOfOrigin = languageOfOrigin;
	}

	if (fallbacks) {
		transformedLanguageConfig.fallbacks = fallbacks;
	}

	return transformedLanguageConfig;
}

export function transformLanguageConfigForJsonApi(
	languageConfig: ILanguageConfigHal,
): ILanguageConfig {
	const language = transformLanguageForJsonApi(languageConfig.language);
	const languageOfOrigin = languageConfig.languageOfOrigin
		? transformLanguageForJsonApi(languageConfig.languageOfOrigin)
		: null;
	const fallbacks = languageConfig.fallbacks
		? languageConfig.fallbacks.map((fallbackLanguage) =>
				transformLanguageForJsonApi(fallbackLanguage),
		  )
		: null;

	const transformedLanguageConfig: ILanguageConfig = { language };

	if (languageOfOrigin) {
		transformedLanguageConfig.languageOfOrigin = languageOfOrigin;
	}

	if (fallbacks) {
		transformedLanguageConfig.fallbacks = fallbacks;
	}

	return transformedLanguageConfig;
}
