/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { IDuration } from '../../../interfaces/time-duration.interface';

export function convertDurationToSeconds(duration: IDuration): number {
	return (duration.hours * 60 + duration.minutes) * 60;
}
