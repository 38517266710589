/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export interface ICompareArraysOptions<T> {
	ignoreOrder?: boolean;
	propertyFn?: (item: T) => unknown;
}

export function compareArrays<T>(
	items1: Array<T>,
	items2: Array<T>,
	options: ICompareArraysOptions<T> = {
		ignoreOrder: false,
	},
): boolean {
	if (items1.length !== items2.length) {
		return false;
	}

	let propertyFn: (item: T) => unknown = options.propertyFn;
	if (!propertyFn) {
		propertyFn = (item: T) => item;
	}

	const values1: Array<unknown> = items1.map(propertyFn);
	const values2: Array<unknown> = items2.map(propertyFn);

	if (options.ignoreOrder) {
		values1.sort();
		values2.sort();
	}

	return values1.every((value: unknown, index: number) => value === values2[index]);
}
