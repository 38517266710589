/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { InjectionToken } from '@angular/core';

export const WINDOW_TOKEN = new InjectionToken<Window>('Window object');

export function windowProvider(): Window {
	return window;
}
