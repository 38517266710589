/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export function eventPath(event: Event): Array<HTMLElement> {
	if (!event || !event.target) {
		return [];
	}

	let currentElement: HTMLElement = event.target as HTMLElement;
	const path: Array<HTMLElement> = [];

	while (currentElement) {
		path.push(currentElement);

		if (currentElement === event.currentTarget) {
			break;
		}

		currentElement = currentElement.parentElement;
	}

	return path;
}
