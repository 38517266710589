/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum Locale {
	ALBANIAN = 'sq_AL',
	ARABIC_BAHRAIN = 'ar_BH',
	ARABIC_JORDAN = 'ar_JO',
	ARABIC_KUWAIT = 'ar_KW',
	ARABIC_OMAN = 'ar_OM',
	ARABIC_QATAR = 'ar_QA',
	ARABIC_SAUDI_ARABIA = 'ar_SA',
	ARABIC = 'ar_AE',
	BAHASA_INDONESIA = 'id_ID',
	BULGARIAN = 'bg_BG',
	CHINESE = 'zh_CN',
	CHINESE_HONG_KONG = 'zh_HK',
	CHINESE_MACAU = 'zh_MO',
	CHINESE_TAIWAN = 'zh_TW',
	CROATIAN = 'hr_HR',
	CZECH = 'cs_CZ',
	DANISH = 'da_DK',
	DUTCH = 'nl_NL',
	DUTCH_BELGIUM = 'nl_BE',
	ENGLISH = 'en_GB',
	ENGLISH_AUSTRALIA = 'en_AU',
	ENGLISH_INDIA = 'en_IN',
	ENGLISH_NEW_ZEALAND = 'en_NZ',
	ENGLISH_PHILIPPINES = 'en_PH',
	ENGLISH_SINGAPORE = 'en_SG',
	ENGLISH_SOUTH_AFRICA = 'en_ZA',
	ENGLISH_UNITED_STATES = 'en_US',
	ESTONIAN = 'et_EE',
	FINNISH = 'fi_FI',
	FRENCH = 'fr_FR',
	FRENCH_BELGIUM = 'fr_BE',
	FRENCH_CANADA = 'fr_CA',
	FRENCH_MAURITIUS = 'fr_MU',
	FRENCH_SWITZERLAND = 'fr_CH',
	GERMAN = 'de_DE',
	GERMAN_SWITZERLAND = 'de_CH',
	GREEK = 'el_GR',
	GREEK_CYPRUS = 'el_CY',
	HEBREW = 'he_IL',
	HINDI_INDIA = 'hi_IN',
	HUNGARIAN = 'hu_HU',
	ITALIAN = 'it_IT',
	KOREAN = 'ko_KR',
	LATVIAN = 'lv_LV',
	LITHUANIAN = 'lt_LT',
	MACEDONIAN = 'mk_MK',
	NORWEGIAN = 'nb_NO',
	POLISH = 'pl_PL',
	PORTUGUESE = 'pt_PT',
	PORTUGUESE_BRAZIL = 'pt_BR',
	ROMANIAN = 'ro_RO',
	RUSSIAN = 'ru_RU',
	RUSSIAN_UKRAINE = 'ru_UA',
	SERBIAN = 'sr_RS',
	SLOVAK = 'sk_SK',
	SLOVENIAN = 'sl_SI',
	SPANISH = 'es_ES',
	SPANISH_ARGENTINA = 'es_AR',
	SPANISH_CHILE = 'es_CL',
	SWEDISH = 'sv_SE',
	THAI = 'th_TH',
	TURKISH = 'tr_TR',
	UKRAINIAN = 'uk_UA',
	VIETNAMESE = 'vi_VN',
}
