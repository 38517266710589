/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import { Attribute as FormAttribute } from 'ngx-form-object';

import { ModelMetadata } from '../classes/model-metadata/model-metadata';

@JsonApiModelConfig({
	type: 'devices',
	meta: ModelMetadata,
})
export class Device extends JsonApiModel {
	public config = null;

	@FormAttribute()
	public id: string;

	@Attribute()
	@FormAttribute()
	public range: string;

	@Attribute()
	@FormAttribute()
	public model: string;

	@Attribute()
	@FormAttribute()
	public timeMinSeconds: number;

	@Attribute()
	@FormAttribute()
	public timeMaxSeconds: number;

	@Attribute()
	@FormAttribute()
	public temperatureMinCelsius: number;

	@Attribute()
	@FormAttribute()
	public temperatureMaxCelsius: number;

	@Attribute()
	@FormAttribute()
	public tempIntervalC: number;

	@Attribute()
	@FormAttribute()
	public temperatureMinFahrenheit: number;

	@Attribute()
	@FormAttribute()
	public temperatureMaxFahrenheit: number;

	@Attribute()
	@FormAttribute()
	public tempIntervalF: number;
}
