/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, BelongsTo, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';

import { Language } from '../enums/language.enum';
import { TranslationStatus } from '../enums/translation-status.enum';
import { IModelTranslation } from '../interfaces/model-translation.interface';
import { Tip } from '../models/tip.model';

@JsonApiModelConfig({
	type: 'tipTranslations',
})
export class TipTranslation extends JsonApiModel implements IModelTranslation {
	public config = null;

	public id: string;

	@Attribute({
		dataType: Date,
	})
	public createdAt: Date;

	@Attribute()
	public language: Language;

	@Attribute()
	public description: string;

	@Attribute({
		dataType: Date,
	})
	public modifiedAt: Date;

	@Attribute({
		dataType: Date,
	})
	public publisheddAt: Date;

	@Attribute()
	public status: TranslationStatus;

	@Attribute()
	public title: string;

	@BelongsTo()
	public tip: Tip;
}
