/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { IContentContainerTypeData } from '../interfaces/content-container-type-data.interface';

export enum ContentContainerType {
	CONTENT = 'CONTENT',
	GENERAL = 'GENERAL',
	LINK = 'LINK',
	PROFILE = 'USER',
}

export enum ContentContainerContentType {
	ARTICLE = 'ARTICLE',
	RECIPE_BOOK = 'RECIPE_BOOK',
	RECIPE = 'RECIPE',
}

export const contentContainerTypeData: Record<ContentContainerType, IContentContainerTypeData> = {
	[ContentContainerType.CONTENT]: {
		id: ContentContainerType.CONTENT,
		translationKey: 'Content',
	},
	[ContentContainerType.GENERAL]: {
		id: ContentContainerType.GENERAL,
		translationKey: 'announcementType.none',
	},
	[ContentContainerType.LINK]: {
		id: ContentContainerType.LINK,
		translationKey: 'announcementType.outgoing',
	},
	[ContentContainerType.PROFILE]: {
		id: ContentContainerType.PROFILE,
		translationKey: 'announcementType.profile',
	},
};

export const announcementContentTypeData: Record<
	ContentContainerContentType,
	{
		id: ContentContainerContentType;
		translationKey: string;
		titleTranslationKey: string;
		idTranslationKey: string;
	}
> = {
	[ContentContainerContentType.RECIPE_BOOK]: {
		id: ContentContainerContentType.RECIPE_BOOK,
		translationKey: 'announcementType.collection',
		titleTranslationKey: 'announcement.collectionTitle',
		idTranslationKey: 'announcement.collectionID',
	},
	[ContentContainerContentType.RECIPE]: {
		id: ContentContainerContentType.RECIPE,
		translationKey: 'announcementType.recipe',
		titleTranslationKey: 'announcement.recipeTitle',
		idTranslationKey: 'announcement.recipeID',
	},
	[ContentContainerContentType.ARTICLE]: {
		id: ContentContainerContentType.ARTICLE,
		translationKey: 'announcementType.tip',
		titleTranslationKey: 'announcement.tipTitle',
		idTranslationKey: 'announcement.tipID',
	},
};

export const extendedContentContainerTypeData: Record<
	ContentContainerType | ContentContainerContentType,
	IContentContainerTypeData
> = {
	...contentContainerTypeData,
	...announcementContentTypeData,
};
