/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Observable } from 'rxjs';
import { PopupDialogService } from '../../../../services/popup-dialog/popup-dialog.service';
import { IConfirmationDialogOptions } from '../../helpers';

export function openConfirmationDialog(
	dialogService: PopupDialogService,
	options: IConfirmationDialogOptions,
): Observable<boolean> {
	const {
		description,
		isHtmlContent,
		proceedAction,
		proceedActionLabel,
		stopActionLabel,
		title,
	} = options;

	return dialogService
		.setTitle(title)
		.setContent(description, isHtmlContent || false)
		.setCancelButton({
			label: stopActionLabel,
			inverse: true,
		})
		.setSaveButton({
			label: proceedActionLabel,
			callback: proceedAction,
		})
		.open()
		.afterClosed();
}
