/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';

@ModelConfig({
	type: 'ContentMetrics',
	endpoint: 'Content/Metrics',
})
export class ContentMetrics extends HalDatastoreModel {
	@Attribute()
	public numberOfAssociatedArticles: number;

	@Attribute()
	public numberOfAssociatedRecipeBooks: number;

	@Attribute()
	public numberOfAssociatedRecipes: number;

	@Attribute()
	public numberOfComments: number;

	@Attribute()
	public numberOfFavourites: number;

	@Attribute()
	public numberOfPublishedCountries: number;

	@Attribute()
	public numberOfTranslations: number;

	@Attribute()
	public numberOfViews: number;

	@Attribute({
		transformResponseValue: (rawValue: string | undefined) =>
			rawValue ? new Date(rawValue) : undefined,
	})
	public lastPublishedAt: Date;
}
