/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { UntypedFormControl, UntypedFormGroup, AbstractControl } from '@angular/forms';

export interface INumberInRangeOptions {
	min: number;
	max: number;
	excludeBoundaries?: boolean;
	message?: string;
}

export function isNumberInRange(
	formControl: UntypedFormControl | UntypedFormGroup | AbstractControl,
	options: INumberInRangeOptions,
	value: number = null,
) {
	const { min, max, message, excludeBoundaries } = options;
	const fieldValue: any = value === null ? formControl.value : value;

	if ((min === null || min === undefined) && (max === null || max === undefined)) {
		return null;
	}

	const isSet = fieldValue === 0 || fieldValue;
	let satisfiesMin;
	let satisfiesMax;

	if (!excludeBoundaries) {
		satisfiesMin = min === null || fieldValue >= min;
		satisfiesMax = max === null || fieldValue <= max;
	} else {
		satisfiesMin = min === null || fieldValue > min;
		satisfiesMax = max === null || fieldValue < max;
	}

	const isValid = isSet && satisfiesMin && satisfiesMax;

	const validatorObject: object = {
		min,
		max,
		message,
	};

	return isValid ? null : { isNumberInRange: validatorObject };
}
