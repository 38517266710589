/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { moveItemPositionInArrayInPlace } from './move-item-position-in-array-in-place.helper';

export function moveItemPositionInArray<T>(
	array: Array<T>,
	fromIndex: number,
	toIndex: number,
): Array<T> {
	return moveItemPositionInArrayInPlace([...array], fromIndex, toIndex);
}
