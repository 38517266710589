/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import {
	BelongsTo,
	ExtendedFormArray,
	Attribute as FormAttribute,
	HasMany as FormHasMany,
} from 'ngx-form-object';
import {
	Attribute,
	HasMany,
	HasOne,
	HeaderAttribute,
	Link,
	ModelConfig,
	ModelEndpoints,
} from 'ngx-hal';
import { DeviceCategoryEnum } from '../enums/device-category.enum';
import { HighLevelCategoryEnum } from '../enums/high-level-category.enum';
import { IngredientsLocation } from '../enums/ingredient-location.enum';
import { Language } from '../enums/language.enum';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';
import {
	transformDurationIsoStringToSeconds,
	transformSecondsToDurationIsoString,
} from '../utils/helpers/duration-iso-string-converter/duration-iso-string-converter';
import { isCategoryAncestor } from '../utils/helpers/is-category-ancestor/is-category-ancestor.helper';
import {
	transformLanguageForHalApi,
	transformLanguageForJsonApi,
} from '../utils/helpers/language-transformer/language-transformer.helper';
import { Category } from './category.model';
import { Content } from './content.model';
import { Device } from './device.hal.model';
import { Media } from './media.hal.model';
import { RecipeIngredient } from './recipe-ingredient.hal.model';
import { RecipeNutrition } from './recipe-nutrition.model';
import { RecipePreparation } from './recipe-preparation.model';
import { RecipeStep } from './recipe-step.model';
import { RecipeTranslation } from './recipe-translation.hal.model';
import { UnitSystem } from './unit-system.model';

@ModelConfig({
	type: 'Recipe',
})
export class Recipe extends Content {
	public static modelType = 'Recipe';

	@HeaderAttribute({
		transformResponseValue: transformLanguageForJsonApi,
		transformBeforeSave: transformLanguageForHalApi,
		externalName: 'Content-language',
	})
	@FormAttribute()
	public language: Language;

	@Attribute({
		externalName: 'name',
	})
	@FormAttribute()
	public title: string;

	@Attribute()
	@FormAttribute()
	public description: string;

	@HasMany({
		itemsType: RecipeTranslation,
	})
	@FormHasMany()
	public translations: Array<RecipeTranslation>;

	@HasOne({
		propertyClass: RecipeTranslation,
		includeInPayload: true,
	})
	@BelongsTo()
	public defaultTranslation: RecipeTranslation;

	@Attribute()
	@FormAttribute()
	public nutritionalInfoComment: string;

	@HasMany({
		itemsType: RecipeNutrition,
	})
	public nutrition: Array<RecipeNutrition>;

	@Attribute({
		transformBeforeSave: (seconds: number) => transformSecondsToDurationIsoString(seconds),
		transformResponseValue: (isoString: string) => transformDurationIsoStringToSeconds(isoString),
	})
	@FormAttribute()
	public activePreparationTime: number;

	@Attribute({
		transformBeforeSave: (seconds: number) => transformSecondsToDurationIsoString(seconds),
		transformResponseValue: (isoString: string) => transformDurationIsoStringToSeconds(isoString),
	})
	@FormAttribute()
	public passivePreparationTime: number;

	@Attribute({
		transformResponseValue: (isoString: string) => transformDurationIsoStringToSeconds(isoString),
	})
	@FormAttribute()
	public activeTime: number;

	@Attribute({
		transformResponseValue: (isoString: string) => transformDurationIsoStringToSeconds(isoString),
	})
	@FormAttribute()
	public totalTime: number;

	@Attribute({
		transformResponseValue: (isoString: string) => transformDurationIsoStringToSeconds(isoString),
	})
	@FormAttribute()
	public cookTime: number;

	@Attribute({
		transformResponseValue: (isoString: string) => transformDurationIsoStringToSeconds(isoString),
	})
	public prepTime: number;

	@Attribute()
	@FormAttribute()
	public servings: number;

	@Attribute()
	public ingredientsLocation: IngredientsLocation;

	@HasOne({
		propertyClass: Media,
		externalName: 'image',
		includeInPayload: true,
	})
	@BelongsTo()
	public coverImage: Media;

	@HasOne({
		propertyClass: Media,
		includeInPayload: true,
	})
	@BelongsTo()
	public video: Media;

	@HasOne({
		propertyClass: UnitSystem,
		includeInPayload: false,
	})
	@BelongsTo()
	public unitSystem: UnitSystem;

	@HasMany({
		externalName: 'ingredients',
		itemsType: RecipeIngredient,
		includeInPayload: true,
	})
	@FormHasMany({
		isChanged: (
			initial: Array<RecipeIngredient>,
			current: Array<RecipeIngredient>,
			recipeIngredients: ExtendedFormArray,
		) => {
			const isArrayItemChanged: boolean = recipeIngredients.controls.some(
				(item: any) => item && item['isChanged'],
			);
			return (
				isArrayItemChanged ||
				!compareArrays(initial, current, {
					ignoreOrder: false,
					propertyFn: (item) => item.ingredient.id,
				})
			);
		},
	})
	public recipeIngredients: Array<RecipeIngredient>;

	@HasMany({
		itemsType: Category,
	})
	@FormHasMany()
	public categories: Array<Category>;

	@HasMany({
		itemsType: Category,
	})
	public deviceCategories: Array<Category>;

	@HasMany({
		itemsType: RecipeStep,
		includeInPayload: true,
	})
	@FormHasMany()
	public steps: Array<RecipeStep>;

	@HasMany({
		itemsType: Device,
	})
	@FormHasMany({
		isChanged: (initial: Array<Device>, current: Array<Device>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item) => item.id,
			}),
	})
	public incompatibleDevices: Array<Device>;

	@HasMany({
		itemsType: Category,
	})
	@FormHasMany()
	public tags: Array<Category>;

	@HasMany({ itemsType: RecipePreparation })
	public recipePreparations: Array<RecipePreparation>;

	@FormAttribute()
	public isIngredientRecipe: boolean;

	@Link()
	public recipeStepIngredients: string;

	public get firstProcessingStep(): RecipeStep {
		return this.steps ? this.steps.find((step: RecipeStep) => step.isProcessingStep) : null;
	}

	public get deviceCategory(): string {
		return this.deviceCategoryFromRecipeCategories?.slug ?? DeviceCategoryEnum.NO_DEVICE;
	}

	public get deviceCategoryFromRecipeCategories(): Category {
		return (this.categories || this.deviceCategories || []).find((category) =>
			isCategoryAncestor(category.parent, HighLevelCategoryEnum.DEVICE),
		);
	}

	public get areIngredientsOnStep(): boolean {
		return this.ingredientsLocation === IngredientsLocation.IngredientsPerRecipeStep;
	}

	public get areIngredientsOnRecipe(): boolean {
		return this.ingredientsLocation === IngredientsLocation.IngredientsPerRecipe;
	}

	public displayNameTranslationKey = 'contentTypeHal.recipe';

	public displayName(language: Language): string {
		return this.getTranslatableProperty(language, 'title');
	}

	public get modelEndpoints(): ModelEndpoints {
		return {
			singleResourceEndpoint: this.datastore.rootApi.singleRecipeEndpoint,
			collectionEndpoint: this.datastore.rootApi.recipeCollectionEndpoint,
		};
	}
}
