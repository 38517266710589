/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRole } from '../../enums/user-role.enum';
import { RoleFormStore } from '../../forms/role/role.form-store';
import { IRelationshipChanges } from '../../interfaces/relationship-changes.interface';
import { Administrator } from '../../models/administrator.model';
import { Role } from '../../models/role.model';
import { RoleService } from '../role/role.service';
import { Organization } from './../../models/organization.hal.model';

@Injectable({
	providedIn: 'root',
})
export class RoleManipulationService {
	constructor(private readonly roleService: RoleService) {}

	/**
	 * use for joining 1 role / 1 organization models to 1 role / n organization models
	 */
	public joinRolesPerUserRole(roles: Array<Role>): Array<Role> {
		return roles.reduce((joinedRoles: Array<Role>, singleRole: Role) => {
			const joinedRole = joinedRoles.find(
				(roleFromJoinedRoles) => roleFromJoinedRoles.role === singleRole.role,
			);

			if (!joinedRole) {
				// add role to the list which does not contain that role
				joinedRoles.push(singleRole);
			} else {
				singleRole.organizations.forEach((organization) => {
					if (!joinedRole.organizations.includes(organization)) {
						// add organization to already existing role
						joinedRole.organizations.push(organization);
					}
				});
			}

			return joinedRoles;
		}, []);
	}

	/**
	 * Custom implementation of RelationshipChangesService.getRelationshipChanges because of backwards compatibility
	 * issues - form is created with joined role models (new models), but saved via existing models.
	 * This override will be removed after removal of V1 user and backend implementation of 1/n role/organization.
	 */
	public getRelationshipChanges(
		roleForms: Array<RoleFormStore>,
		administrator: Administrator,
	): IRelationshipChanges<Role> {
		const savedRoles = administrator.roles || [];
		const splitRoleForms: Array<RoleFormStore> = this.splitRoleFormsPerUserRoleAndOrganization(
			roleForms,
			administrator,
		);

		const modelsToDelete = savedRoles.filter((savedRole) => {
			const hasFormWithModelValue = Boolean(
				splitRoleForms.find((form) => {
					const hasSameRole = form.get('role').value === savedRole.role;
					const hasSameOrganization =
						form.get('organizations').value[0].id === savedRole.organizations[0].id;

					return hasSameRole && hasSameOrganization;
				}),
			);

			return !hasFormWithModelValue;
		});

		const adds: Array<Observable<Role>> = splitRoleForms
			.filter((form) => !savedRoles.includes(form.model))
			.map((form) => form.save());
		const deletes: Array<Observable<void>> = modelsToDelete.map((model) => model.delete());

		return {
			deletes,
			adds,
			edits: [],
			all: [...adds, ...deletes],
		};
	}

	/**
	 * use for splitting 1 role / n organization models to 1 role / 1 organization models
	 */
	private splitRoleFormsPerUserRoleAndOrganization(
		roleForms: Array<RoleFormStore>,
		administrator: Administrator,
	): Array<RoleFormStore> {
		return roleForms.reduce((splitRoleForms: Array<RoleFormStore>, form: RoleFormStore) => {
			const role: UserRole = form.get('role').value;

			form.get('organizations').value.forEach((organization: Organization) => {
				if (!this.isRoleFormInArray(role, organization, splitRoleForms)) {
					const existingModel = (administrator.roles || []).find(
						(savedModel) =>
							savedModel.role === role && savedModel.organizations[0].id === organization.id,
					);
					const newModel = this.roleService.createNewModel({ role });
					newModel.organizations = [organization];

					splitRoleForms.push(
						this.roleService.createRoleForm(administrator, existingModel || newModel),
					);
				}
			});

			return splitRoleForms;
		}, []);
	}

	private isRoleFormInArray(
		role: UserRole,
		organization: Organization,
		splitRoleForms: Array<RoleFormStore>,
	): boolean {
		return Boolean(
			splitRoleForms.find(
				(splitRoleForm) =>
					splitRoleForm.get('role').value === role &&
					splitRoleForm.get('organizations').value[0].id === organization.id,
			),
		);
	}
}
