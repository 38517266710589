/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, BelongsTo, HasMany, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';

import { Consumer } from '../models/consumer.model';
import { ModelMetadata } from '../classes/model-metadata/model-metadata';
import { ContentStatus } from '../enums/content-status.enum';
import { ModelTypePrefix } from '../enums/model-type-prefix.enum';
import { IContentModel } from '../interfaces/content-model.interface';
import { ConsumerProfile } from '../models/consumer-profile.model';
import { AuthorType } from '../enums/author-type.enum';
import { CountryProfile } from '../models/country-profile.model';
import { Domain } from '../models/domain.model';
import { NutritionClaimContentTranslation } from '../models/nutrition-claim-content-translations.model';
import { NutritionClaimGuideline } from '../models/nutrition-claim-guideline.model';
import { User } from '../models/user.model';

@JsonApiModelConfig({
	type: 'nutritionClaimContents',
	meta: ModelMetadata,
})
export class NutritionClaimContent extends JsonApiModel implements IContentModel {
	public config = null;

	public modelTypePrefix: ModelTypePrefix = ModelTypePrefix.NUTRITION_CLAIM_CONTENT;

	@Attribute()
	public shortId: string;

	@Attribute()
	public status: ContentStatus;

	@Attribute()
	public translationCount: number;

	@Attribute()
	public type: AuthorType;

	@BelongsTo()
	public authorUser: User;

	@BelongsTo()
	public authorConsumer: Consumer;

	@BelongsTo()
	public domain: Domain;

	@BelongsTo()
	public defaultTranslation: NutritionClaimContentTranslation;

	@HasMany()
	public nutritionClaimContentTranslations: Array<NutritionClaimContentTranslation>;

	@BelongsTo()
	public nutritionClaimGuideline: NutritionClaimGuideline;

	// Profiles is not used anywhere because NutritionClaimContent is not publishable
	// but it has to be here as NutritionClaimContent implements ContentModel interface
	public profiles: Array<CountryProfile | ConsumerProfile>;

	public get translations(): Array<NutritionClaimContentTranslation> {
		return this.nutritionClaimContentTranslations;
	}

	public translationsPropertyName = 'nutritionClaimContentTranslations';
}
