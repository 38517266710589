/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Component, Inject, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of } from 'rxjs';
import { DropdownOptionsFunction } from '../../custom-types/select-field-options-function.type';
import { IReportReasonInfo, ReportReason, reportReasonData } from '../../enums/report-reason.enum';
import { ISelectInterface2 } from '../../interfaces/select.interface';
import { IDialogData } from '../popup-dialog/dialog-options.interface';
import { PopupDialogComponent } from '../popup-dialog/popup-dialog.component';

@Component({
	selector: 'reported-content-modal',
	templateUrl: 'reported-content-modal.component.html',
	styleUrls: ['reported-content-modal.component.scss'],
})
export class ReportedContentModalComponent extends PopupDialogComponent {
	public reportReasons: Array<IReportReasonInfo> = Object.keys(reportReasonData)
		.map((reasonKey: string) => reportReasonData[reasonKey])
		.filter((reasonData: any) => reasonData.id !== ReportReason.OTHER);

	public reportReasonOptions: Array<ISelectInterface2<IReportReasonInfo>> = this.reportReasons.map(
		({ id }: IReportReasonInfo) => ({
			value: reportReasonData[id],
			label: reportReasonData[id].label,
		}),
	);
	public reportReasonOptionsFn: DropdownOptionsFunction<IReportReasonInfo> = () =>
		of(this.reportReasonOptions);

	public form: UntypedFormGroup = new UntypedFormGroup({
		reportReasons: new UntypedFormControl([], Validators.required),
		comment: new UntypedFormControl('', Validators.required),
	});

	public isSingleSelect = false;

	public constructor(
		public dialogRef: MatDialogRef<PopupDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IDialogData,
		protected renderer: Renderer2,
	) {
		super(dialogRef, data, renderer);

		if (data.extra && data.extra.selectableReportReasons) {
			this.assignReportReasons(data.extra.selectableReportReasons);
		}

		if (data.extra && data.extra.singleReasonSelect) {
			this.isSingleSelect = data.extra.singleReasonSelect;
		}
	}

	public get isSuccessButtonEnabled(): boolean {
		return this.form.valid;
	}

	public onSaveClick(): void {
		let canClose = true;
		const comment: string = this.form.get('comment').value;
		const reasons: Array<ReportReason> = this.form.get('reportReasons').value;

		if (this.data.saveButton.callback) {
			canClose = this.data.saveButton.callback(reasons, comment);
		}

		if (canClose !== false) {
			this.dialogRef.close({
				comment,
				reasons,
				shouldProceed: true,
			});
		}
	}

	public onReportReasonSelect(selectedReason: IReportReasonInfo): void {
		this.form.get('reportReasons').setValue([selectedReason]);
	}

	private assignReportReasons(selectableReportReasons: Array<ReportReason>): void {
		this.reportReasons = selectableReportReasons.map(
			(reason: ReportReason) => reportReasonData[reason],
		);

		this.reportReasonOptions = selectableReportReasons.map((reason: ReportReason) => ({
			value: reportReasonData[reason],
			label: reportReasonData[reason].label,
		}));
	}

	public onCancelClick(): void {
		let canClose = true;

		if (this.data.cancelButton.callback) {
			canClose = this.data.cancelButton.callback();
		}

		if (canClose !== false) {
			this.dialogRef.close({ shouldProceed: false });
		}
	}
}
