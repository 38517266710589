/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { FormFieldComponent } from 'app/components/form/form-field/form-field.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	imports: [CommonModule, MatIconModule, MatTooltipModule],
	declarations: [FormFieldComponent],
	exports: [FormFieldComponent],
})
export class FormFieldModule {}
