/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export function deleteNullObjectProperties(object: any): void {
	if (!object || typeof object !== 'object') {
		return;
	}

	deleteNullProperties(object);
}

function deleteNullProperties(object: any) {
	Object.keys(object).forEach((objectKey: string) => {
		if (object[objectKey] === null || object[objectKey] === undefined) {
			delete object[objectKey];
		} else if (typeof object[objectKey] === 'object') {
			deleteNullProperties(object[objectKey]);
		}
	});
}
