/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { TemperatureUnit } from '../enums/temperature-unit.enum';
import { Quantifiable, IQuantifiableInterface } from './quantifiable.class';

export class Temperature extends Quantifiable<TemperatureUnit> {
	public get temperature(): IQuantifiableInterface<TemperatureUnit> {
		return this.value;
	}
}
