/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export type TranslationStatusData = Record<
	TranslationStatus | TranslationStatusHal,
	{
		id: TranslationStatus | TranslationStatusHal;
		label: string;
		translateKey: string;
	}
>;

export enum TranslationStatus {
	DRAFT = 1,
	APPROVED = 2,
}

export enum TranslationStatusHal {
	DRAFT = 'DRAFT',
	APPROVED = 'APPROVED',
}

const translationStatusJsonApiData = {
	[TranslationStatus.DRAFT]: {
		id: TranslationStatus.DRAFT,
		label: 'Draft',
		translateKey: 'status.draft',
	},
	[TranslationStatus.APPROVED]: {
		id: TranslationStatus.APPROVED,
		label: 'Approved',
		translateKey: 'status.approved',
	},
};

const translationStatusHalData = {
	[TranslationStatusHal.DRAFT]: {
		id: TranslationStatusHal.DRAFT,
		label: 'Draft',
		translateKey: 'status.draft',
	},
	[TranslationStatusHal.APPROVED]: {
		id: TranslationStatusHal.APPROVED,
		label: 'Approved',
		translateKey: 'status.approved',
	},
};

export const translationStatusData: TranslationStatusData = {
	...translationStatusJsonApiData,
	...translationStatusHalData,
};
