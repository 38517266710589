/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { CustomOptions, HalModel } from 'ngx-hal';
import { Observable } from 'rxjs';
import { HalPagination } from '../../../classes/pagination';
import { HalDatastoreService } from '../hal-datastore.service';

export abstract class HalDatastoreModel extends HalModel<HalPagination, HalDatastoreService> {
	public saveViaParent<T extends HalModel<HalPagination>>(
		parentModel: T,
		relationshipName: string,
		customOptions?: CustomOptions<this>,
	): Observable<this> {
		let options = Object.assign({}, customOptions);

		if (!this.selfLink) {
			options = Object.assign(
				{
					buildUrlFunction: () => parentModel.getRelationshipUrl(relationshipName),
				},
				options,
			);
		}

		return this.save({}, options);
	}

	public hasRelationshipUrls(relationshipNames: Array<string>): boolean {
		return relationshipNames.every((relationshipName) => this.getRelationshipUrl(relationshipName));
	}
}
