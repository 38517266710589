/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export function moveItemPositionInArrayInPlace<T>(
	array: Array<T>,
	fromIndex: number,
	toIndex: number,
): Array<T> {
	array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
	return array;
}
