/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { PressureUnit } from '../enums/pressure-unit.enum';
import { Quantifiable, IQuantifiableInterface } from './quantifiable.class';

export interface IPressureInterface extends IQuantifiableInterface<PressureUnit> {
	buildDuration: number;
	releaseDuration: number;
}

export interface IPressureRawInterface extends IQuantifiableInterface<PressureUnit> {
	buildDuration: string;
	releaseDuration: string;
}

export class Pressure extends Quantifiable<PressureUnit, IPressureInterface> {
	public get pressure(): IPressureInterface {
		return this.value;
	}

	public get buildDuration(): number {
		return this.value?.buildDuration;
	}

	public get releaseDuration(): number {
		return this.value?.releaseDuration;
	}
}
