/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable, inject } from '@angular/core';
import { EnvironmentVariablesService } from '@infinum/ngx-nuts-and-bolts/env';
import { EnvironmentVariable } from '../../../../environments/environment-variable.enum';

@Injectable()
export class DevLoginGuard {
	private readonly environmentVariablesService = inject(EnvironmentVariablesService);

	public canActivate(): boolean {
		const loginRouteEnabled = this.environmentVariablesService.get(
			EnvironmentVariable.LOGIN_ROUTE_ENABLED,
		);
		return Boolean(loginRouteEnabled);
	}
}
