/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';

import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ImportResult } from '../../models/import-result.model';
import { PreparedMeal } from '../../models/prepared-meal.model';
import { ErrorService } from '../error/error.service';

import { APP_CONFIG, IAppConfig } from 'app/app.config';
import { CollectionTranslation } from 'app/models/collection-translation.model';
import { Collection } from 'app/models/collection.model';
import { Comment } from 'app/models/comment.model';
import { ConsumerProfile } from 'app/models/consumer-profile.model';
import { Consumer } from 'app/models/consumer.model';
import { CountryProfile } from 'app/models/country-profile.model';
import { CountryStatistics } from 'app/models/country-statistics.model';
import { Device } from 'app/models/device.model';
import { Domain } from 'app/models/domain.model';
import { HealthClaimContentTranslation } from 'app/models/health-claim-content-translations.model';
import { HealthClaimContent } from 'app/models/health-claim-content.model';
import { HealthClaimGuideline } from 'app/models/health-claim-guideline.model';
import { HistoryItem } from 'app/models/history-item.model';
import { IngredientTranslation } from 'app/models/ingredient-translation.model';
import { Ingredient } from 'app/models/ingredient.model';
import { Media } from 'app/models/media.model';
import { NutrientDetail } from 'app/models/nutrient-detail.model';
import { Nutrient } from 'app/models/nutrient.model';
import { NutritionClaimContentTranslation } from 'app/models/nutrition-claim-content-translations.model';
import { NutritionClaimContent } from 'app/models/nutrition-claim-content.model';
import { NutritionClaimGuideline } from 'app/models/nutrition-claim-guideline.model';
import { NutritionInfo } from 'app/models/nutrition-info.model';
import { NutritionListItem } from 'app/models/nutrition-list-item.model';
import { RecipeIngredient } from 'app/models/recipe-ingredient.model';
import { RecipeNutritionInfo } from 'app/models/recipe-nutrition-info.model';
import { RecipeTranslation } from 'app/models/recipe-translation.model';
import { Recipe } from 'app/models/recipe.model';
import { ReportedContent } from 'app/models/reported-content.model';
import { ReportedItem } from 'app/models/reported-item.model';
import { SearchFilter } from 'app/models/search-filter.model';
import { SpecificConversion } from 'app/models/specific-conversion.model';
import { User } from 'app/models/user.model';
import { HttpService } from 'app/services/http/http.service';
import { getDirtyAttributes } from 'app/utils/overrides/json-api-datastore/get-dirty-attributes';
import { toQueryString } from 'app/utils/overrides/json-api-datastore/to-query-string';
import { JsonApiDatastoreConfig } from '../../../assets/vendors/angular2-jsonapi/decorators/json-api-datastore-config.decorator';
import { DatastoreConfig } from '../../../assets/vendors/angular2-jsonapi/interfaces/datastore-config.interface';
import { JsonApiQueryData } from '../../../assets/vendors/angular2-jsonapi/models/json-api-query-data';
import { JsonApiModel } from '../../../assets/vendors/angular2-jsonapi/models/json-api.model';
import {
	JsonApiDatastore,
	ModelType,
} from '../../../assets/vendors/angular2-jsonapi/services/json-api-datastore.service';
import { TipTranslation } from '../../models/tip-translation.model';
import { Tip } from '../../models/tip.model';

// Models

export type CustomRequestOptions =
	| {
			body?: object;
			observe?: string;
			responseType?: string;
			fullResponse?: boolean; // if true, HttpResponse will be returned instead of parsed JSON
			headers?: object;
			[K: string]: any;
	  }
	| any;

@Injectable()
@JsonApiDatastoreConfig({
	models: {
		ConsumerProfile,
		Consumer,
		Media,
		collections: Collection,
		collectionStatusHistory: HistoryItem,
		collectionTranslations: CollectionTranslation,
		comments: Comment,
		consumerProfiles: ConsumerProfile,
		consumers: Consumer,
		countryProfiles: CountryProfile,
		countryStatistics: CountryStatistics,
		devices: Device,
		domains: Domain,
		healthClaimContents: HealthClaimContent,
		healthClaimContentTranslations: HealthClaimContentTranslation,
		healthClaimGuidelines: HealthClaimGuideline,
		historyItems: HistoryItem,
		importResult: ImportResult,
		ingredients: Ingredient,
		ingredientTranslations: IngredientTranslation,
		media: Media,
		nutrientDetails: NutrientDetail,
		nutrients: Nutrient,
		nutritionClaimContents: NutritionClaimContent,
		nutritionClaimContentTranslations: NutritionClaimContentTranslation,
		nutritionClaimGuidelines: NutritionClaimGuideline,
		nutritionInfo: NutritionInfo,
		nutritionListItem: NutritionListItem,
		originalDevice: Device,
		preparedMeals: PreparedMeal,
		profiles: CountryProfile, // TODO profile can be Country or Consumer profile, check with backend devs
		profileStatusHistory: HistoryItem,
		recipeIngredients: RecipeIngredient,
		recipeNutritionInfo: RecipeNutritionInfo,
		recipes: Recipe,
		recipeStatusHistory: HistoryItem,
		recipeTranslations: RecipeTranslation,
		reportedContent: ReportedContent,
		reportedItems: ReportedItem,
		reports: ReportedContent,
		searchFilters: SearchFilter,
		specificConversions: SpecificConversion,
		tips: Tip,
		tipStatusHistory: HistoryItem,
		tipTranslations: TipTranslation,
		users: User,
	},
	overrides: {
		toQueryString,
		getDirtyAttributes,
	},
})
export class Datastore extends JsonApiDatastore {
	protected config: DatastoreConfig = {
		baseUrl: this.appConfig.api.host,
		apiVersion: this.appConfig.api.endpoint,
	};

	public modelTypes = {
		ConsumerProfile,
		Consumer,
		Media,
		collections: Collection,
		collectionStatusHistory: HistoryItem,
		collectionTranslations: CollectionTranslation,
		comments: Comment,
		consumerProfiles: ConsumerProfile,
		consumers: Consumer,
		countryProfiles: CountryProfile,
		countryStatistics: CountryStatistics,
		devices: Device,
		domains: Domain,
		healthClaimContents: HealthClaimContent,
		healthClaimContentTranslations: HealthClaimContentTranslation,
		healthClaimGuidelines: HealthClaimGuideline,
		historyItems: HistoryItem,
		importResult: ImportResult,
		ingredients: Ingredient,
		ingredientTranslations: IngredientTranslation,
		media: Media,
		nutrientDetails: NutrientDetail,
		nutrients: Nutrient,
		nutritionClaimContents: NutritionClaimContent,
		nutritionClaimContentTranslations: NutritionClaimContentTranslation,
		nutritionClaimGuidelines: NutritionClaimGuideline,
		nutritionInfo: NutritionInfo,
		nutritionListItem: NutritionListItem,
		originalDevice: Device,
		preparedMeals: PreparedMeal,
		profiles: CountryProfile, // TODO profile can be Country or Consumer profile, check with backend devs
		profileStatusHistory: HistoryItem,
		recipeIngredients: RecipeIngredient,
		recipeNutritionInfo: RecipeNutritionInfo,
		recipes: Recipe,
		recipeStatusHistory: HistoryItem,
		recipeTranslations: RecipeTranslation,
		reportedContent: ReportedContent,
		reportedItems: ReportedItem,
		reports: ReportedContent,
		searchFilters: SearchFilter,
		specificConversions: SpecificConversion,
		tips: Tip,
		tipStatusHistory: HistoryItem,
		tipTranslations: TipTranslation,
		users: User,
	};

	public constructor(
		private readonly httpService: HttpService,
		@Inject(APP_CONFIG) private readonly appConfig: IAppConfig,
		private errorService: ErrorService,
		private readonly injector: Injector,
	) {
		super(httpService);
	}

	public updateHasManyRelationship(
		endpointUrl: string,
		relationshipModels: Array<any>,
		modelType: string,
	): Observable<any> {
		const data = relationshipModels.map((model) => ({
			id: model.id,
			type: modelType,
		}));

		const options: CustomRequestOptions = {
			body: {
				data,
			},
		};

		return this.makeHttpRequest('PATCH', endpointUrl, options);
	}

	protected handleError(errorResponse: any): Observable<any> {
		// HACK: for some reason Datastore constructor is called twice on app initialization
		// and one of those two calls don't have appropriate parameters
		if (!this.errorService) {
			this.errorService = this.injector.get(ErrorService);
		}

		return this.errorService.globalErrorHandler(errorResponse);
	}

	public makeHttpRequest<T extends JsonApiModel>(
		method = 'GET',
		endpointUrl: string,
		options: CustomRequestOptions = {},
		model?: T,
		modelType?: ModelType<T>,
		apiVersion?: string,
	): Observable<JsonApiQueryData<T> | HttpResponse<T> | T> {
		const url: string = this.generateUrl(endpointUrl, apiVersion);

		let request$;

		switch (method) {
			case 'GET':
				request$ = this.makeHttpGetRequest<T>(url, options);
				break;
			case 'PUT':
				request$ = this.makeHttpPutRequest<T>(url, options.body, options);
				break;
			case 'PATCH':
				request$ = this.makeHttpPatchRequest<T>(url, options.body, options);
				break;
			case 'POST':
				request$ = this.makeHttpPostRequest<T>(url, options.body, options);
				break;
			case 'DELETE':
				request$ = this.makeHttpDeleteRequest<T>(url, options);
				break;
			default:
				console.error(
					`Not supported method ${method} for a custom call. Check makeHttpRequest for more information.`,
				);
				return of({}) as Observable<T>;
		}

		return request$.pipe(
			map((response: HttpResponse<T>) => {
				if (model && modelType) {
					const extractedModel = this['extractRecordData'](response, modelType, model);
					return extractedModel;
				} else {
					const response1 = options.fullResponse ? response : response.body;
					return response1;
				}
			}),
			catchError(this.handleError.bind(this)),
		);
	}

	private makeHttpGetRequest<T>(
		url: string,
		options: CustomRequestOptions = {},
	): Observable<HttpResponse<T>> {
		const opts = Object.assign(options, {
			observe: 'response',
		});

		return this.httpService.get<T>(url, opts) as Observable<HttpResponse<T>>;
	}

	private makeHttpDeleteRequest<T>(
		url: string,
		options: CustomRequestOptions = {},
	): Observable<HttpResponse<T>> {
		const opts = Object.assign(options, {
			observe: 'response',
		});

		return this.httpService.delete<T>(url, opts) as Observable<HttpResponse<T>>;
	}

	private makeHttpPutRequest<T>(
		url: string,
		body: object,
		options: CustomRequestOptions = {},
	): Observable<HttpResponse<T>> {
		const opts = Object.assign(options, {
			observe: 'response',
		});

		return this.httpService.put<T>(url, body, opts) as Observable<HttpResponse<T>>;
	}

	private makeHttpPatchRequest<T>(
		url: string,
		body: object,
		options: CustomRequestOptions = {},
	): Observable<HttpResponse<T>> {
		const opts = Object.assign(options, {
			observe: 'response',
		});

		return this.httpService.patch<T>(url, body, opts) as Observable<HttpResponse<T>>;
	}

	private makeHttpPostRequest<T>(
		url: string,
		body: object,
		options: CustomRequestOptions = {},
	): Observable<HttpResponse<T>> {
		const opts = Object.assign(options, {
			observe: 'response',
		});

		return this.httpService.post<T>(url, body, opts) as Observable<HttpResponse<T>>;
	}

	public customEndpointQueryData<T extends JsonApiModel>(
		endpointUrl: string,
		modelType: ModelType<T>,
		withMeta?: boolean,
		options: CustomRequestOptions = {},
	): Observable<JsonApiQueryData<T> | Array<T>> {
		const queryData$: Subject<JsonApiQueryData<T> | Array<T>> = new Subject();

		const requestOptions: CustomRequestOptions = Object.assign(options, { fullResponse: true });

		this.makeHttpRequest('GET', endpointUrl, requestOptions)
			.pipe(catchError((error: any) => throwError(error)))
			.subscribe((response: HttpResponse<T>) => {
				const extractedModels = this['extractQueryData'](response, modelType, withMeta);
				queryData$.next(extractedModels);
			});

		return queryData$;
	}

	public generateUrl(
		endpointUrl: string,
		apiVersion: string = this.appConfig.api.endpoint,
		baseUrl: string = this.appConfig.api.host,
	): string {
		return [baseUrl, apiVersion, endpointUrl].join('/');
	}
}
