/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';

@Injectable()
export class ServiceWorkerService {
	public registerServiceWorker(path: string): void {
		if (!this.areServiceWorkersSupported) {
			console.warn('Service workers are not supported in current browser.');
			return;
		}

		navigator.serviceWorker.register(path);
	}

	private get areServiceWorkersSupported(): boolean {
		return 'serviceWorker' in navigator;
	}
}
