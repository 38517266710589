/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiModelConfig, JsonApiModel, Attribute } from 'angular2-jsonapi';

@JsonApiModelConfig({
	type: 'countryStatistics',
})
export class CountryStatistics extends JsonApiModel {
	@Attribute()
	public newConsumers30Days: number;

	@Attribute()
	public activeConsumers30Days: number;

	@Attribute()
	public allConsumers: number;

	@Attribute()
	public allRecipes: number;

	@Attribute()
	public allRecipesByConsumer: number;

	@Attribute()
	public allRecipesByPhilips: number;

	@Attribute()
	public allTips: number;

	@Attribute()
	public liveRecipes: number;

	@Attribute()
	public liveRecipesByConsumer: number;

	@Attribute()
	public liveRecipesByPhilips: number;

	@Attribute()
	public liveTips: number;

	@Attribute()
	public otherConsumers: number;

	@Attribute()
	public otherRecipes: number;

	@Attribute()
	public otherRecipesByConsumer: number;

	@Attribute()
	public otherRecipesByPhilips: number;

	@Attribute()
	public otherTips: number;
}
