/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { provideEnvironmentVariables } from '@infinum/ngx-nuts-and-bolts/env';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { loadEnvironmentVariables } from './app/utils/helpers/environment-loader/environment-loader.helper';

function addNewRelicScriptIntoHead() {
	const newRelicScript = document.createElement('script');
	newRelicScript.type = 'text/javascript';
	newRelicScript.src = '/assets/vendors/new-relic.min.js';
	document.head.appendChild(newRelicScript);
}

function bootstrapApp(envs: Record<string, string>) {
	if (environment.production) {
		enableProdMode();
	}

	if (envs.ENV === 'production') {
		addNewRelicScriptIntoHead();
	}

	platformBrowserDynamic([provideEnvironmentVariables(envs)])
		.bootstrapModule(AppModule)
		.catch((err) => console.log(err));
}

loadEnvironmentVariables().then((envs) => {
	bootstrapApp(envs);
});
