/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Language } from '../../../enums/language.enum';
import { Accessory } from '../../../models/accessory.hal.model';
import { Content } from '../../../models/content.model';
import { Device } from '../../../models/device.hal.model';
import { RecipeStep } from '../../../models/recipe-step.model';
import { Translation } from '../../../models/translation.model';
import { getTranslationForLanguage } from '../helpers';
import { ArticleStep } from './../../../models/article-step.model';

export type TranslatableModel = Content | RecipeStep | ArticleStep | Device | Accessory;

export function getTranslatableProperty(
	model: TranslatableModel,
	translatablePropertyKey: string,
	language: Language,
): string {
	const defaultLabel = `Unknown translation for ${model.displayNameTranslationKey} ${translatablePropertyKey}`;

	if (!model.translations) {
		return model[translatablePropertyKey] || defaultLabel;
	}

	const translation: Translation = getTranslationForLanguage(model.translations, language);
	return translation
		? translation[translatablePropertyKey]
		: model[translatablePropertyKey] || defaultLabel;
}
