<h2 mat-dialog-title>{{ 'sensitiveFieldChecks.modal.title' | translate }}</h2>

<mat-dialog-content class="reasons">
	{{ 'sensitiveFieldChecks.modal.details' | translate }}
	<ul>
		<li *ngFor="let reason of data.extra.checkingResultsReasons">
			{{ reason | translate }}
		</li>
	</ul>
</mat-dialog-content>

<confirmation-actions [data]="data" (cancel)="onCancelClick()"></confirmation-actions>
