/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccessDeniedContainerComponent } from 'app/pages/access-denied-container/access-denied-container.component';
import { AccessDeniedModule } from 'app/components/access-denied/access-denied.module';

@NgModule({
	imports: [CommonModule, AccessDeniedModule],
	declarations: [AccessDeniedContainerComponent],
})
export class AccessDeniedContainerModule {}
