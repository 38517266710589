/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HasMany as FormHasMany } from 'ngx-form-object';
import { Attribute, HasMany, HasOne, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { Language } from '../enums/language.enum';
import { capitalize, getTranslationForLanguage } from '../utils/helpers/helpers';
import { AttributeConfig } from './attribute-config/attribute-config.model';
import { CategoryTranslation } from './category-translation.model';
import { Content } from './content.model';
import { Media } from './media.hal.model';

@ModelConfig({
	type: 'Category',
})
export class Category extends Content {
	public static modelType = 'Category';

	@Attribute()
	public abbreviation: string;

	@Attribute()
	public slug: string;

	@Attribute()
	public name: string;

	@HasOne({ propertyClass: Media })
	public icon: Media;

	@HasMany({
		itemsType: CategoryTranslation,
	})
	@FormHasMany()
	public translations: Array<CategoryTranslation>;

	@HasOne({ propertyClass: Category })
	public parent: Category;

	@HasOne({ propertyClass: Category })
	public categoryRoot: Category;

	@HasOne({
		propertyClass: 'AttributeConfig',
	})
	public attributeConfig: AttributeConfig;

	@HasMany({
		itemsType: Category,
	})
	public associated: Array<Category>;

	@HasMany({
		itemsType: Category,
	})
	public children: Array<Category>;

	public get hasChildCategories(): boolean {
		return (
			Boolean(this.getRelationshipUrl('children')) || Boolean(this.getRelationship('children'))
		);
	}

	public get displayName(): string {
		return this.name || capitalize(this.slug.replace(/_/g, ' ').toLowerCase());
	}

	public displayNameTranslationKey = 'Category';

	public getDisplayName(language: Language): string {
		const defaultLabel = `Unknown translation for ${this.displayNameTranslationKey}`;

		if (!this.translations) {
			return this.displayName || defaultLabel;
		}

		const translation: CategoryTranslation = getTranslationForLanguage(this.translations, language);
		return translation ? translation.name : this.displayName || defaultLabel;
	}

	public get modelEndpoints(): ModelEndpoints {
		return {
			collectionEndpoint: this.datastore.rootApi.categoryCollectionEndpoint,
		};
	}
}
