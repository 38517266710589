/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { BelongsTo, HasMany as FormHasMany } from 'ngx-form-object';
import { Attribute, HasMany, HasOne, ModelConfig } from 'ngx-hal';
import { Language } from '../enums/language.enum';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { getTranslatableProperty } from '../utils/helpers/get-translatable-property/get-translatable-property.helper';
import { ArticleStepTranslation } from './article-step-translation.model';
import { Content } from './content.model';
import { Media } from './media.hal.model';

@ModelConfig({
	type: 'ArticleStep',
})
export class ArticleStep extends HalDatastoreModel {
	public static modelType = 'ArticleStep';

	@Attribute()
	public title: string;

	@Attribute()
	public description: string;

	@HasOne({
		propertyClass: Media,
		externalName: 'image',
		includeInPayload: true,
	})
	@BelongsTo()
	public stepImage: Media;

	@HasOne({
		propertyClass: Media,
		includeInPayload: true,
	})
	@BelongsTo()
	public video: Media;

	@HasOne({
		propertyClass: null,
	})
	public parent: Content;

	@HasMany({
		itemsType: ArticleStepTranslation,
	})
	@FormHasMany()
	public translations: Array<ArticleStepTranslation>;

	public displayNameTranslationKey = 'articleStep';

	public getTranslatableProperty(language: Language, translatablePropertyKey: string): string {
		return getTranslatableProperty(this, translatablePropertyKey, language);
	}
}
