/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum SortDirection {
	ASC = 1,
	DESC = 2,
}

export const sortDirectionData = {
	[SortDirection.ASC]: {
		prefix: '',
		value: 'asc',
	},
	[SortDirection.DESC]: {
		prefix: '-',
		value: 'desc',
	},
};
