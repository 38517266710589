/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG, IAppConfig } from 'app/app.config';
import { ConstantService } from 'app/services/constant/constant.service';
import { HttpService } from 'app/services/http/http.service';
import { StorageService } from 'app/services/storage/storage.service';
import { Observable, of, tap } from 'rxjs';
import { SsoProvider, ssoProvidersData } from '../../enums/sso-provider.enum';
import { AdministratorRoot } from '../../models/administrator-root.model';
import { Administrator } from '../../models/administrator.model';
import { Tenant } from '../../models/tenant.model';
import { AdministratorService } from '../administrator/administrator.service';
import { SessionStorageService } from '../storage/session-storage/session-storage.service';

@Injectable()
export class AuthService {
	private readonly defaultRedirectURL = '/admin/welcome';
	private cachedCurrentAdministrator: Administrator;
	private cachedCurrentAdministratorConfig: AdministratorRoot;
	private readonly apiEndpoint = `${this.config.api.host}/${this.config.api.endpoint}`;
	private readonly apiV2Endpoint = `${this.config.apiv2.host}/${this.config.apiv2.endpoint}`;
	private readonly logoutURL = `${this.apiEndpoint}/logout`;
	private readonly redirectURLSessionStorageKey = 'redirectURLafterLogin';

	constructor(
		@Inject(APP_CONFIG) private readonly config: IAppConfig,
		private readonly storageService: StorageService,
		private readonly sessionStorageService: SessionStorageService,
		private readonly httpService: HttpService,
		private readonly administrationService: AdministratorService,
		private readonly constantService: ConstantService,
		private readonly router: Router,
	) {}

	public get currentAdministrator(): Administrator {
		return this.cachedCurrentAdministrator;
	}

	public set currentAdministrator(administrator: Administrator) {
		this.cachedCurrentAdministrator = administrator;
	}

	public get currentTenant(): Tenant | undefined {
		return this.cachedCurrentAdministratorConfig.tenants.find(
			(tenant) => tenant.id === this.loggedInTenantId,
		);
	}

	public get authToken(): string {
		return this.storageService.get(this.constantService.authTokenLocalStorageKey);
	}

	public get loggedInTenantId(): string {
		return this.storageService.get(this.constantService.loggedInTenantIdLocalStorageKey);
	}

	public goToLogin(redirectURL, ssoProvider?: SsoProvider): void {
		if (ssoProvider) {
			const endpoint = ssoProvidersData[ssoProvider].apiv2 ? this.apiV2Endpoint : this.apiEndpoint;
			const loginUrl = `${endpoint}/${ssoProvidersData[ssoProvider].loginUrl}`;
			this.goToExternalLogin(loginUrl);
		} else {
			this.router.navigate(['/providers']);
		}

		// Store only admin routes
		if (redirectURL && redirectURL.startsWith('/admin')) {
			this.sessionStorageService.setItem(this.redirectURLSessionStorageKey, redirectURL);
		}
	}

	private goToExternalLogin(loginUrl: string) {
		window.location.href = loginUrl;
	}

	public login(token: string): void {
		this.logoutFromTenant();
		this.storageService.add(this.constantService.authTokenLocalStorageKey, token);
	}

	public logout(): void {
		this.storageService.remove(this.constantService.authTokenLocalStorageKey);
		this.sessionStorageService.removeItem(this.redirectURLSessionStorageKey);
		this.currentAdministrator = null;

		this.router.navigate(['/providers']);
	}

	public clearToken(): void {
		this.storageService.remove(this.constantService.authTokenLocalStorageKey);
		this.storageService.remove(this.constantService.loggedInTenantIdLocalStorageKey);
	}

	public get isLoggedIn(): boolean {
		return this.authToken !== undefined;
	}

	public get isLoggedInIntoTenant(): boolean {
		return Boolean(this.authToken && this.loggedInTenantId);
	}

	public fetchCurrentAdministrator(): Observable<Administrator> {
		if (this.currentAdministrator) {
			return of(this.currentAdministrator);
		}

		return this.administrationService.fetchCurrentAdministrator().pipe(
			tap((administrator: Administrator) => {
				this.cachedCurrentAdministrator = administrator;
			}),
		);
	}

	public fetchCurrentAdministratorConfig(): Observable<AdministratorRoot> {
		return this.administrationService
			.fetchCurrentAdministratorConfig()
			.pipe(tap((config) => (this.cachedCurrentAdministratorConfig = config)));
	}

	public loginToTenant(tenant: Tenant): Observable<{ token: string }> {
		return this.httpService
			.post<{ token: string }>(tenant.getRelationshipUrl('tenantLoginEndpoint'), {
				_links: { tenant: { href: tenant.selfLink } },
			})
			.pipe(
				tap(({ token }: { token: string }) => {
					this.login(token);
					this.sessionStorageService.clear();
					this.storageService.add(this.constantService.loggedInTenantIdLocalStorageKey, tenant.id);
				}),
			);
	}

	public logoutFromTenant(): void {
		this.storageService.remove(this.constantService.loggedInTenantIdLocalStorageKey);
	}

	public get redirectUrlAfterLogin(): string {
		const storedRedirectURL = this.sessionStorageService.getItem<string>(
			this.redirectURLSessionStorageKey,
		);

		this.sessionStorageService.removeItem(this.redirectURLSessionStorageKey);

		return storedRedirectURL || this.defaultRedirectURL;
	}
}
