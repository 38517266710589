/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { NgModule } from '@angular/core';

import { OrganizationService } from './organization.hal.service';

@NgModule({
	providers: [OrganizationService],
})
export class OrganizationModule {}
