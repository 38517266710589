/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';

import { ImportResultItemType } from '../enums/import-result-item-type.enum';
import { ImportResultType } from '../enums/import-result-type.enum';

export const importResultTypeName = 'importResult';

@JsonApiModelConfig({
	type: importResultTypeName,
})
export class ImportResult extends JsonApiModel {
	@Attribute()
	public itemId: string;

	@Attribute()
	public itemType: ImportResultItemType;

	@Attribute()
	public message: string;

	@Attribute()
	public resultType: ImportResultType;

	public get isResultTypeSuccess(): boolean {
		return this.resultType === ImportResultType.TRANSLATION_IMPORTED;
	}

	public get isResultTypeWarning(): boolean {
		return this.resultType === ImportResultType.TRANSLATION_STILL_MISSING;
	}

	public get isResultTypeError(): boolean {
		const failingResults: Array<ImportResultType> = [
			ImportResultType.INVALID_TRANSLATION_PROVIDED,
			ImportResultType.SOURCE_MISSING,
			ImportResultType.SOURCE_TRANSLATION_CHANGED,
			ImportResultType.SOURCE_TRANSLATION_MISSING,
			ImportResultType.TARGET_TRANSLATION_MISSING,
			ImportResultType.INVALID_FILE_PROVIDED,
			ImportResultType.LANGUAGE_NOT_SUPPORTED,
			ImportResultType.NUMBER_STEP_TRANSLATION_DOES_NOT_MATCH_SOURCE,
			ImportResultType.TARGET_LANGUAGE_MATCHES_SOURCE_LANGUAGE,
		];
		return failingResults.some(
			(failingResult: ImportResultType) => this.resultType === failingResult,
		);
	}
}
