<button class="provider-button" (click)="onButtonClick()">
	<img
		*ngIf="iconSrc; else noIconTemplate"
		class="logo"
		alt="{{ ssoProvider }} icon"
		[src]="iconSrc"
	/>

	<ng-template #noIconTemplate>
		<div class="placeholder"></div>
	</ng-template>

	<p innerHTML="{{ 'ssoLandingPage.buttonText' | translate: { sso: ssoProvider } }}"></p>
</button>
