/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { convertSecondsToDuration } from 'app/utils/helpers/helpers';
import { formatDuration } from '../../utils/helpers/format-duration/format-duration.helper';

@Pipe({
	name: 'toHoursMinutes',
})
export class ToHoursMinutesPipe implements PipeTransform {
	constructor(public translateService: TranslateService) {}

	public transform(seconds: number): string {
		return formatDuration(convertSecondsToDuration(seconds), this.translateService);
	}
}
