/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';
import { ILanguageData } from 'app/custom-types/language-data.type';
import { Language, languageData, selectableLanguages } from 'app/enums/language.enum';
import { Locale } from 'app/enums/locale.enum';
import { IDictionary } from 'app/interfaces/dictionary.interface';
import { ISelectInterface2 } from '../../interfaces/select.interface';
import { Translation } from '../../models/translation.model';
import { transformLanguageForJsonApi } from '../../utils/helpers/language-transformer/language-transformer.helper';

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	public getLanguageLabelForId(id: Language): string {
		for (const language in languageData) {
			if (languageData[language].id === id) {
				return languageData[language].label;
			}
		}

		return id.toString();
	}

	public getLocale(language: Language): Locale {
		const languageDataObject = languageData[language];
		return languageDataObject ? languageDataObject.locale : Locale.ENGLISH;
	}

	public getLanguageData(language: Language): ILanguageData {
		const defaultLanguageValue: ILanguageData = {
			id: language,
			label: language.toString(),
			locale: Locale.ENGLISH,
			priority: 100,
		};

		return languageData[language] || defaultLanguageValue;
	}

	public isLanguageSelectable(language: Language): boolean {
		return selectableLanguages.indexOf(language) !== -1;
	}

	get selectableLanguages(): IDictionary<ILanguageData> {
		const languages: IDictionary<ILanguageData> = {};

		for (const language in languageData) {
			if (this.isLanguageSelectable(languageData[language].id)) {
				languages[language] = languageData[language];
			}
		}

		return languages;
	}

	get ingredientLanguages(): IDictionary<ILanguageData> {
		const languages: IDictionary<ILanguageData> = {};

		for (const language in languageData) {
			if (this.isIngredientLanguage(languageData[language].id)) {
				languages[language] = languageData[language];
			}
		}

		return languages;
	}

	public getSelectableLanguagesOptions(): Array<ISelectInterface2<{ id: Language }>> {
		return Object.keys(this.selectableLanguages).map((key: string) => {
			const data: ILanguageData = this.selectableLanguages[key];
			return { value: { id: data.id }, label: data.label };
		});
	}

	public isIngredientLanguage(language: Language): boolean {
		return selectableLanguages.indexOf(language) !== -1;
	}

	public compareLanguages(
		language1: Language,
		language2: Language,
		takeCountryIntoAccount = true,
	): boolean {
		if (takeCountryIntoAccount) {
			return language1 === language2;
		} else {
			return language1.split('_')[0] === language2.split('_')[0];
		}
	}

	public getTranslationLabel(translations: Array<Translation> = []): string {
		return translations
			.map((translation) =>
				this.getLanguageLabelForId(transformLanguageForJsonApi(translation.language) as Language),
			)
			.filter((translated) => translated)
			.join(', ');
	}
}
