/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HasOne, ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Food } from './food.model';

export interface IFoodUnitConversionItem {
	quantity: number;
	unit: string;
}

@ModelConfig({
	type: 'FoodUnitConversion',
})
export class FoodUnitConversion extends HalDatastoreModel {
	@Attribute()
	public source: IFoodUnitConversionItem;

	@Attribute()
	public target: IFoodUnitConversionItem;

	@HasOne({ propertyClass: 'Food' })
	public food: Food;
}
