/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { formatISO, parseISO } from 'date-fns';
import { PropertyConverter } from '../../interfaces/property-converter.interface';

export class DateConverter implements PropertyConverter {
	mask(value: any) {
		return parseISO(value);
	}

	unmask(value: any) {
		return formatISO(value);
	}
}
