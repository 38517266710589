/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import {
	ExtendedFormArray,
	Attribute as FormAttribute,
	HasMany as FormHasMany,
} from 'ngx-form-object';
import { arrayAttr, Attribute, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { CountryFeatures } from '../classes/country-features.class';
import { DeviceRegion } from '../enums/deviceRegion.enum';
import { ElectricSystem } from '../enums/electric-system.enum';
import { Language } from '../enums/language.enum';
import { UnitSystemSlug } from '../enums/unit-system.enum';
import { LanguageConfigFormStore } from '../forms/language-config/language-config.form-store';
import { ILanguageConfig, ILanguageConfigHal } from '../interfaces/language-config.interface';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';
import { removeDuplicates } from '../utils/helpers/helpers';
import {
	transformLanguageConfigForHalApi,
	transformLanguageConfigForJsonApi,
} from '../utils/helpers/language-transformer/language-transformer.helper';
import { CountryCode } from './../enums/country-code.enum';
import { LanguageConfig } from './language-config.model';

@ModelConfig({
	type: 'Country',
})
export class Country extends HalDatastoreModel {
	public get modelEndpoints(): ModelEndpoints {
		return {
			singleResourceEndpoint: this.datastore.rootApi.singleCountryEndpoint,
			collectionEndpoint: this.datastore.rootApi.countriesEndpoint,
		};
	}

	@Attribute()
	@FormAttribute()
	public code: string;

	@Attribute()
	public enabled: boolean;

	@Attribute()
	@FormAttribute()
	public name: string;

	@Attribute()
	@FormAttribute()
	public electricSystem: ElectricSystem;

	@Attribute()
	@FormAttribute()
	public unitSystem: UnitSystemSlug;

	@Attribute({
		useClass: CountryFeatures,
		transformBeforeSave: (features: CountryFeatures) => ({
			basic: features.basic,
		}),
	})
	@FormAttribute()
	public features: CountryFeatures;

	@Attribute({
		useClass: () => arrayAttr(LanguageConfig),
		transformResponseValue: (rawValue: Array<ILanguageConfigHal>) =>
			rawValue &&
			rawValue.map((languageConfig) => transformLanguageConfigForJsonApi(languageConfig)),
		transformBeforeSave: (rawValue: Array<ILanguageConfig>) =>
			rawValue &&
			rawValue.map((languageConfig: ILanguageConfig) =>
				transformLanguageConfigForHalApi(languageConfig),
			),
	})
	@FormHasMany({
		isChanged: (
			initial: Array<LanguageConfig>,
			current: Array<LanguageConfig>,
			formArray: ExtendedFormArray,
		) => {
			const isArrayItemChanged: boolean = (
				formArray.controls as Array<LanguageConfigFormStore>
			).some((item) => item && item.isChanged);
			return (
				isArrayItemChanged ||
				!compareArrays(initial, current, {
					ignoreOrder: false,
					propertyFn: (item) => item.language,
				})
			);
		},
	})
	public languages: Array<LanguageConfig>;

	@Attribute()
	@FormAttribute()
	public deviceRegion: DeviceRegion;

	@Attribute()
	@FormAttribute()
	public marketingOptInEnabled: boolean;

	@Attribute()
	@FormAttribute()
	public communityEnabled: boolean;

	public get supportedLanguages(): Array<Language> {
		return removeDuplicates(
			this.languages
				.map((languageConfig) =>
					[languageConfig.language, languageConfig.languageOfOrigin].filter((language) => language),
				)
				.flat(),
		);
	}

	public get isGlobal(): boolean {
		return this.code === CountryCode.GLOBAL;
	}
}
