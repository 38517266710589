/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { BelongsTo as FormBelongsTo, HasMany as FormHasMany } from 'ngx-form-object';
import {
	Attribute,
	HasMany,
	HasOne,
	HeaderAttribute,
	Link,
	ModelConfig,
	ModelEndpoints,
} from 'ngx-hal';
import { CollectionType } from '../enums/collection-type.enum';
import { contentTypeData } from '../enums/content-type.hal.enum';
import { Language } from '../enums/language.enum';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';
import {
	transformLanguageForHalApi,
	transformLanguageForJsonApi,
} from '../utils/helpers/language-transformer/language-transformer.helper';
import { Accessory } from './accessory.hal.model';
import { Article } from './article.model';
import { Category } from './category.model';
import { CollectionContentContainer } from './collection-content-container.model';
import { CollectionTranslation } from './collection-translation.hal.model';
import { Content } from './content.model';
import { Country } from './country.hal.model';
import { Device } from './device.hal.model';
import { Media } from './media.hal.model';
import { Recipe } from './recipe.hal.model';

@ModelConfig({
	type: 'Collection',
	endpoint: 'Collection',
})
export class Collection extends Content {
	public get modelEndpoints(): ModelEndpoints {
		return {
			singleResourceEndpoint: this.datastore.rootApi.singleCollectionEndpoint,
			collectionEndpoint: this.datastore.rootApi.collectionsEndpoint,
		};
	}

	@Attribute()
	public title: string;

	@Attribute({
		externalName: 'description',
	})
	// Use only for creation of the Collection resource
	public initialDescription: string;

	@HeaderAttribute({
		transformResponseValue: transformLanguageForJsonApi,
		transformBeforeSave: transformLanguageForHalApi,
		externalName: 'Content-Language',
	})
	// Use only for creation of the Collection resource
	public initialLanguage: Language;

	@HasOne({
		propertyClass: Media,
		externalName: 'image',
		includeInPayload: true,
	})
	@FormBelongsTo()
	public coverImage: Media;

	@Attribute({
		externalName: 'type',
	})
	public collectionType: CollectionType;

	@HasMany({
		itemsType: Recipe,
		includeInPayload: true,
	})
	@FormHasMany({
		isChanged: (initial: Array<Recipe>, current: Array<Recipe>) =>
			!compareArrays(initial, current, {
				ignoreOrder: false,
				propertyFn: (item: Recipe) => item.id,
			}),
	})
	public recipes: Array<Recipe>;

	@HasMany({
		itemsType: Collection,
		includeInPayload: true,
	})
	@FormHasMany({
		isChanged: (initial: Array<Collection>, current: Array<Collection>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true, // backend does not support saving of collections order
				propertyFn: (item: Collection) => item.id,
			}),
	})
	public collections: Array<Collection>;

	@HasMany({
		itemsType: Article,
		includeInPayload: true,
	})
	@FormHasMany({
		isChanged: (initial: Array<Article>, current: Array<Article>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Article) => item.id,
			}),
	})
	public articles: Array<Article>;

	@HasMany({
		itemsType: 'CollectionContentContainer',
	})
	public highlights: Array<CollectionContentContainer>;

	@HasMany({
		itemsType: Accessory,
		includeInPayload: true,
	})
	@FormHasMany({
		isChanged: (initial: Array<Accessory>, current: Array<Accessory>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true, // backend does not support saving of accessory order
				propertyFn: (item: Accessory) => item.id,
			}),
	})
	public accessories: Array<Accessory>;

	@HasMany({
		itemsType: Device,
		includeInPayload: true,
	})
	@FormHasMany({
		isChanged: (initial: Array<Device>, current: Array<Device>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true, // backend does not support saving of device order
				propertyFn: (item: Device) => item.id,
			}),
	})
	public devices: Array<Device>;

	@HasMany({
		itemsType: CollectionTranslation,
	})
	@FormHasMany()
	public translations: Array<CollectionTranslation>;

	@HasOne({ propertyClass: CollectionTranslation })
	@FormBelongsTo()
	public defaultTranslation: CollectionTranslation;

	@HasMany({
		itemsType: Category,
	})
	@FormHasMany()
	public categories: Array<Category>;

	@HasMany({
		itemsType: Category,
	})
	@FormHasMany()
	public deviceCategories: Array<Category>;

	@FormBelongsTo()
	public collectionTypeCategory: Category;

	@HasMany({
		itemsType: Country,
		includeInPayload: true,
		externalName: 'countries',
	})
	// Use only for creation of the Collection resource
	public initialCountries: Array<Country>;

	@HasMany({
		itemsType: Category,
		includeInPayload: true,
		externalName: 'categories',
	})
	// Use only for creation of the Collection resource
	public initialCategories: Array<Category>;

	public get displayImage(): Media {
		return this.coverImage || this.firstRecipeCoverImage;
	}

	private get firstRecipeCoverImage(): Media {
		const recipeWithImage: Recipe = this.recipes
			? this.recipes.find((recipe: Recipe) => recipe.coverImage)
			: null;
		return recipeWithImage ? recipeWithImage.coverImage : null;
	}

	public get thumbnailImages(): Array<Media> {
		if (!this.recipes) {
			return [];
		}

		return this.recipes
			.filter(({ coverImage }) => coverImage)
			.map(({ coverImage }) => coverImage)
			.slice(0, 4);
	}

	public get numberOfRecipes(): number {
		return this.getRelationshipTotalElements('recipes');
	}

	public get numberOfCollections(): number {
		return this.getRelationshipTotalElements('collections');
	}

	@Link({
		externalName: 'bulkPublishing',
	})
	public bulkPublishingEndpoint: string;

	public get displayNameTranslationKey(): string {
		return contentTypeData[this.collectionTypeCategory?.slug]?.translationKey;
	}
}
