/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injector } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BuildControl, ExtendedFormControl, FormObjectOptions } from 'ngx-form-object';
import { PropertyOptions } from 'ngx-form-object/lib/interfaces/property-options.interface';
import { Observable } from 'rxjs';
import { Administrator } from '../../models/administrator.model';
import { Organization } from '../../models/organization.hal.model';
import { Role } from '../../models/role.model';
import { BaseFormObject } from '../base-form-object/base.form-object';
import { RoleFormStore } from './role.form-store';
import { roleOrganizationValidator } from './validators/role-organization.validator';

export class RoleFormObject extends BaseFormObject<Role> {
	public formStoreClass = RoleFormStore;

	private readonly translateService: TranslateService;

	constructor(
		public readonly model: Role,
		protected readonly options: FormObjectOptions,
		protected readonly injector: Injector,
		protected readonly parentModel: Administrator,
	) {
		super(model, options);

		this.translateService = this.injector.get(TranslateService);
	}

	public validators: Record<string, ValidatorFn | Array<ValidatorFn>> = {
		role: Validators.required,
		organizations: (control) =>
			roleOrganizationValidator(
				control,
				this.translateService.instant('role.organization.errors.required'),
			),
	};

	protected save(model: Role): Observable<Role> {
		return model.saveViaParent(this.parentModel, 'roles');
	}

	@BuildControl('organizations')
	public buildOrganizations(
		organizations: Array<Organization> = [],
		validators: ValidatorFn | Array<ValidatorFn>,
		propertyOptions: PropertyOptions,
	): ExtendedFormControl {
		return new ExtendedFormControl(organizations, validators, null, true, propertyOptions);
	}
}
