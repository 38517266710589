/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Component, Inject, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';

import { IDialogData } from 'app/components/popup-dialog/dialog-options.interface';
import { PopupDialogComponent } from 'app/components/popup-dialog/popup-dialog.component';

@Component({
	selector: 'error-modal',
	templateUrl: './error-modal.component.html',
	styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent extends PopupDialogComponent {
	public constructor(
		public dialogRef: MatDialogRef<PopupDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IDialogData,
		protected renderer: Renderer2,
		private readonly translateService: TranslateService,
	) {
		super(dialogRef, data, renderer);
	}

	public get title(): string {
		return this.errorTitle || this.translateService.instant(`error.http_${this.response.status}`);
	}

	public get isOnekaErrorResponse(): boolean {
		const contentType: string = this.response.headers.get('content-type') || '';
		return contentType.indexOf('application/vnd.oneka.v2.0+json') > -1;
	}

	public get errors(): Array<any> {
		let errors = this.response['error'];

		if (this.isMesssageJsonFormat()) {
			errors = JSON.parse(errors || '{}');
		}

		return (errors && errors.errors) || [];
	}

	public isMesssageJsonFormat(): boolean {
		try {
			JSON.parse(this.response['error'].message);
		} catch (e) {
			return false;
		}

		return true;
	}

	protected get callCancelOnKeypressEnter(): boolean {
		return true;
	}

	private get response(): HttpResponse<any> {
		return this.data.extra.response || {};
	}

	private get errorTitle(): string {
		let title = '';
		if (this.isOnekaErrorResponse) {
			title = this.response['error'] ? this.response['error'].title : null;
		}
		return title;
	}
}
