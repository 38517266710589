/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { BelongsTo, Attribute as FormAttribute, HasMany as FormHasMany } from 'ngx-form-object';
import { Attribute, HasMany, HasOne, Link, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';
import { Accessory } from './accessory.hal.model';
import { ArticleStep } from './article-step.model';
import { ArticleTranslation } from './article-translation.model';
import { Category } from './category.model';
import { Content } from './content.model';
import { Device } from './device.hal.model';
import { Media } from './media.hal.model';
import { Recipe } from './recipe.hal.model';

@ModelConfig({
	type: 'Article',
})
export class Article extends Content {
	public static modelType = 'Article';

	public get modelEndpoints(): ModelEndpoints {
		return {
			singleResourceEndpoint: this.datastore.rootApi.singleArticleEndpoint,
			collectionEndpoint: this.datastore.rootApi.collectionArticlesEndpoint,
		};
	}

	@Attribute()
	public description: string;

	@Attribute()
	@FormAttribute()
	public hyperlink: string;

	@Attribute()
	public title: string;

	@HasOne({
		propertyClass: Media,
		externalName: 'image',
		includeInPayload: true,
	})
	@BelongsTo()
	public coverImage: Media;

	@HasOne({
		propertyClass: Media,
		includeInPayload: true,
	})
	@BelongsTo()
	public video: Media;

	@HasMany({
		itemsType: Device,
	})
	@FormHasMany({
		isChanged: (initial: Array<Device>, current: Array<Device>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Device) => item?.id || '',
			}),
	})
	public devices: Array<Device>;

	@HasMany({
		itemsType: Accessory,
	})
	@FormHasMany({
		isChanged: (initial: Array<Accessory>, current: Array<Accessory>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Accessory) => item?.id || '',
			}),
	})
	public accessories: Array<Accessory>;

	@HasMany({
		itemsType: ArticleTranslation,
	})
	@FormHasMany()
	public translations: Array<ArticleTranslation>;

	@HasOne({
		propertyClass: ArticleTranslation,
		includeInPayload: true,
	})
	@BelongsTo()
	public defaultTranslation: ArticleTranslation;

	@HasMany({
		itemsType: Category,
	})
	@FormHasMany({
		isChanged: (initial: Array<Category>, current: Array<Category>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Category) => item?.slug || '',
			}),
	})
	public deviceCategories: Array<Category>;

	@HasMany({
		itemsType: Category,
	})
	@FormHasMany({
		isChanged: (initial: Array<Category>, current: Array<Category>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Category) => item?.slug || '',
			}),
	})
	public articleCategories: Array<Category>;

	@HasMany({
		itemsType: Recipe,
	})
	@FormHasMany()
	public recipes: Array<Recipe>;

	@HasMany({
		itemsType: Article,
	})
	@FormHasMany()
	public articles: Array<Article>;

	@HasMany({
		itemsType: ArticleStep,
		includeInPayload: true,
	})
	@FormHasMany()
	public steps: Array<ArticleStep>;

	@Link({
		externalName: 'products',
	})
	public deviceAndAccessoryCreationEndpoint: string;

	public displayNameTranslationKey = 'contentTypeHal.tip';
}
