/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class Http2Interceptor implements HttpInterceptor {
	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let http2Request;

		if (request.url.indexOf('api/v2') !== -1) {
			http2Request = request.clone({
				headers: request.headers.set('api-version', '2.0.0'),
			});
		}

		return next.handle(http2Request || request);
	}
}
