/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum ReportedContentStatus {
	REPORTED = 'REPORTED',
	IN_REVIEW = 'IN_REVIEW',
	REVIEWED = 'REVIEWED',
}

export const reportedContentStatusData = {
	[ReportedContentStatus.REPORTED]: {
		id: ReportedContentStatus.REPORTED,
		translationKey: 'reportedContentStatus.reported',
	},
	[ReportedContentStatus.IN_REVIEW]: {
		id: ReportedContentStatus.IN_REVIEW,
		translationKey: 'reportedContentStatus.inReview',
	},
	[ReportedContentStatus.REVIEWED]: {
		id: ReportedContentStatus.REVIEWED,
		translationKey: 'reportedContentStatus.reviewed',
	},
};
