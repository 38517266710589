/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { UntypedFormGroup } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';

import { ContentStatus } from '../../../enums/content-status.enum';
import { IDictionary } from '../../../interfaces/dictionary.interface';
import { contains } from '../helpers';
import { ContentType, contentTypeData } from '../../../enums/content-type.hal.enum';

import { IMissingFormInfoDialogOptions } from 'app/utils/helpers/form/missing-form-info-dialog-options.interface';
import { PopupDialogService } from 'app/services/popup-dialog/popup-dialog.service';
import { modelTypePrefixData } from 'app/enums/model-type-prefix.enum';
import { extractErrorMessages } from 'app/utils/helpers/form/extract-error-messages.helper';

export function openMissingInformationDialog(
	dialogService: PopupDialogService,
	translateService: TranslateService,
	form: UntypedFormGroup,
	options: IMissingFormInfoDialogOptions = {},
): Observable<any> {
	const errorMessages: Array<string> = extractErrorMessages(form);

	let errorsHtml = '';

	errorMessages.forEach((message: string) => {
		errorsHtml = `${errorsHtml}<div>${message}</div>`;
	});

	if (options.contentType) {
		let message: string;

		if (
			options.canBeEditedWithoutChangingTheStatus ||
			(form['model'] && form['model'].status === ContentStatus.DRAFT)
		) {
			message = translateService.instant('missingInformationDialog.editFollowingInformation');
		} else {
			const modelData: IDictionary<any> = contains(Object.values(ContentType), options.contentType)
				? contentTypeData
				: modelTypePrefixData;

			message = translateService.instant('missingInformationDialog.moveToDraftMessage', {
				contentType: translateService.instant(
					options.contentTypeTranslationKey
						? options.contentTypeTranslationKey
						: modelData[options.contentType].translationKey,
				),
			});
		}

		errorsHtml = `<p>${message}</p>${errorsHtml}`;
	}

	if (options.question) {
		errorsHtml = `${errorsHtml}<p>${options.question}</p>`;
	}

	const defaultErrorMessage: string = translateService.instant(
		'missingInformationDialog.defaultErrorMessage',
	);
	const errorMessage = errorsHtml || defaultErrorMessage;

	let dialog = dialogService
		.setTitle(translateService.instant('missingInformationDialog.title'))
		.setContent(errorMessage, true)
		.setCancelButton({
			label: options.cancelLabel || translateService.instant('button.ok'),
			inverse: true,
		})
		.isCancelable();

	if (options.saveLabel) {
		dialog = dialog.setSaveButton({
			label: options.saveLabel,
		});
	}

	const dialog$ = dialog.open().afterClosed();

	dialog$.subscribe();

	return dialog$;
}
