/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export function groupAsArrayByHelper<T>(items: Array<T> = [], groupBy = ''): Array<Array<T>> {
	const groupedItems: object = {};

	items.forEach((item) => {
		const groupName = groupBy.split('.').reduce((arr, i) => {
			if (arr) {
				return arr[i];
			}

			return item[i];
		}, undefined);

		groupedItems[groupName] = groupedItems[groupName] || [];
		groupedItems[groupName].push(item);
	});

	return Object.keys(groupedItems).map((key) => groupedItems[key]);
}
