/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotFoundContainerComponent } from './not-found-container.component';
import { NotFoundModule } from './../../components/not-found/not-found.module';

@NgModule({
	imports: [CommonModule, NotFoundModule],
	declarations: [NotFoundContainerComponent],
})
export class NotFoundContainerModule {}
