/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { contains } from 'app/utils/helpers/contains/contains.helper';
import { ComparisonFunction } from 'app/custom-types/comparison-function.type';

export function intersection(
	array1: Array<any>,
	array2: Array<any>,
	comparisonFunction?: ComparisonFunction<any>,
): Array<any> {
	const set2: Set<any> = new Set(array2);
	const result: Set<any> = new Set(
		array1.filter((item) => {
			if (comparisonFunction) {
				return contains(array2, item, comparisonFunction);
			} else {
				return set2.has(item);
			}
		}),
	);

	return Array.from(result);
}
