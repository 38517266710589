/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HasOne, ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Collection } from './collection.hal.model';
import { ContentContainer } from './content-container.model';

@ModelConfig({
	type: 'CollectionContentContainer',
})
export class CollectionContentContainer extends HalDatastoreModel {
	public static modelType = 'CollectionContentContainer';

	@HasOne({
		propertyClass: Collection,
	})
	public collection: Collection;

	@HasOne({
		propertyClass: ContentContainer,
	})
	public contentContainer: ContentContainer;
}
