/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { IImageLimit } from '../../constants/image-limit.constant';
import {
	ContentContainerContentType,
	ContentContainerType,
} from '../../enums/content-container-type.enum';
import { Feature } from '../../enums/feature.enum';
import { TenantName, TenantSetting, tenantData } from '../../enums/tenant.enum';

export abstract class TenantService {
	public abstract get tenantName(): TenantName;

	public abstract get appTitle(): string;

	public abstract get availableContentContainerTypes(): Partial<
		Record<
			ContentContainerType | ContentContainerContentType,
			ContentContainerType | ContentContainerContentType
		>
	>;

	private get tenantIconPrefix(): string {
		return tenantData[this.tenantName].iconPrefix;
	}

	public hasFeature(feature: Feature): boolean {
		return tenantData[this.tenantName].features.includes(feature);
	}

	public getSetting(tenantSetting: TenantSetting): IImageLimit {
		return tenantData[this.tenantName].settings[tenantSetting];
	}

	public setFavicons(document: Document): void {
		this.replaceLinkHref(document, 'icon-favicon', this.tenantIconPrefix);
		this.replaceLinkHref(document, 'icon-svg', this.tenantIconPrefix);
		this.replaceLinkHref(document, 'icon-apple', this.tenantIconPrefix);
		this.replaceLinkHref(document, 'mask-icon', this.tenantIconPrefix);
		this.replaceLinkHref(document, 'manifest', this.tenantIconPrefix);
	}

	private replaceLinkHref(document: Document, elementId: string, tenantPrefix: string): void {
		const domElement: HTMLLinkElement = document.querySelector(`#${elementId}`);
		if (domElement) {
			domElement.href = domElement.href.replace(/default/g, tenantPrefix);
		}
	}
}
