/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { IImageRatio } from '../interfaces/image-ratio.interface';

export interface IImageLimit {
	ignoreOrientation: boolean; // if set to true, width will be used for both width and height
	min: IImageRatio;
	max: IImageRatio;
	ratio: { ratioX: number; ratioY: number };
}

export const DEFAULT_COVER_IMAGE_LIMIT: IImageLimit = {
	ignoreOrientation: true,
	min: { width: 2028, height: 2028 },
	max: null,
	ratio: null,
};

export const DEFAULT_STEP_IMAGE_LIMIT: IImageLimit = {
	ignoreOrientation: false,
	min: { width: 2028, height: 1536 },
	max: null,
	ratio: { ratioX: 4, ratioY: 3 },
};

export const DEFAULT_PRODUCT_IMAGE_LIMIT: IImageLimit = {
	ignoreOrientation: false,
	min: { width: 1500, height: 1500 },
	max: { width: 1500, height: 1500 },
	ratio: { ratioX: 1, ratioY: 1 },
};
