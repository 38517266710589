/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiModelConfig, JsonApiModel, Attribute, HasMany, BelongsTo } from 'angular2-jsonapi';

import { NutrientUnit } from 'app/enums/nutrient-unit.enum';
import { Nutrient } from 'app/models/nutrient.model';

@JsonApiModelConfig({
	type: 'nutritionListItem',
})
export class NutritionListItem extends JsonApiModel {
	@Attribute()
	public amount: number;

	@Attribute()
	public dailyPercentage: number;

	@Attribute()
	public limit: number;

	@Attribute()
	public isOverLimit: boolean;

	@BelongsTo()
	public nutrientDetail: Nutrient;

	@Attribute()
	public unit: NutrientUnit;

	@Attribute()
	public dailyAmount: number;

	@HasMany()
	public nutrientItemList: Array<NutritionListItem> = [];
}
