/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable, inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { IErrorTracker } from 'app/services/error-tracker/error-tracker.interface';
import { HttpCode } from 'app/enums/http-codes.enum';
import { AwsRum } from 'aws-rum-web';
import { CLOUDWATCH_RUM } from '../../utils/cloudwatch-rum';

@Injectable({ providedIn: 'root' })
export class ErrorTrackerService {
	private newRelicTracker: IErrorTracker = window['newrelic'];
	private readonly cloudwatchRUM: AwsRum = inject(CLOUDWATCH_RUM);

	public trackError(error: any, response?: HttpResponse<any>, additionalData?: any): void {
		this.trackErrorNewRelic(error, response, additionalData);
		this.trackErrorCloudwatchRUM(error, response, additionalData);
	}

	public trackErrorNewRelic(error: any, response?: HttpResponse<any>, additionalData?: any): void {
		this.loadNewRelicErrorTrackerIfNeeded();

		if (!this.newRelicTracker || this.shouldSkipErrorReporting(response)) {
			return;
		}

		this.newRelicTracker.noticeError(error, additionalData);
	}

	public trackErrorCloudwatchRUM(
		error: any,
		response?: HttpResponse<any>,
		additionalData?: any,
	): void {
		if (!this.cloudwatchRUM || this.shouldSkipErrorReporting(response)) {
			return;
		}

		this.cloudwatchRUM.recordError(error);
	}

	private shouldSkipErrorReporting(response: HttpResponse<any>): boolean {
		return response && response.status === HttpCode.CLIENT_401_UNAUTHORIZED;
	}

	private loadNewRelicErrorTrackerIfNeeded(): void {
		if (!this.newRelicTracker) {
			this.newRelicTracker = window['newrelic'];
		}
	}
}
