/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

// Copied from the old version of json-api datastore library
export function toQueryString(params: any): string {
	let encodedStr = '';

	for (const key in params) {
		if (params.hasOwnProperty(key)) {
			if (encodedStr && encodedStr[encodedStr.length - 1] !== '&') {
				encodedStr = `${encodedStr}&`;
			}

			const value: any = params[key];
			if (value instanceof Array) {
				for (let i = 0; i < value.length; i++) {
					encodedStr = `${encodedStr}${key}=${encodeURIComponent(value[i])}&`;
				}
			} else if (typeof value === 'object') {
				for (const innerKey in value) {
					if (value.hasOwnProperty(innerKey)) {
						encodedStr = `${encodedStr}${key}[${innerKey}]=${encodeURIComponent(value[innerKey])}&`;
					}
				}
			} else {
				encodedStr = `${encodedStr}${key}=${encodeURIComponent(value)}`;
			}
		}
	}
	if (encodedStr[encodedStr.length - 1] === '&') {
		encodedStr = encodedStr.substr(0, encodedStr.length - 1);
	}
	return encodedStr;
}
