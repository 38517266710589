/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { ContentStatus, ContentStatusHal } from '../../../enums/content-status.enum';

export function contentStatusConverter(status: ContentStatus | ContentStatusHal): ContentStatusHal {
	const isContentStatusHal = Boolean(ContentStatusHal[status]);

	if (isContentStatusHal) {
		return status as ContentStatusHal;
	}

	const contentStatusKey: string = Object.keys(ContentStatus).find((key: string) => {
		if (ContentStatus[key] === status) {
			return key;
		}
	});

	return ContentStatusHal[contentStatusKey];
}

export function contentStatusHalConverter(status: ContentStatusHal): ContentStatus {
	const contentStatusKey: string = Object.keys(ContentStatus).find((key: string) => {
		if (ContentStatusHal[key] === status) {
			return key;
		}
	});

	return ContentStatus[contentStatusKey];
}
