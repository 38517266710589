/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { ErrorHandler, Injectable } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { ErrorTrackerService } from '../services/error-tracker/error-tracker.service';

@Injectable()
export class OneKAErrorHandler implements ErrorHandler {
	constructor(
		private readonly errorTrackerService: ErrorTrackerService,
		private readonly authService: AuthService,
	) {}

	public handleError(error: any = {}) {
		let errorObj = error;

		if (!(error instanceof Error) && typeof error === 'object') {
			errorObj = new Error(JSON.stringify(error));
		}

		const currentAdministrator = this.authService.currentAdministrator;

		const additionalData: Record<string, string | number | boolean> = {};

		if (currentAdministrator) {
			const administratorRoles = currentAdministrator.roles.map((role) => {
				const countryCodes = role.countries?.map((country) => country.code).join(', ') || '-';
				return `${role.role}: ${countryCodes}`;
			});

			additionalData.administratorRoles = administratorRoles?.join(', ') || '-';
		}

		this.errorTrackerService.trackError(errorObj, error.response, additionalData);

		console.error(errorObj);
	}
}
