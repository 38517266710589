/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { BelongsTo as FormBelongsTo } from 'ngx-form-object';
import { HasOne, ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Country } from './country.hal.model';
import { ScheduledEvent } from './scheduled-event.model';

@ModelConfig({
	type: 'ScheduledEventCountry',
	endpoint: 'ScheduledEvent/Country',
})
export class ScheduledEventCountry extends HalDatastoreModel {
	@HasOne({
		propertyClass: Country,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public country: Country;

	@HasOne({
		propertyClass: 'ScheduledEvent',
		includeInPayload: true,
	})
	@FormBelongsTo()
	public scheduledEvent: ScheduledEvent;

	public get endpoint(): string {
		return `ScheduledEventCountry/${this.scheduledEvent.id}/Country`;
	}
}
