/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, ModelConfig } from 'ngx-hal';
import { NutrientCategory } from '../custom-types/nutrient-category.type';
import { NutrientCode } from '../enums/nutrient-code.enum';
import { NutrientUnit } from '../enums/nutrient-unit.enum';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';

@ModelConfig({
	type: 'Nutrient',
	endpoint: 'Nutrient',
})
export class Nutrient extends HalDatastoreModel {
	@Attribute()
	public category: NutrientCategory;

	@Attribute()
	public code: NutrientCode;

	@Attribute()
	public name: string;

	@Attribute()
	public unit: NutrientUnit;
}
