/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum DeepLinkGeneratorContentType {
	RECIPE = 'recipe',
	RECIPE_BOOK = 'recipe_book',
	ARTICLE = 'tip',
	HIGHLIGHT = 'highlight',
}

export interface IDeepLinkGeneratorData {
	content_id: string;
	content_type: DeepLinkGeneratorContentType;
	canonical_identifier: string;
	$og_title?: string;
	$og_description?: string;
	$og_image_url?: string;
}
