/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Observable } from 'rxjs';

import { PopupDialogService } from 'app/services/popup-dialog/popup-dialog.service';
import { ICloseConfirmationDialogOptions } from 'app/utils/helpers/form/close-confirmation-dialog/close-confirmation-dialog-options.interface';

export function openCloseConfirmationDialog(
	dialogService: PopupDialogService,
	options: ICloseConfirmationDialogOptions,
): Observable<any> {
	const dialog = dialogService
		.setTitle(options.title)
		.setContent(options.description, options.isHtmlContent)
		.setCancelButton({
			label: options.discardLabel || 'Discard',
			inverse: true,
		})
		.setSaveButton({
			label: options.saveLabel || 'Save',
		})
		.open();

	const dialog$ = dialog.afterClosed();

	return dialog$;
}
