/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiModelConfig, JsonApiModel, Attribute, HasMany } from 'angular2-jsonapi';
import { Attribute as FormAttribute, HasMany as FormHasMany } from 'ngx-form-object';

import { CountryProfile } from '../models/country-profile.model';
import { ModelMetadata } from '../classes/model-metadata/model-metadata';
import { CountryStatistics } from '../models/country-statistics.model';

@JsonApiModelConfig({
	type: 'domains',
	meta: ModelMetadata,
})
export class Domain extends JsonApiModel {
	public config = null;

	@FormAttribute()
	public id: string;

	@Attribute()
	@FormAttribute()
	public name: string;

	@Attribute()
	@FormAttribute()
	public responsibleFor: Array<string>;

	@HasMany()
	public countryProfiles: Array<CountryProfile>;

	@HasMany()
	@FormHasMany()
	public countryStatistics: Array<CountryStatistics>;

	public get nameKey(): string {
		return this.name
			.split(/\s+/)
			.join('')
			.toLowerCase();
	}
}
