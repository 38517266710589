/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, ModelConfig } from 'ngx-hal';
import { IngredientUnit } from '../enums/ingredient-unit.enum';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';

@ModelConfig({
	type: 'SpecificConversion',
	endpoint: 'SpecificConversion',
})
export class SpecificConversion extends HalDatastoreModel {
	@Attribute()
	public sourceQuantity: number;

	@Attribute()
	public sourceUnit: IngredientUnit;

	@Attribute()
	public destinationQuantity: number;

	@Attribute()
	public destinationUnit: IngredientUnit;
}
