/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SsoLandingPageButtonModule } from './sso-landing-page-button/sso-landing-page-button.module';
import { SsoLandingPageComponent } from './sso-landing-page.component';

@NgModule({
	declarations: [SsoLandingPageComponent],
	imports: [CommonModule, SsoLandingPageButtonModule, TranslateModule],
	exports: [SsoLandingPageComponent],
	providers: [TranslateService],
})
export class SsoLandingPageModule {}
