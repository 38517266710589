/**
 * Copyright (C) 2021 - 2021 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

// eslint-disable-next-line
export function BindCurrentContext() {
	return function(_target: any, propertyKey: string, descriptor: PropertyDescriptor) {
		return {
			configurable: true,
			get(this: any) {
				const bound = descriptor.value.bind(this);

				Object.defineProperty(this, propertyKey, {
					value: bound,
					configurable: true,
					writable: true,
				});

				return bound;
			},
		};
	};
}
