/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';

import { ModelMetadata } from 'app/classes/model-metadata/model-metadata';
import { NutrientUnit } from 'app/enums/nutrient-unit.enum';

@JsonApiModelConfig({
	type: 'nutrientDetails',
	meta: ModelMetadata,
})
export class NutrientDetail extends JsonApiModel {
	public config = null;

	@Attribute()
	public nutrient: string;

	@Attribute()
	public unit: NutrientUnit;

	@Attribute()
	public value: number;
}
