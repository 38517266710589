/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as AttributeNgxHal, HasMany, ModelConfig } from 'ngx-hal';
import { IRelatedFieldDefinition } from '../../components/recipe-steps/cooking-variable-forms-container/cooking-variable-forms-container.component';
import { AttributeValueType } from '../../enums/attribute-value-type.enum';
import { CookingVariableDeviceOption } from '../../enums/device-option.enum';
import { HalDatastoreModel } from '../../services/datastore/models/hal-datastore-model.model';
import { Attribute } from './attribute.model';

@ModelConfig({
	type: 'AttributeConfig',
	endpoint: 'AttributeConfig',
})
export class AttributeConfig extends HalDatastoreModel {
	public static modelType = 'AttributeConfig';

	@AttributeNgxHal()
	public name: string;

	@AttributeNgxHal()
	public slug: string;

	@HasMany({
		itemsType: Attribute,
	})
	public attributes: Array<Attribute>;

	public findAttribute(name: string): Attribute {
		return this.attributes?.find((attribute: Attribute) => attribute.name === name);
	}

	public hasOption(option: CookingVariableDeviceOption): boolean {
		return Boolean(this.findAttribute(option));
	}

	public hasRequiredOption(option: CookingVariableDeviceOption): boolean {
		return Boolean(this.findAttribute(option)?.isRequired);
	}

	public dependantsFor(
		attribute: Attribute,
		dependantType?: Extract<AttributeValueType, AttributeValueType.ENUM | AttributeValueType.LINK>,
	): Array<Attribute> {
		return this.attributes.filter((currentAttribute: Attribute) => {
			const isDependant =
				currentAttribute.prerequisite?.id === attribute.id &&
				(!dependantType || currentAttribute.valueType === dependantType);
			return isDependant;
		});
	}

	public childrenFor(
		attribute: Attribute,
		allAttributes: Array<Attribute> = this.attributes,
	): Array<Attribute> {
		return allAttributes.filter(
			(currentAttribute: Attribute) => currentAttribute.parent?.id === attribute.id,
		);
	}

	public getDependantsMap(): IRelatedFieldDefinition {
		const map: IRelatedFieldDefinition = {};

		this.attributes.forEach((attribute) => {
			if (!attribute.prerequisite) {
				return;
			}

			if (!attribute.isEnumType && !attribute.isLinkType) {
				return;
			}

			// If the attribute is not required, its values can't be binding in any way
			if (!attribute.isRequired) {
				return;
			}

			// If the attribute is not visible, its values are handled underneath the hood, hopefully
			if (!attribute.isVisible) {
				return;
			}

			// No need to track appliance type as it's used underneath the hood only
			if (attribute.prerequisite.name === CookingVariableDeviceOption.APPLIANCE_TYPE) {
				return;
			}

			map[attribute.prerequisite.name] = map[attribute.prerequisite.name] ?? {
				[attribute.prerequisite.name]: null,
			};
			map[attribute.prerequisite.name][attribute.name] = null;
		});

		return map;
	}

	public isEqualTo(attributeConfig: AttributeConfig): boolean {
		return Boolean(this.slug === attributeConfig?.slug);
	}
}
