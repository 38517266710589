/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
	private readonly STORAGE_NAMESPACE = 'ka-philips';

	public get(key: string): any {
		return this.getItems()[key];
	}

	public add(key: string, value: any): void {
		this.addToItems(key, value);
	}

	public remove(key: string): void {
		this.removeFromItems(key);
	}

	private getItems(): object {
		const items = localStorage.getItem(this.STORAGE_NAMESPACE);

		return items ? JSON.parse(items) : {};
	}

	private addToItems(key: string, value: any): void {
		const items: object = this.getItems();
		items[key] = value;

		this.saveItems(items);
	}

	private removeFromItems(key: string): void {
		const items: object = this.getItems();
		delete items[key];

		this.saveItems(items);
	}

	private saveItems(items: object): void {
		const stringifiedItems = JSON.stringify(items);

		localStorage.setItem(this.STORAGE_NAMESPACE, stringifiedItems);
	}
}
