/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { BelongsTo, Attribute as FormAttribute } from 'ngx-form-object';
import { Attribute, HasOne, ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Accessory } from './accessory.hal.model';
import { CookingVariable } from './cooking-variable.model';
import { Device } from './device.hal.model';
import { Product } from './product.model';

export interface ICompatibleProductData {
	isVerified?: boolean;
	accessory?: Accessory;
	device?: Device;
	product?: Product;
}

@ModelConfig({
	type: 'CompatibleProduct',
})
export class CompatibleProduct extends HalDatastoreModel implements ICompatibleProductData {
	@Attribute()
	@FormAttribute()
	public isVerified: boolean;

	@HasOne({
		propertyClass: Product,
		includeInPayload: true,
	})
	@BelongsTo()
	public product: Product;

	@HasOne({
		propertyClass: Device,
		includeInPayload: true,
	})
	@BelongsTo()
	public device: Device;

	@HasOne({
		propertyClass: Accessory,
		includeInPayload: true,
	})
	@BelongsTo()
	public accessory: Accessory;

	public cookingVariable: CookingVariable;
}
