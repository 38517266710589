/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HasOne, ModelConfig } from 'ngx-hal';
import { ReactionType } from '../enums/reaction-type.enum';
import { Administrator } from './administrator.model';
import { Comment } from './comment.hal.model';
import { Profile } from './profile.hal.model';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';

@ModelConfig({
	type: 'Reaction',
})
export class Reaction extends HalDatastoreModel {
	@Attribute({ externalName: 'type' })
	public reactionType: ReactionType;

	@HasOne({ propertyClass: 'Comment' })
	public comment: Comment;

	@HasOne({ propertyClass: Profile })
	public profile: Profile;

	@HasOne({ propertyClass: Administrator })
	public user: Administrator;
}
