/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, BelongsTo, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import { Attribute as FormAttribute, BelongsTo as FormBelongsTo } from 'ngx-form-object';

import { Language } from '../enums/language.enum';
import { TranslationStatus } from '../enums/translation-status.enum';
import { IModelTranslation } from '../interfaces/model-translation.interface';
import { Collection } from '../models/collection.model';

@JsonApiModelConfig({
	type: 'collectionTranslations',
})
export class CollectionTranslation extends JsonApiModel implements IModelTranslation {
	public config = null;

	@FormAttribute()
	public id: string;

	@Attribute()
	@FormAttribute()
	public language: Language;

	@Attribute()
	@FormAttribute()
	public title: string;

	@Attribute()
	@FormAttribute()
	public description: string;

	@Attribute()
	@FormAttribute()
	public status: TranslationStatus;

	@Attribute()
	public favouriteCount = 0;

	@Attribute()
	public viewCount = 0;

	@BelongsTo()
	@FormBelongsTo()
	public collection: Collection;
}
