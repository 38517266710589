/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorPageComponent } from './error-page.component';

@NgModule({
	imports: [CommonModule],
	declarations: [ErrorPageComponent],
})
export class ErrorPageModule {}
