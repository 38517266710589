/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SsoLandingPageButtonComponent } from './sso-landing-page-button.component';

@NgModule({
	declarations: [SsoLandingPageButtonComponent],
	imports: [CommonModule, TranslateModule],
	exports: [SsoLandingPageButtonComponent],
	providers: [TranslateService],
})
export class SsoLandingPageButtonModule {}
