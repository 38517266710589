/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { UntypedFormGroup } from '@angular/forms';

// The same as conditional-required
// TODO delete once is not used anymore
export function requiredForApproving(
	formGroup: UntypedFormGroup,
	isApprovingStep: boolean,
	message?: string,
): object | null {
	if (!isApprovingStep) {
		return null;
	}

	const value: any = formGroup.value;

	const isSet: boolean =
		value !== null && value !== undefined && value !== '' && (!value.trim || value.trim().length);

	return isSet
		? null
		: {
				requiredForApproving: {
					message,
					valid: false,
				},
		  };
}
