/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, BelongsTo, HasMany, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import { ModelMetadata } from 'app/classes/model-metadata/model-metadata';
import { Country } from 'app/custom-types/country/country.type';
import { AuthorType } from 'app/enums/author-type.enum';
import { ProfileStatus } from 'app/enums/consumer-status.enum';
import { Consumer } from 'app/models/consumer.model';
import { HistoryItem } from 'app/models/history-item.model';
import { Media } from 'app/models/media.model';

@JsonApiModelConfig({
	type: 'consumerProfiles',
	meta: ModelMetadata,
})
export class ConsumerProfile extends JsonApiModel {
	@Attribute({
		dataType: Country,
	})
	public country: Country;

	@Attribute()
	public description: string;

	@Attribute()
	public email: string;

	@Attribute()
	public mobileNumber: string;

	@Attribute()
	public status: ProfileStatus;

	@Attribute({
		dataType: Date,
	})
	public createdAt: Date;

	@Attribute({
		dataType: Date,
	})
	public modifiedAt: Date;

	@Attribute()
	public locale: string;

	@Attribute()
	public name: string;

	@Attribute()
	public type: AuthorType;

	@Attribute()
	public followersCount: number;

	@Attribute()
	public followingCount: number;

	@HasMany()
	public profileStatusHistory: Array<HistoryItem> = [];

	@BelongsTo()
	public consumer: Consumer;

	@BelongsTo()
	public profileImage: Media;

	public sortStatusHistory(order = -1): void {
		this.profileStatusHistory.sort(
			(state1: HistoryItem, state2: HistoryItem) =>
				order * (state1.changedAt.getTime() - state2.changedAt.getTime()),
		);
	}

	public getLastStatusBeforeQuarantine(): ProfileStatus | boolean {
		this.sortStatusHistory(-1);

		let hasFoundQuarantined = false;
		for (let i = 0; i < this.profileStatusHistory.length; i++) {
			const profileState = this.profileStatusHistory[i];

			if (!hasFoundQuarantined && profileState.newStatus === ProfileStatus.QUARANTINED) {
				hasFoundQuarantined = true;
			} else if (hasFoundQuarantined && profileState.newStatus !== ProfileStatus.QUARANTINED) {
				return profileState.newStatus;
			}
		}

		return false;
	}
}
