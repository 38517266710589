/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { AttributeOfPipe } from 'app/pipes/attribute-of/attribute-of.pipe';

@Pipe({ name: 'pluck' })
export class PluckPipe implements PipeTransform {
	public constructor(private readonly attributeOfPipe: AttributeOfPipe) {}

	public transform(items: Array<Record<string, string>> = [], attribute: string): Array<string> {
		return items.map((item: object) => this.attributeOfPipe.transform(item, attribute));
	}
}
