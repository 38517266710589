/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'updateTextWith' })
export class UpdateTextWithPipe implements PipeTransform {
	public transform(value: string, text: string, defaultText: string): string {
		if (value && value.length) {
			return text.replace(/#s/, value);
		} else {
			return defaultText;
		}
	}
}
