/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum SelectionType {
	SINGLE = 'SINGLE',
	MULTIPLE = 'MULTIPLE',
	NONE = 'NONE',
}
