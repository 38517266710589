/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

// Removes leading and trailing spaces and replaces multiple spaces with a single
export function stripSpaces(str: string): string {
	const trimmedString: string = str.replace(/^\s+|\s+$/g, '');
	return trimmedString.replace(/\s\s+/g, ' ');
}
