/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum CookingVariableDeviceOption {
	APPLIANCE_TYPE = 'applianceType',
	ATTRIBUTE_CONFIG = 'attributeConfig',
	SOURCE_PRODUCT = 'sourceProduct',
	SOURCE_PRODUCT_CATEGORY = 'sourceProductCategory',
	PRODUCT_RANGE = 'productRange',
	PRODUCT_GROUP = 'productGroup',
	ACCESSORIES = 'accessories',
	DURATION = 'duration',
	TEMPERATURE = 'temperature',
	SPEED = 'speed',
	AIR_SPEED = 'airSpeed',
	PAN = 'pan',
	NUMBER_OF_SHAKES = 'numberOfShakes',
	ELECTRIC_SYSTEM = 'electricSystem',
	HUMIDITY = 'humidity',
	COOKING_METHODS = 'cookingMethod',
	SUB_COOKING_METHODS = 'subCookingMethod',
	COOKING_PROFILES = 'cookingProfile',
	PRESELECTED_ACCESSORIES = 'preselectedAccessories',
	PRESSURE = 'pressure',
	PRESSURE_BUILD_DURATION = 'buildDuration',
	PRESSURE_RELEASE_DURATION = 'releaseDuration',
	POWER = 'power',
}

export type CookingVariableDeviceAttribute = Exclude<
	CookingVariableDeviceOption,
	CookingVariableDeviceOption.COOKING_PROFILES | CookingVariableDeviceOption.PRESELECTED_ACCESSORIES
>;

export enum RecipeDeviceOption {
	ACTIVE_PREPARATION_TIME = 'activePreparationTime',
	PASSIVE_PREPARATION_TIME = 'passivePreparationTime',
	ACTIVE_TIME = 'activeTime',
	TOTAL_TIME = 'totalTime',
	COOK_TIME = 'cookTime',
	PREP_TIME = 'prepTime',
	SERVINGS = 'servings',
}

export interface IDeviceConfigData {
	cookingVariableDeviceOptions: Record<CookingVariableDeviceAttribute, { formName: string }>;
	recipeDeviceOptions: Record<RecipeDeviceOption, { formName: string }>;
}

export const deviceOptionData: IDeviceConfigData = {
	cookingVariableDeviceOptions: {
		[CookingVariableDeviceOption.APPLIANCE_TYPE]: {
			formName: 'applianceType',
		},
		[CookingVariableDeviceOption.ATTRIBUTE_CONFIG]: {
			formName: 'attributeConfig',
		},
		[CookingVariableDeviceOption.SOURCE_PRODUCT]: {
			formName: 'sourceProduct',
		},
		[CookingVariableDeviceOption.SOURCE_PRODUCT_CATEGORY]: {
			formName: 'sourceProductCategory',
		},
		[CookingVariableDeviceOption.PRODUCT_RANGE]: {
			formName: 'compatibleDevices',
		},
		[CookingVariableDeviceOption.PRODUCT_GROUP]: {
			formName: 'productGroups',
		},
		[CookingVariableDeviceOption.ACCESSORIES]: {
			formName: 'compatibleAccessories',
		},
		[CookingVariableDeviceOption.DURATION]: {
			formName: 'duration',
		},
		[CookingVariableDeviceOption.TEMPERATURE]: {
			formName: 'temperature',
		},
		[CookingVariableDeviceOption.SPEED]: {
			formName: 'speed',
		},
		[CookingVariableDeviceOption.AIR_SPEED]: {
			formName: 'airSpeed',
		},
		[CookingVariableDeviceOption.PAN]: {
			formName: 'pan',
		},
		[CookingVariableDeviceOption.NUMBER_OF_SHAKES]: {
			formName: 'numberOfShakes',
		},
		[CookingVariableDeviceOption.ELECTRIC_SYSTEM]: {
			formName: 'electricSystem',
		},
		[CookingVariableDeviceOption.HUMIDITY]: {
			formName: 'humidityLevel',
		},
		[CookingVariableDeviceOption.COOKING_METHODS]: {
			formName: 'cookingMethod',
		},
		[CookingVariableDeviceOption.SUB_COOKING_METHODS]: {
			formName: 'childCookingMethod',
		},
		[CookingVariableDeviceOption.PRESSURE]: {
			formName: 'pressure',
		},
		[CookingVariableDeviceOption.PRESSURE_BUILD_DURATION]: {
			formName: 'buildDuration',
		},
		[CookingVariableDeviceOption.PRESSURE_RELEASE_DURATION]: {
			formName: 'releaseDuration',
		},
		[CookingVariableDeviceOption.POWER]: {
			formName: 'power',
		},
	},
	recipeDeviceOptions: {
		[RecipeDeviceOption.ACTIVE_PREPARATION_TIME]: {
			formName: 'activePreparationTime',
		},
		[RecipeDeviceOption.PASSIVE_PREPARATION_TIME]: {
			formName: 'passivePreparationTime',
		},
		[RecipeDeviceOption.ACTIVE_TIME]: {
			formName: 'activeTime',
		},
		[RecipeDeviceOption.TOTAL_TIME]: {
			formName: 'totalTime',
		},
		[RecipeDeviceOption.COOK_TIME]: {
			formName: 'cookTime',
		},
		[RecipeDeviceOption.PREP_TIME]: {
			formName: 'prepTime',
		},
		[RecipeDeviceOption.SERVINGS]: {
			formName: 'servings',
		},
	},
};
