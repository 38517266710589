/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute, BelongsTo } from 'ngx-form-object';
import { Attribute, HasOne, ModelConfig } from 'ngx-hal';

import { Translation } from './translation.model';
import { Announcement } from './announcement.hal.model';

@ModelConfig({
	type: 'AnnouncementTranslation',
})
export class AnnouncementTranslation extends Translation {
	@Attribute()
	@FormAttribute()
	public description: string;

	@Attribute()
	@FormAttribute()
	public title: string;

	@HasOne({
		propertyClass: 'Announcement',
	})
	@BelongsTo()
	public announcement: Announcement;
}
