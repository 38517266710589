/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { isArray } from '../is-array/is-array';

export function removeBlankAttributes(source: object): object {
	const target = Object.assign({}, source);

	Object.keys(target).forEach((attrName) => {
		const attrValue = target[attrName];
		if (!attrValue) {
			delete target[attrName];
		}
	});

	return target;
}

export function isObject(obj: any): boolean {
	return Object.prototype.toString.call(obj) === '[object Object]';
}

export function deepCopy(source: any): any {
	if (isArray(source)) {
		return source.map(deepCopy);
	}

	if (isObject(source)) {
		return copyObject(source);
	}

	return source;
}

export function copyObject(source: object): object {
	const copy = {};

	Object.keys(source).forEach((key) => {
		copy[key] = deepCopy(source[key]);
	});

	return copy;
}
