/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiModelConfig, JsonApiModel, BelongsTo } from 'angular2-jsonapi';

import { ConsumerProfile } from 'app/models/consumer-profile.model';
import { ModelMetadata } from 'app/classes/model-metadata/model-metadata';

@JsonApiModelConfig({
	type: 'consumers',
	meta: ModelMetadata,
})
export class Consumer extends JsonApiModel {
	@BelongsTo()
	public consumerProfile: ConsumerProfile;
}
