/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, BelongsTo, HasMany, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import { ModelMetadata } from '../classes/model-metadata/model-metadata';
import { AuthorType } from '../enums/author-type.enum';
import { ContentStatus } from '../enums/content-status.enum';
import { ModelTypePrefix } from '../enums/model-type-prefix.enum';
import { TipSubType } from '../enums/tip-sub-type.enum';
import { IContentModel } from '../interfaces/content-model.interface';
import { Consumer } from '../models/consumer.model';
import { CountryProfile } from '../models/country-profile.model';
import { Device } from '../models/device.model';
import { Domain } from '../models/domain.model';
import { Media } from '../models/media.model';
import { TipTranslation } from '../models/tip-translation.model';
import { User } from '../models/user.model';
import { Datastore } from '../services/datastore/datastore.service';

@JsonApiModelConfig({
	type: 'tips',
	meta: ModelMetadata,
})
export class Tip extends JsonApiModel implements IContentModel {
	public config = null;

	public modelTypePrefix: ModelTypePrefix = ModelTypePrefix.TIP;

	@Attribute({
		dataType: Date,
	})
	public createdAt: Date;

	@Attribute({
		dataType: Date,
	})
	public modifiedAt: Date;

	@Attribute()
	public shortId: string;

	@Attribute()
	public status: ContentStatus;

	@Attribute()
	public subType: TipSubType;

	@Attribute()
	public type: AuthorType;

	@Attribute()
	public modelId: string;

	@Attribute()
	public viewCount: string;

	@Attribute()
	public favouriteCount: string;

	@Attribute()
	public commentCount: string;

	@Attribute()
	public shareCount: string;

	@HasMany()
	public devices: Array<Device>;

	@HasMany()
	public tipTranslations: Array<TipTranslation>;

	// Use assignedProfiles instead
	@HasMany()
	public profiles: Array<CountryProfile>;

	@BelongsTo()
	public defaultTranslation: TipTranslation;

	@BelongsTo()
	public coverImage: Media;

	@BelongsTo()
	public domain: Domain;

	@BelongsTo()
	public authorUser: User;

	@BelongsTo()
	public authorConsumer: Consumer;

	// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
	private _assignedProfiles: Array<CountryProfile>;

	public constructor(private readonly datastore: Datastore, data?: any) {
		super(datastore, data);

		this._assignedProfiles = this.getParsedProfiles(data);
	}

	public get assignedProfiles(): Array<CountryProfile> {
		return this._assignedProfiles;
	}

	public set assignedProfiles(assignedProfiles: Array<CountryProfile>) {
		this._assignedProfiles = assignedProfiles;
	}

	public translationsPropertyName = 'tipTranslations';

	public get translations(): Array<TipTranslation> {
		return this.tipTranslations;
	}

	private getParsedProfiles(rawResponse: any): Array<CountryProfile> {
		if (!rawResponse || !rawResponse.relationships) {
			return null;
		}

		const profiles: any = rawResponse.relationships.profiles;

		if (!profiles || !profiles.data) {
			return null;
		}

		const profileIds: Array<string> = profiles.data.map((profile: CountryProfile) => profile.id);
		const countryProfiles: Array<CountryProfile> = profileIds
			.map((id: string) => this.datastore.peekRecord(CountryProfile, id))
			.filter((profile) => profile);

		return countryProfiles.length === profileIds.length ? countryProfiles : null;
	}
}
