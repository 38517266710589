/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum ScheduledEventStatus {
	DRAFT = 'DRAFT',
	FAILED = 'FAILED',
	IN_PROGRESS = 'IN_PROGRESS',
	PARTIALLY_SUCCESSFUL = 'PARTIALLY_SUCCESSFUL',
	SCHEDULED = 'SCHEDULED',
	SUCCESSFUL = 'SUCCESSFUL',
}

export const scheduledEventStatusData: Record<ScheduledEventStatus, { translateKey: string }> = {
	[ScheduledEventStatus.DRAFT]: {
		translateKey: 'contentScheduler.eventStatus.draft',
	},
	[ScheduledEventStatus.FAILED]: {
		translateKey: 'contentScheduler.eventStatus.failed',
	},
	[ScheduledEventStatus.IN_PROGRESS]: {
		translateKey: 'contentScheduler.eventStatus.inProgress',
	},
	[ScheduledEventStatus.PARTIALLY_SUCCESSFUL]: {
		translateKey: 'contentScheduler.eventStatus.partiallySuccessful',
	},
	[ScheduledEventStatus.SCHEDULED]: {
		translateKey: 'contentScheduler.eventStatus.scheduled',
	},
	[ScheduledEventStatus.SUCCESSFUL]: {
		translateKey: 'contentScheduler.eventStatus.successful',
	},
};
