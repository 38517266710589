/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Language } from '../../enums/language.enum';
import { LocaleService } from '../../services/locale/locale.service';

@Pipe({
	name: 'translateToLang',
})
export class TranslateToLangPipe implements PipeTransform {
	constructor(private readonly localeService: LocaleService) {}

	public transform(
		key: string,
		language: Language,
		defaultLanguage?: Language,
	): Observable<string> {
		return this.localeService.getTranslationForLang(key, language, defaultLanguage);
	}
}
