/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JSON_API_DATASTORE_CONFIG } from '../constants/json-api.constants';
import { setObjProperty } from '../helpers/class-metadata.helper';

export function JsonApiDatastoreConfig(config: any = {}) {
	return function (target: any) {
		setObjProperty(target, JSON_API_DATASTORE_CONFIG, config);
	};
}
