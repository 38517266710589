/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { ComparisonFunction } from '../../../custom-types/comparison-function.type';

export function contains<T>(
	arr: Array<T>,
	item: T,
	comparisonFunction?: ComparisonFunction<T>,
): boolean {
	if (comparisonFunction) {
		return arr.some((arrItem: T) => comparisonFunction(arrItem, item));
	} else {
		return arr.indexOf(item) !== -1;
	}
}
