/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Observable } from 'rxjs';

import { PopupDialogService } from 'app/services/popup-dialog/popup-dialog.service';
import { IInformationDialogOptions } from 'app/utils/helpers/modals/information-dialog/information-dialog-options.interface';

export function openInformationDialog(
	dialogService: PopupDialogService,
	options: IInformationDialogOptions,
	isHtmlContent?: boolean,
): Observable<boolean> {
	const dialog = dialogService
		.setTitle(options.title)
		.setContent(options.description, isHtmlContent)
		.setCancelButton({
			label: options.stopActionLabel,
		})
		.open();

	const dialog$ = dialog.afterClosed();

	return dialog$;
}
