/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'split',
})
export class SplitPipe implements PipeTransform {
	public transform(item: string, separator: string | RegExp = /\s+/): any {
		return item.split(separator);
	}
}
