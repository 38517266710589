/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export interface JsonApiError {
	id?: string;
	links?: Array<any>;
	status?: string;
	code?: string;
	title?: string;
	detail?: string;
	source?: {
		pointer?: string;
		parameter?: string;
	};
	meta?: any;
}

export class ErrorResponse {
	errors?: Array<JsonApiError> = [];

	constructor(errors?: Array<JsonApiError>) {
		if (errors) {
			this.errors = errors;
		}
	}
}
