/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Countries } from 'countries-data';
import { countriesData } from '../../enums/countries-data.enum';

export type CountryCode = string;

export class CountryData {
	public commonName: string;

	constructor(
		private readonly countryCode: CountryCode,
		countryData: any,
	) {
		if (countryData) {
			this.commonName = countryData.name.common;
		} else {
			this.commonName = 'Unknown country name';
		}
	}

	public get code(): string {
		return this.countryCode;
	}

	public toString(): string {
		return this.commonName;
	}
}

export class Country {
	public countriesData: Countries = countriesData; // TODO find a way to inject countries data

	public mask(countryCode: string) {
		const countryData = this.countriesData[countryCode];
		const country = new CountryData(countryCode, countryData);
		return country;
	}

	public unmask(country: CountryData) {
		// TODO check why does JsonApi library execute unmask before mask
		if (!country) {
			return;
		}

		return country.code;
	}
}
