/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Component, Input } from '@angular/core';

@Component({
	selector: 'character-limit',
	templateUrl: './character-limit.component.html',
	styleUrls: ['./character-limit.component.scss'],
})
export class CharacterLimitComponent {
	@Input() current: number;
	@Input() max: number;
}
