/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import * as _ from 'lodash';
import { ATTRIBUTE_CONFIG, ATTRIBUTE_MAPPING_CONFIG } from '../constants/json-api.constants';
import { AttributeMetadata } from '../constants/symbols';
import { DateConverter } from '../converters/date/date.converter';
import { getObjProperty, setObjProperty } from '../helpers/class-metadata.helper';
import { AttributeDecoratorOptions } from '../interfaces/attribute-decorator-options.interface';

export function Attribute(options: AttributeDecoratorOptions = {}): PropertyDecorator {
	return function (target: any, propertyName: string) {
		const converter = function (dataType: any, value: any, forSerialisation = false): any {
			let attrConverter;

			if (dataType === Date) {
				attrConverter = new DateConverter();
			} else if (dataType) {
				const datatype = new dataType();

				if (datatype.mask && datatype.unmask) {
					attrConverter = datatype;
				}
			}

			if (attrConverter) {
				if (!forSerialisation) {
					return attrConverter.mask(value);
				}
				return attrConverter.unmask(value);
			}

			return value;
		};

		const saveAnnotations = function () {
			const metadata = getObjProperty(target, ATTRIBUTE_CONFIG) || {};

			metadata[propertyName] = {
				marked: true,
			};

			setObjProperty(target, ATTRIBUTE_CONFIG, metadata);

			const mappingMetadata = getObjProperty(target, ATTRIBUTE_MAPPING_CONFIG) || {};
			const serializedPropertyName =
				options.serializedName !== undefined ? options.serializedName : propertyName;
			mappingMetadata[serializedPropertyName] = propertyName;
			setObjProperty(target, ATTRIBUTE_MAPPING_CONFIG, mappingMetadata);
		};

		const setMetadata = function (instance: any, oldValue: any, newValue: any) {
			const targetType = options.dataType;

			if (!instance[AttributeMetadata]) {
				instance[AttributeMetadata] = {};
			}
			instance[AttributeMetadata][propertyName] = {
				newValue,
				oldValue,
				nested: false,
				serializedName: options.serializedName,
				hasDirtyAttributes: !_.isEqual(oldValue, newValue),
				serialisationValue: converter(targetType, newValue, true),
			};
		};

		const getter = function () {
			return this[`_${propertyName}`];
		};

		const setter = function (newVal: any) {
			const targetType = options.dataType;
			const convertedValue = converter(targetType, newVal);
			let oldValue = null;
			if (this.isModelInitialization() && this.id) {
				oldValue = converter(targetType, newVal);
			} else {
				if (this[AttributeMetadata] && this[AttributeMetadata][propertyName]) {
					oldValue = this[AttributeMetadata][propertyName]['oldValue'];
				}
			}

			this[`_${propertyName}`] = convertedValue;
			setMetadata(this, oldValue, convertedValue);
		};

		if (delete target[propertyName]) {
			saveAnnotations();
			Object.defineProperty(target, propertyName, {
				get: getter,
				set: setter,
				enumerable: true,
				configurable: true,
			});
		}
	};
}
