/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';

@Injectable()
export class IsSecureRouteGuard {
	public canActivate(): boolean {
		return this.isSafeToNavigateForward();
	}

	public canActivateChild(): boolean {
		return this.isSafeToNavigateForward();
	}

	private isSafeToNavigateForward(): boolean {
		if (!this.isDev && !this.isRouteSecure) {
			this.redirectToSecureRoute();
		}

		return this.isDev || this.isRouteSecure;
	}

	private get isRouteSecure(): boolean {
		return window.location.protocol === 'https:';
	}

	private redirectToSecureRoute(): void {
		window.location.href = `https:${window.location.href.substring(
			window.location.protocol.length,
		)}`;
	}

	private get isDev() {
		return location.hostname === 'localhost';
	}
}
