/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, interval, Observable, of, Subject, timer } from 'rxjs';
import { filter, map, skipUntil, switchMap, throttleTime } from 'rxjs/operators';
import { IBuildVersion } from '../../interfaces/build-version.interface';
import { IConfirmationDialogOptions, openConfirmationDialog } from '../../utils/helpers/helpers';
import { PopupDialogService } from '../popup-dialog/popup-dialog.service';
import { WINDOW_TOKEN } from '../window/window.service';

@Injectable({
	providedIn: 'root',
})
export class VersionCheckService {
	private readonly DEBOUNCING_INTERVAL = 15 * 60 * 1000; // 15 minutes
	private readonly POLLING_INTERVAL = 24 * 60 * 60 * 1000; // 24 hours
	private readonly SHOWING_DIALOG_INTERVAL = 5 * 60 * 1000; // 5 minutes

	private readonly dialog = inject(PopupDialogService);
	private readonly http = inject(HttpClient);
	private readonly router = inject(Router);
	private readonly translateService = inject(TranslateService);
	private readonly window = inject(WINDOW_TOKEN);

	private static isInstantiated = false;

	private isDialogOpened = false;
	private isReloadInQueue = false;
	// this placeholder will be replaced with the actual version during the post-build process
	private readonly currentVersion = '{{BUILD_VERSION_PLACEHOLDER}}';
	private dialogOptions: IConfirmationDialogOptions;

	private readonly checkNewVersionTrigger$ = new Subject<void>();

	public init(): void {
		if (VersionCheckService.isInstantiated) {
			throw new Error('VersionCheckService may be instantiated only once.');
		}

		VersionCheckService.isInstantiated = true;

		this.subscribeToIntervalChecking();
		this.subscribeToWindowFocusChecking();
		this.subscribeToRouteChangeChecking();

		this.dialogOptions = this.getDialogLabels();

		this.checkNewVersionTrigger$
			.pipe(
				// ignore events for first DEBOUNCING_INTERVAL time, as user just opened the page
				skipUntil(timer(this.DEBOUNCING_INTERVAL)),
				// throttle events so that max 1 event per DEBOUNCING_INTERVAL is triggered
				throttleTime(this.DEBOUNCING_INTERVAL),
				switchMap(() => this.checkIfNewVersionIsAvailable()),
				// filter out events where version has not changed
				filter(Boolean),
				switchMap(() => this.openDialogRecursively()),
			)
			.subscribe();
	}

	private subscribeToIntervalChecking(): void {
		interval(this.POLLING_INTERVAL).subscribe(() => {
			this.checkNewVersionTrigger$.next();
		});
	}

	private subscribeToWindowFocusChecking(): void {
		fromEvent(this.window, 'focus').subscribe(() => {
			this.checkNewVersionTrigger$.next();
		});
	}

	private subscribeToRouteChangeChecking(): void {
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
			this.checkNewVersionTrigger$.next();
		});
	}

	private checkIfNewVersionIsAvailable(): Observable<boolean> {
		if (this.isDialogOpened || this.isReloadInQueue) {
			return of(false);
		}

		return this.getVersion().pipe(map(({ version }) => this.currentVersion !== version));
	}

	private openDialogRecursively(): Observable<boolean> {
		this.isDialogOpened = true;
		this.isReloadInQueue = true;

		return openConfirmationDialog(this.dialog, this.dialogOptions).pipe(
			switchMap((shouldReload) => {
				this.isDialogOpened = false;

				if (shouldReload) {
					this.reloadThePage();
				}

				return timer(this.SHOWING_DIALOG_INTERVAL).pipe(
					switchMap(() => this.openDialogRecursively()),
				);
			}),
		);
	}

	private reloadThePage(): void {
		this.window.location.reload();
	}

	private getVersion(): Observable<IBuildVersion> {
		return this.http.get<IBuildVersion>(`assets/version.json?t=${Date.now()}`);
	}

	private getDialogLabels(): IConfirmationDialogOptions {
		return {
			title: this.translateService.instant('reloadPageDialog.title'),
			description: this.translateService.instant('reloadPageDialog.description'),
			stopActionLabel: this.translateService.instant('reloadPageDialog.button.stop'),
			proceedActionLabel: this.translateService.instant('reloadPageDialog.button.proceed'),
		};
	}
}
