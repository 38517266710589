/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum PreparedMealStatus {
	DELETED = 'DELETED',
	LIVE = 'LIVE',
	LIVE_FLAGGED = 'LIVE_FLAGGED',
}

export const preparedMealStatusData = {
	[PreparedMealStatus.LIVE]: {
		label: 'Live',
		translateKey: 'status.live',
	},
	[PreparedMealStatus.DELETED]: {
		label: 'Deleted',
		translateKey: 'status.deleted',
	},
	[PreparedMealStatus.LIVE_FLAGGED]: {
		label: 'Live flagged',
		translateKey: 'status.liveFlagged',
	},
};
