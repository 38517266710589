/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'attributeOf' })
export class AttributeOfPipe implements PipeTransform {
	public transform(value: any, attribute: string, defaultValue = ''): any {
		if (value) {
			return value[attribute];
		}

		return defaultValue;
	}
}
