/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HalModel, ModelService, RelationshipRequestDescriptor } from 'ngx-hal';
import { RequestOptions } from 'ngx-hal/lib/types/request-options.type';
import { Observable } from 'rxjs';
import { HalPagination } from '../../classes/pagination';

export abstract class HalModelService<T extends HalModel<HalPagination>> extends ModelService<
	T,
	HalPagination
> {
	public findOne(
		modelId: string,
		includeRelationships: Array<string | RelationshipRequestDescriptor> = [],
		requestOptions: RequestOptions = {},
		subsequentRequestsOptions: RequestOptions = {},
		customUrl?: string,
	): Observable<T> {
		const enrichedRequestOptions: RequestOptions = Object.assign({}, requestOptions);
		enrichedRequestOptions.routeParams = enrichedRequestOptions.routeParams || {};
		enrichedRequestOptions.routeParams.id = enrichedRequestOptions.routeParams?.id || modelId;

		return super.findOne(
			modelId,
			includeRelationships,
			enrichedRequestOptions,
			subsequentRequestsOptions,
			customUrl,
		);
	}
}
