/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiModel, JsonApiModelConfig, Attribute, BelongsTo } from 'angular2-jsonapi';

import { Recipe } from './recipe.model';
import { ConsumerProfile } from './consumer-profile.model';
import { Media } from './media.model';
import { PreparedMealStatus } from '../enums/prepared-meal-status.enum';
import { ModelMetadata } from '../classes/model-metadata/model-metadata';
import { CountryProfile } from './country-profile.model';

@JsonApiModelConfig({
	type: 'preparedMeals',
	meta: ModelMetadata,
})
export class PreparedMeal extends JsonApiModel {
	@Attribute()
	public status: PreparedMealStatus;

	@Attribute({
		dataType: Date,
	})
	public createdAt: Date;

	@BelongsTo()
	public createdFromRecipe: Recipe;

	@BelongsTo()
	public author: ConsumerProfile | CountryProfile;

	@BelongsTo()
	public image: Media;

	public get isFlagged(): boolean {
		return this.status === PreparedMealStatus.LIVE_FLAGGED;
	}
}
