/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { tenantData } from '../../enums/tenant.enum';
import { TenantService } from '../../services/tenant/tenant.service';

@Pipe({
	name: 'prefixWithTenant',
})
export class PrefixWithTenantPipe implements PipeTransform {
	public constructor(private readonly tenantService: TenantService) {}

	public transform(translationKey: string): string {
		return `${tenantData[this.tenantService.tenantName]?.translationKeysPrefix}.${translationKey}`;
	}
}
