/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { InputFieldComponent } from '../../../components/form/input-field/input-field.component';
import { CharacterLimitModule } from '../../../components/form/shared/character-limit/character-limit.module';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
	imports: [CommonModule, MatIconModule, CharacterLimitModule, PipesModule],
	declarations: [InputFieldComponent],
	exports: [InputFieldComponent],
})
export class InputFieldModule {}
