/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export function order<T>(items: Array<T>, orderProperty: string | Function): Array<T> {
	if (!items || !items.length) {
		return [];
	}

	return items.sort((item1: T, item2: T) => {
		let order1: number;
		let order2: number;

		if (typeof orderProperty === 'function') {
			order1 = orderProperty(item1);
			order2 = orderProperty(item2);
		} else {
			const searchAttributes = orderProperty.split('.');
			order1 = searchAttributes.reduce(
				(current: object, attribute: string) => current[attribute],
				item1,
			);
			order2 = searchAttributes.reduce(
				(current: object, attribute: string) => current[attribute],
				item2,
			);
		}

		order1 = order1 ?? Number.MAX_SAFE_INTEGER;
		order2 = order2 ?? Number.MAX_SAFE_INTEGER;

		return order1 - order2;
	});
}
