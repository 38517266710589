/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { IFormElement } from '../../components/form-builder/shared/form-element.interface';

@Pipe({ name: 'groupFormElements' })
export class GroupFormElements implements PipeTransform {
	public transform(items: Array<IFormElement> = [], groupSize = 1): Array<Array<IFormElement>> {
		if (groupSize < 1) {
			throw new RangeError('Group size must be greater than 1');
		}

		const groupedItems: Array<Array<IFormElement>> = [];

		// Group items by two, but if there are some hidden items, include them without counting them
		let index = 0;
		while (index < items.length) {
			let hiddenItemsInGroup = 0;
			let validItemsInGroup = 0;

			for (let i = index; i < items.length; i++) {
				if (items[i].isHidden) {
					hiddenItemsInGroup++;
				} else {
					validItemsInGroup++;
				}

				if (validItemsInGroup === groupSize) {
					break;
				}
			}

			const group: Array<IFormElement> = items.slice(index, index + groupSize + hiddenItemsInGroup);

			groupedItems.push(group);

			index += groupSize + hiddenItemsInGroup;
		}

		return groupedItems;
	}
}
