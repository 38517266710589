/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum UserStatus {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	/**
	 * @deprecated unused on Administrator resource, kept here for backward compatibility, use INACTIVE instead
	 */
	DELETED = 'DELETED',
}

export const userStatusData = {
	[UserStatus.ACTIVE]: {
		id: UserStatus.ACTIVE,
		translationKey: 'user.status.active',
	},
	[UserStatus.INACTIVE]: {
		id: UserStatus.INACTIVE,
		translationKey: 'user.status.inactive',
	},
	[UserStatus.DELETED]: {
		// DEPRECATED
		id: UserStatus.DELETED,
		translationKey: 'user.status.inactive',
	},
};
