/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, BelongsTo, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import { Attribute as FormAttribute, BelongsTo as FormBelongsTo } from 'ngx-form-object';

import { ModelMetadata } from '../classes/model-metadata/model-metadata';
import { IngredientUnit } from '../enums/ingredient-unit.enum';
import { Ingredient } from '../models/ingredient.model';

@JsonApiModelConfig({
	type: 'specificConversions',
	meta: ModelMetadata,
})
export class SpecificConversion extends JsonApiModel {
	public config = null;

	@Attribute()
	@FormAttribute()
	public id: string;

	@Attribute()
	@FormAttribute()
	public sourceQuantity: number;

	@Attribute()
	@FormAttribute()
	public sourceUnit: IngredientUnit;

	@Attribute()
	@FormAttribute()
	public destinationQuantity: number;

	@Attribute()
	@FormAttribute()
	public destinationUnit: IngredientUnit;

	@BelongsTo()
	@FormBelongsTo()
	public ingredient: Ingredient;
}
