/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RootApi } from '../../models/root-api.model';
import { Tenant } from '../../models/tenant.model';
import { HalDatastoreService } from '../datastore/hal-datastore.service';
import { HalModelService } from '../hal-model/hal-model.service';

@Injectable({
	providedIn: 'root',
})
export class RootApiService extends HalModelService<RootApi> {
	private _rootApi: RootApi;

	constructor(public datastore: HalDatastoreService) {
		super(datastore, RootApi);
	}

	public get rootApi(): RootApi {
		return this._rootApi;
	}

	public fetchRootApi(currentTenant: Tenant): Observable<RootApi> {
		return currentTenant.fetchRelationships('rootApi').pipe(
			map((tenant) => tenant.rootApi),
			tap((rootApiData) => {
				this._rootApi = rootApiData;
				this.datastore.rootApi = this.rootApi;
			}),
		);
	}
}
