/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Locale } from 'app/enums/locale.enum';
import { Language } from 'app/enums/language.enum';

export const localeData = {
	[Locale.ENGLISH]: {
		id: Locale.ENGLISH,
		language: Language.ENGLISH,
	},
	[Locale.GERMAN]: {
		id: Locale.GERMAN,
		language: Language.GERMAN,
	},
	[Locale.DUTCH]: {
		id: Locale.DUTCH,
		language: Language.DUTCH,
	},
};
