/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum DeviceCategoryGroup {
	ALL_APPLIANCES_GROUP = 'ALL_APPLIANCES_GROUP',
	KITCHEN_APPLIANCES_GROUP = 'KITCHEN_APPLIANCES_GROUP',
	COFFEE_APPLIANCES_GROUP = 'COFFEE_APPLIANCES_GROUP',
	GARMENT_CARE_APPLIANCES_GROUP = 'GARMENT_CARE_APPLIANCES_GROUP',
	FLOOR_CARE_APPLIANCES_GROUP = 'FLOOR_CARE_APPLIANCES_GROUP',
}

// Hardcode only device categories that are used for handling edge cases
export enum DeviceCategoryEnum {
	AICON = 'ALL_IN_ONE_COOKER',
	AIRFRYER = 'AIRFRYER',
	BLENDER = 'BLENDER',
	JUICER = 'JUICER',
	KITCHEN_MACHINE = 'KITCHEN_MACHINE',
	NO_DEVICE = 'NONE',
	NUTRIMAX = 'AIR_COOKER',
	PASTA_MAKER = 'PASTA_MAKER',
	RICE_COOKER = 'RICE_COOKER',
}
