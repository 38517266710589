/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replaceNan' })
export class ReplaceNanPipe implements PipeTransform {
	public transform(value: number, replaceWithValue = '-'): string | number {
		return isNaN(value) || value === null ? replaceWithValue : value;
	}
}
