/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiModelConfig, JsonApiModel, Attribute, BelongsTo } from 'angular2-jsonapi';

import { ModelMetadata } from 'app/classes/model-metadata/model-metadata';
import { HealthClaimGuidelineSource } from 'app/enums/health-claim-guideline-source.enum';
import { NutritionClaimGuideline } from 'app/models/nutrition-claim-guideline.model';
import { HealthClaimContent } from 'app/models/health-claim-content.model';

@JsonApiModelConfig({
	type: 'healthClaimGuidelines',
	meta: ModelMetadata,
})
export class HealthClaimGuideline extends JsonApiModel {
	public config = null;

	@Attribute()
	public source: HealthClaimGuidelineSource;

	@BelongsTo()
	public healthClaimContent: HealthClaimContent;

	@BelongsTo()
	public nutritionClaimGuideline: NutritionClaimGuideline;
}
