/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { SimpleHalModel } from 'ngx-hal';

export class CountryFeatures extends SimpleHalModel {
	constructor(private readonly data: { basic: Array<string> }) {
		super();
	}

	public get basic(): Array<string> {
		return this.data.basic;
	}
}
