/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { IAlertData, IDialogOptions } from 'app/components/popup-dialog/dialog-options.interface';
import { IButtonOptions } from 'app/components/popup-dialog/button-options.interface';
import { ConfirmationDialogComponent } from 'app/components/popup-dialog/confirmation-dialog/confirmation-dialog.component';
import { deepCopy } from 'app/utils/helpers/helpers';
import { AlertType } from '../../components/alert/alert-type.enum';

const defaultOptions: IDialogOptions = {
	autoFocus: false,
	disableClose: true,
	data: {
		title: '',
		content: '',
	},
};

@Injectable()
export class PopupDialogService {
	protected options: IDialogOptions = deepCopy(defaultOptions);

	constructor(private readonly dialog: MatDialog) {}

	public get dialogIsClosing(): boolean {
		return Boolean(this.dialog.openDialogs.length);
	}

	public closeAll(): void {
		this.dialog.closeAll();
	}

	public get afterAllClosed(): Observable<void> {
		return this.dialog.afterAllClosed;
	}

	public isCancelable(): PopupDialogService {
		this.options.disableClose = false;
		return this;
	}

	public open(componentClass?, options: MatDialogConfig = {}): MatDialogRef<any> {
		if (!options.width) {
			options.width = '480px';
		}

		const component = componentClass || ConfirmationDialogComponent;
		const dialogRef: MatDialogRef<any> = this.dialog.open(
			component,
			Object.assign({}, this.options, options),
		);

		this.reset();

		return dialogRef;
	}

	public setTitle(title: string): PopupDialogService {
		this.options.data.title = title;
		return this;
	}

	public setContent(content: string, isHtmlContent?: boolean): PopupDialogService {
		this.options.data.content = content;
		this.options.data.isHtmlContent = isHtmlContent;

		return this;
	}

	public setAlert(alert: IAlertData): PopupDialogService {
		this.options.data.alert = alert;

		return this;
	}

	public setCancelButton(button: IButtonOptions): PopupDialogService {
		this.options.data.cancelButton = button;
		return this;
	}

	public setSaveButton(button: IButtonOptions): PopupDialogService {
		this.options.data.saveButton = button;
		return this;
	}

	public setExtraOptions(extraOptions: object): PopupDialogService {
		this.options.data.extra = extraOptions;
		return this;
	}

	public setOptions(options: Array<IButtonOptions>): PopupDialogService {
		this.options.data.options = options;
		return this;
	}

	public setSubtitle(subtitle: string): PopupDialogService {
		this.options.data.subtitle = subtitle;
		return this;
	}

	public reset(): PopupDialogService {
		this.options = deepCopy(defaultOptions);
		return this;
	}
}
