/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { NgModule } from '@angular/core';
import { AttributeOfPipe } from 'app/pipes/attribute-of/attribute-of.pipe';
import { EscapeHtmlPipe } from 'app/pipes/escape-html/escape-html.pipe';
import { FilterBy } from 'app/pipes/filter-by/filter-by.pipe';
import { FindBy } from 'app/pipes/find-by/find-by.pipe';
import { FirstPipe } from 'app/pipes/first/first.pipe';
import { GetFormByFormFieldPipe } from 'app/pipes/get-form-by-form-field/get-form-by-form-field.pipe';
import { GetTranslationForLanguage } from 'app/pipes/get-translation-for-language/get-translation-for-language.pipe';
import { GroupAsArrayByPipe } from 'app/pipes/group-as-array-by/group-as-array-by.pipe';
import { GroupItemsPipe } from 'app/pipes/group-items/group-items.pipe';
import { HighlightMatchPipe } from 'app/pipes/highlight-match/highlight-match.pipe';
import { ImageSizePipe } from 'app/pipes/image-size/image-size.pipe';
import { IsEmptyPipe } from 'app/pipes/is-empty/is-empty.pipe';
import { IsNotEmptyPipe } from 'app/pipes/is-not-empty/is-not-empty.pipe';
import { JoinPipe } from 'app/pipes/join/join.pipe';
import { LastElementPipe } from 'app/pipes/last-element/last-element.pipe';
import { PluckPipe } from 'app/pipes/pluck/pluck.pipe';
import { ToMinutesPipe } from 'app/pipes/to-minutes/to-minutes.pipe';
import { TranslateToLangPipe } from 'app/pipes/translate-to-lang/translate-to-lang.pipe';
import { TranslateToLocalePipe } from 'app/pipes/translate-to-locale/translate-to-locale.pipe';
import { TranslatedModelPipe } from 'app/pipes/translated-model/translated-model.pipe';
import { TruncatePipe } from 'app/pipes/truncate/truncate.pipe';
import { UniquePipe } from 'app/pipes/unique/unique.pipe';
import { UpdateTextWithPipe } from 'app/pipes/update-text-with/update-text-with.pipe';
import { AttributeConfigHasOption } from './attribute-config-has-option/attribute-config-has-option.pipe';
import { CapitalizePipe } from './capitalize/capitalize.pipe';
import { DecapitalizePipe } from './capitalize/decapitalize.pipe';
import { ContentStatusConverterPipe } from './content-status-converter/content-status-converter.pipe';
import { GetLanguageFromIdPipe } from './get-language-from-id/get-language-from-id.pipe';
import { GroupFormElements } from './group-form-elements/group-form-elements.pipe';
import { PrefixWithTenantPipe } from './prefix-with-tenant/prefix-with-tenant.pipe';
import { QuantityBasedTranslationKeyPipe } from './quantity-based-translation-key/quantity-based-translation-key.pipe';
import { ReplaceNanPipe } from './replaceNan/replaceNan.pipe';
import { RoundPipe } from './round/round.pipe';
import { SplitPipe } from './split/split.pipe';
import { ToHoursMinutesPipe } from './to-hours-minutes/to-hours-minutes.pipe';
import { UnescapeHtmlPipe } from './unescape-html/unescape-html.pipe';

@NgModule({
	declarations: [
		AttributeOfPipe,
		CapitalizePipe,
		ContentStatusConverterPipe,
		DecapitalizePipe,
		EscapeHtmlPipe,
		FilterBy,
		FindBy,
		FirstPipe,
		GetFormByFormFieldPipe,
		GetLanguageFromIdPipe,
		GetTranslationForLanguage,
		GroupAsArrayByPipe,
		GroupItemsPipe,
		GroupFormElements,
		HighlightMatchPipe,
		ImageSizePipe,
		AttributeConfigHasOption,
		IsEmptyPipe,
		IsNotEmptyPipe,
		JoinPipe,
		LastElementPipe,
		PluckPipe,
		QuantityBasedTranslationKeyPipe,
		QuantityBasedTranslationKeyPipe,
		ReplaceNanPipe,
		RoundPipe,
		RoundPipe,
		SplitPipe,
		ToHoursMinutesPipe,
		ToMinutesPipe,
		TranslatedModelPipe,
		TranslateToLangPipe,
		TranslateToLocalePipe,
		TruncatePipe,
		UnescapeHtmlPipe,
		UniquePipe,
		UpdateTextWithPipe,
		PrefixWithTenantPipe,
	],
	exports: [
		AttributeOfPipe,
		CapitalizePipe,
		ContentStatusConverterPipe,
		DecapitalizePipe,
		EscapeHtmlPipe,
		FilterBy,
		FindBy,
		FirstPipe,
		GetFormByFormFieldPipe,
		GetLanguageFromIdPipe,
		GetTranslationForLanguage,
		GroupAsArrayByPipe,
		GroupItemsPipe,
		GroupFormElements,
		HighlightMatchPipe,
		ImageSizePipe,
		AttributeConfigHasOption,
		IsEmptyPipe,
		IsNotEmptyPipe,
		JoinPipe,
		LastElementPipe,
		PluckPipe,
		PrefixWithTenantPipe,
		QuantityBasedTranslationKeyPipe,
		QuantityBasedTranslationKeyPipe,
		ReplaceNanPipe,
		RoundPipe,
		SplitPipe,
		ToHoursMinutesPipe,
		ToMinutesPipe,
		TranslatedModelPipe,
		TranslateToLangPipe,
		TranslateToLocalePipe,
		TruncatePipe,
		UnescapeHtmlPipe,
		UniquePipe,
		UpdateTextWithPipe,
	],
	providers: [
		AttributeOfPipe,
		GetFormByFormFieldPipe,
		GetLanguageFromIdPipe,
		GetTranslationForLanguage,
		GroupAsArrayByPipe,
		ImageSizePipe,
		JoinPipe,
		LastElementPipe,
		SplitPipe,
		UniquePipe,
	],
})
export class PipesModule {}
