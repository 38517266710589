/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HasOne, ModelConfig } from 'ngx-hal';
import { Language } from '../enums/language.enum';
import { PushMessageDeliveryStatus } from '../enums/push-message-delivery-status.enum';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Country } from './country.hal.model';

@ModelConfig({
	type: 'PushMessageDelivery',
	endpoint: 'Delivery',
})
export class PushMessageDelivery extends HalDatastoreModel {
	@Attribute()
	public title: string;

	@Attribute()
	public description: string;

	@Attribute()
	public language: Language;

	@Attribute()
	public status: PushMessageDeliveryStatus;

	@HasOne({ propertyClass: Country })
	public country: Country;

	@Attribute()
	public modifiedAt: Date;

	@Attribute()
	public createdAt: Date;
}
