/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { CompatibleProduct } from '../../../models/compatible-product.model';
import { compareByProperty } from '../compare-by-property/compare-by-property';

export function compareCompatibleProducts(
	item1: CompatibleProduct,
	item2: CompatibleProduct,
): boolean {
	if (!item1 || !item2) {
		return false;
	}

	if (item1.device && item2.device) {
		return compareByProperty(item1.device, item2.device);
	} else if (item1.accessory && item2.accessory) {
		return compareByProperty(item1.accessory, item2.accessory);
	} else if (item1.product && item2.product) {
		return compareByProperty(item1.product, item2.product);
	}

	return false;
}
