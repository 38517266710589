/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { BelongsTo, Attribute as FormAttribute, HasMany as FormHasMany } from 'ngx-form-object';
import { Attribute, HasMany, HasOne, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { HighLevelCategoryEnum } from '../enums/high-level-category.enum';
import { PushMessageAudienceEnum } from '../enums/push-message-audience.enum';
import { isCategoryAncestor } from '../utils/helpers/is-category-ancestor/is-category-ancestor.helper';
import { Article } from './article.model';
import { Author } from './author.model';
import { Category } from './category.model';
import { Collection } from './collection.hal.model';
import { ContentContainer } from './content-container.model';
import { Content } from './content.model';
import { ManagingCountry } from './managing-country.hal.model';
import { PushMessageDelivery } from './push-message-delivery.model';
import { PushMessageTranslation } from './push-message-translation.model';
import { Recipe } from './recipe.hal.model';

@ModelConfig({
	type: 'PushMessage',
})
export class PushMessage extends Content {
	public get modelEndpoints(): ModelEndpoints {
		return {
			singleResourceEndpoint: this.datastore.rootApi.singlePushMessageEndpoint,
			collectionEndpoint: this.datastore.rootApi.collectionPushMessageEndpoint,
		};
	}

	@Attribute()
	@FormAttribute()
	public title: string;

	@Attribute()
	@FormAttribute()
	public description: string;

	@Attribute()
	@FormAttribute()
	public audience: PushMessageAudienceEnum;

	@HasMany({
		itemsType: ManagingCountry,
		externalName: 'countries',
	})
	@FormHasMany()
	public managingCountries: Array<ManagingCountry>;

	@HasMany({
		itemsType: PushMessageDelivery,
	})
	public deliveries: Array<PushMessageDelivery>;

	@HasOne({ propertyClass: Category })
	@BelongsTo()
	public pushMessageCategory: Category;

	@BelongsTo()
	public pushMessageAppliance: Category;

	@HasMany({
		itemsType: Category,
	})
	@FormHasMany()
	public categories: Array<Category>;

	@BelongsTo()
	public content: Content;

	@HasOne({
		propertyClass: ContentContainer,
		includeInPayload: true,
	})
	@BelongsTo()
	public contentContainer: ContentContainer;

	@HasOne({
		propertyClass: Article,
		includeInPayload: true,
	})
	@BelongsTo()
	public article: Article;

	@HasOne({
		propertyClass: Recipe,
		includeInPayload: true,
	})
	@BelongsTo()
	public recipe: Recipe;

	@HasOne({
		propertyClass: Collection,
		includeInPayload: true,
	})
	@BelongsTo()
	public collection: Collection;

	@HasOne({ propertyClass: Author })
	public lastModifiedBy: Author;

	@HasMany({
		itemsType: PushMessageTranslation,
	})
	@FormHasMany()
	public translations: Array<PushMessageTranslation>;

	@HasOne({
		propertyClass: PushMessageTranslation,
		includeInPayload: true,
	})
	@BelongsTo()
	public defaultTranslation: PushMessageTranslation;

	public get appliance(): Category {
		return (this.categories || []).find((category: Category) =>
			isCategoryAncestor(category.parent, HighLevelCategoryEnum.PUSH_MESSAGE_APPLIANCE_TYPE),
		) as Category;
	}

	public displayNameTranslationKey = 'contentTypeHal.pushMessage';
}
