/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { AbstractControl, ValidationErrors } from '@angular/forms';

export function isInteger(control: AbstractControl, message?: string): ValidationErrors | null {
	const value: unknown = control.value;

	const integer: boolean = Number.isInteger(Number.parseFloat(String(value)));

	return integer
		? null
		: {
				isInteger: {
					message,
					valid: false,
				},
		  };
}
