/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import { Attribute as FormAttribute } from 'ngx-form-object';

import { ScreenName } from '../enums/screen-name.enum';
import { unescapeHtml } from '../utils/helpers/helpers';

@JsonApiModelConfig({
	type: 'searchFilters',
})
export class SearchFilter extends JsonApiModel {
	public config = null;

	@Attribute()
	public id: string;

	@Attribute()
	@FormAttribute()
	public filterName: string;

	@Attribute()
	@FormAttribute()
	public screenName: ScreenName;

	@Attribute()
	@FormAttribute()
	public value: string;

	public get parsedValue(): Record<string, string | Array<string>> {
		return JSON.parse(unescapeHtml(this.value));
	}

	public outdated: boolean;
}
