/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import { Attribute as FormAttribute } from 'ngx-form-object';
import { ModelMetadata } from '../classes/model-metadata/model-metadata';
import { UserRole } from '../enums/user-role.enum';
import { UserStatus } from '../enums/user-status.enum';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';

@JsonApiModelConfig({
	type: 'users',
	meta: ModelMetadata,
})
export class User extends JsonApiModel {
	public config = null;

	@FormAttribute()
	public id: string;

	@Attribute()
	@FormAttribute()
	public username: string;

	@Attribute()
	@FormAttribute()
	public email: string;

	@Attribute()
	@FormAttribute({
		isChanged: (initial: Array<UserRole>, current: Array<UserRole>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
			}),
	})
	public roles: Array<UserRole>;

	@Attribute()
	@FormAttribute()
	public name: string;

	@Attribute()
	@FormAttribute()
	public createdAt: Date;

	@Attribute()
	@FormAttribute()
	public modifiedAt: Date;

	@Attribute()
	@FormAttribute()
	public preferredLanguage: string;

	@Attribute()
	@FormAttribute()
	public status: UserStatus;

	@Attribute()
	@FormAttribute()
	public company: string;

	public get displayNameAndEmail(): string {
		return `${this.name}`.concat(this.email ? ` (${this.email})` : '');
	}
}
