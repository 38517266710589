/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

// params: value - string
// returns a new string with first letter capitalized
export function capitalize(value: string): string {
	let capitalizedValue = value;
	if (value) {
		capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
	}

	return capitalizedValue;
}
