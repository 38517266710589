/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, ModelConfig } from 'ngx-hal';

import { Translation } from './translation.model';

@ModelConfig({
	type: 'CategoryTranslation',
	endpoint: 'CategoryTranslation',
})
export class CategoryTranslation extends Translation {
	@Attribute()
	public name: string;
}
