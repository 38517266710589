/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export function sortByProperty<T = any>(getProperty: string | ((item: T) => string)) {
	return (a: T, b: T) => {
		const propA: string = getSortProperty(a, getProperty)?.toLocaleLowerCase() || '';
		const propB: string = getSortProperty(b, getProperty)?.toLocaleLowerCase() || '';

		if (propA < propB) {
			return -1;
		} else if (propA > propB) {
			return 1;
		}

		return 0;
	};
}

function getSortProperty<T>(item: T, getProperty: string | ((item: T) => string)): string {
	if (getProperty instanceof Function) {
		return (getProperty as Function)(item);
	}

	return item[getProperty];
}
