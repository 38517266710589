/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { inject, Injectable, Injector } from '@angular/core';
import { RoleFormStore } from '../../forms/role/role.form-store';
import { Administrator } from '../../models/administrator.model';
import { Role } from '../../models/role.model';
import { HalDatastoreService } from '../datastore/hal-datastore.service';
import { HalModelService } from '../hal-model/hal-model.service';
import { RoleFormObject } from './../../forms/role/role.form-object';

@Injectable({
	providedIn: 'root',
})
export class RoleService extends HalModelService<Role> {
	private readonly injector: Injector = inject(Injector);

	constructor(protected readonly datastore: HalDatastoreService) {
		super(datastore, Role);
	}

	public createRoleForm(administrator: Administrator, role?: Role): RoleFormStore {
		const newRole: Role = role || this.createNewModel();

		const roleFormObject = new RoleFormObject(newRole, null, this.injector, administrator);
		return roleFormObject.form as RoleFormStore;
	}
}
