/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { SimpleHalModel } from 'ngx-hal';

export class MediaSizes extends SimpleHalModel {
	constructor(public sizes: Record<string, string>) {
		super();
	}

	public get thumbnail(): string {
		return this.sizes.thumbnail;
	}

	public get tenSecondVideoPreview(): string {
		return this.sizes['fmp4-240p-preview'];
	}
}
