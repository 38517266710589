/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiModelConfig, JsonApiModel, BelongsTo, Attribute, HasMany } from 'angular2-jsonapi';

import { NutritionListItem } from './nutrition-list-item.model';

import { Country } from 'app/custom-types/country/country.type';
import { Recipe } from 'app/models/recipe.model';
import { NutritionClaimGuideline } from 'app/models/nutrition-claim-guideline.model';

@JsonApiModelConfig({
	type: 'recipeNutritionInfo',
})
export class RecipeNutritionInfo extends JsonApiModel {
	@Attribute()
	public calories: number;

	@Attribute({
		dataType: Country,
	})
	public country: Country;

	@Attribute()
	public carbs: number;

	@Attribute()
	public carbsCalorieDaily: number;

	@Attribute()
	public carbsCaloriePercentage: number;

	@Attribute()
	public carbsCalories: number;

	@Attribute()
	public fat: number;

	@Attribute()
	public fatCalorieDaily: number;

	@Attribute()
	public fatCaloriePercentage: number;

	@Attribute()
	public fatCalories: number;

	@Attribute()
	public other: number;

	@Attribute()
	public otherCaloriePercentage: number;

	@Attribute()
	public otherCalories: number;

	@Attribute()
	public proteinCalorieDaily: number;

	@Attribute()
	public proteinCaloriePercentage: number;

	@Attribute()
	public proteinCalories: number;

	@Attribute()
	public proteins: number;

	@HasMany()
	public vitamins: Array<NutritionListItem> = [];

	@HasMany()
	public minerals: Array<NutritionListItem> = [];

	@HasMany()
	public macroNutrients: Array<NutritionListItem> = [];

	@HasMany()
	public importantNutrients: Array<NutritionListItem>;

	@HasMany()
	public nutritionClaimGuidelines: Array<NutritionClaimGuideline>;

	@BelongsTo()
	public recipe: Recipe;
}
