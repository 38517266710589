/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HasOne, ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { FoodComposition } from './food-composition.model';
import { Food } from './food.model';

@ModelConfig({
	type: 'FoodCompositionForFood',
})
export class FoodCompositionForFood extends HalDatastoreModel {
	public static modelType = 'FoodCompositionForFood';

	@HasOne({
		propertyClass: 'Food',
	})
	public food: Food;

	@HasOne({
		propertyClass: FoodComposition,
	})
	public composition: FoodComposition;
}
