<h2 mat-dialog-title>{{ title }}</h2>

<mat-dialog-content>
	<div *ngIf="errors?.length || isOnekaErrorResponse; else defaultErrorTemplate">
		<div *ngIf="isOnekaErrorResponse; else errorListTemplate">
			{{ data.extra.errorMessage }}
		</div>
		<ng-template #errorListTemplate>
			<div *ngFor="let error of errors">
				{{ error.detail }}
			</div>
		</ng-template>
	</div>

	<ng-template #defaultErrorTemplate>
		<p>{{ 'error.httpResponse' | translate }}</p>
		<pre *ngIf="isMesssageJsonFormat(); else rawMessage" class="errorMessage">{{
			data.extra.errorMessage | json
		}}</pre>

		<ng-template #rawMessage>
			<pre class="errorMessage">{{ data.extra.errorMessage }}</pre>
		</ng-template>
	</ng-template>
</mat-dialog-content>

<confirmation-actions [data]="data" (cancel)="onCancelClick()"></confirmation-actions>
