/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export function joinLastDifferent(
	array: Array<any>,
	separator: string,
	lastSeparator: string,
): string {
	if (array.length <= 2) {
		return array.join(lastSeparator);
	} else {
		return array.slice(0, -1).join(separator) + lastSeparator + array[array.length - 1];
	}
}
