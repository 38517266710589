/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HasOne, ModelConfig } from 'ngx-hal';
import { Language } from '../enums/language.enum';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { transformLanguageForJsonApi } from '../utils/helpers/language-transformer/language-transformer.helper';
import { Profile } from './profile.hal.model';

@ModelConfig({
	type: 'Author',
	endpoint: 'Author',
})
export class Author extends HalDatastoreModel {
	public static modelType = 'Author';

	@Attribute()
	public name: string;

	@Attribute()
	public email: string;

	@HasOne({ propertyClass: Profile })
	public profile: Profile;

	@Attribute({
		transformResponseValue: transformLanguageForJsonApi,
	})
	public language: Language;

	public get displayName(): string {
		if (!this.name) {
			return this.profileId;
		}

		let label: string = this.name;

		if (this.email) {
			label = label.concat(` (${this.email})`);
		} else if (this.profileId) {
			label = label.concat(` (${this.profileId})`);
		}

		return label;
	}

	public get profileId(): string | null {
		const profileUrl: string = this.getRelationshipUrl('profile');

		if (!profileUrl) {
			return null;
		}

		const urlParts: Array<string> = profileUrl.split('/');
		return urlParts[urlParts.length - 1];
	}
}
