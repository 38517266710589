/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { IIntegerWithFraction, IFraction } from '../../../interfaces/fraction.interface';

interface IDiffFraction {
	fraction: IFraction;
	diff: number;
}

const acceptableFractions: Array<IFraction> = [
	{ numerator: 1, denominator: 1 }, // 1 if the number is >15/16
	{ numerator: 1, denominator: 2 },
	{ numerator: 1, denominator: 3 },
	{ numerator: 2, denominator: 3 },
	{ numerator: 1, denominator: 4 },
	{ numerator: 3, denominator: 4 },
	{ numerator: 1, denominator: 5 },
	{ numerator: 2, denominator: 5 },
	{ numerator: 3, denominator: 5 },
	{ numerator: 4, denominator: 5 },
	{ numerator: 1, denominator: 8 },
	{ numerator: 3, denominator: 8 },
	{ numerator: 5, denominator: 8 },
	{ numerator: 7, denominator: 8 },
	{ numerator: 0, denominator: 1 }, // 0 if the number is <1/16
];

export function numberToFraction(value: number): IIntegerWithFraction {
	const integer: number = Math.floor(value);
	const decimal: number = value - integer;

	if (!decimal) {
		return { integer };
	}

	const fractionsWithDiffs: Array<IDiffFraction> = acceptableFractions.map(
		(fraction: IFraction) => ({
			fraction,
			diff: Math.abs(decimal - fraction.numerator / fraction.denominator),
		}),
	);

	const minDiff: number = Math.min(...fractionsWithDiffs.map((f) => f.diff));

	const minFractionWithDiff = fractionsWithDiffs.find(
		(fractionWithDiff: IDiffFraction) => fractionWithDiff.diff === minDiff,
	);

	const minFraction: IFraction = minFractionWithDiff.fraction;

	// Handle 1/1
	if (minFraction.numerator === 1 && minFraction.denominator === 1) {
		return { integer: integer + 1 };
	}

	return { integer, fraction: minFraction.numerator ? minFraction : null };
}
