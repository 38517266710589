/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { InjectionToken } from '@angular/core';
import { EnvironmentVariablesService } from '@infinum/ngx-nuts-and-bolts/env';
import { EnvironmentVariable } from '../environments/environment-variable.enum';

export const APP_CONFIG = new InjectionToken<IAppConfig>('app.config');

interface IApiConfig {
	host: string;
	endpoint: string;
}

export interface IAppConfig {
	api: IApiConfig;
	apiv2: IApiConfig;
	dam: {
		api: IApiConfig;
	};
}

let adminUiAppConfig: IAppConfig;

function initializeAppConfig(
	envVarsService: EnvironmentVariablesService<EnvironmentVariable>,
): IAppConfig {
	const host: string = envVarsService.get(EnvironmentVariable.API_HOST);
	const digitalAssetManagementAppHost: string = envVarsService.get(
		EnvironmentVariable.DAM_API_HOST,
	);

	const appConfig: IAppConfig = {
		api: {
			host,
			endpoint: 'api/v1',
		},
		apiv2: {
			host,
			endpoint: 'api',
		},
		dam: {
			api: {
				host: digitalAssetManagementAppHost,
				endpoint: '',
			},
		},
	};

	return appConfig;
}

function getAppConfig(
	envVarsService: EnvironmentVariablesService<EnvironmentVariable>,
): IAppConfig {
	if (!adminUiAppConfig) {
		adminUiAppConfig = initializeAppConfig(envVarsService);
	}

	return adminUiAppConfig;
}

export function provideAppConfig() {
	return {
		provide: APP_CONFIG,
		useFactory: (envVarsService: EnvironmentVariablesService<EnvironmentVariable>) => {
			return getAppConfig(envVarsService);
		},
		deps: [EnvironmentVariablesService],
	};
}
