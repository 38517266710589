/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import {
	Attribute,
	BelongsTo,
	HasMany,
	JsonApiDatastore,
	JsonApiModel,
	JsonApiModelConfig,
} from 'angular2-jsonapi';
import {
	Attribute as FormAttribute,
	BelongsTo as FormBelongsTo,
	HasMany as FormHasMany,
} from 'ngx-form-object';

import { CommentResolvedStatus } from '../models/comment-resolved-status.model';
import { ModelMetadata } from '../classes/model-metadata/model-metadata';
import { AuthorType } from '../enums/author-type.enum';
import { CommentStatus } from '../enums/comment-status.enum';
import { Language } from '../enums/language.enum';
import { IMutableHistory } from '../interfaces/mutable-history.interface';
import { Country } from '../custom-types/country/country.type';
import { ConsumerProfile } from '../models/consumer-profile.model';
import { CountryProfile } from '../models/country-profile.model';
import { HistoryItem } from '../models/history-item.model';
import { Recipe } from '../models/recipe.model';
import { ReportedContent } from '../models/reported-content.model';

@JsonApiModelConfig({
	type: 'comments',
	meta: ModelMetadata,
})
export class Comment extends JsonApiModel implements IMutableHistory {
	public config = null;

	@FormAttribute()
	public id: string;

	@Attribute()
	@FormAttribute()
	public commentText: string;

	@Attribute({
		dataType: Date,
	})
	public createdAt: Date;

	@Attribute()
	@FormAttribute()
	public status: CommentStatus;

	@Attribute()
	@FormAttribute()
	public commenterLanguage: Language;

	@Attribute({
		dataType: Country,
	})
	@FormAttribute()
	public commenterCountry: Country;

	@Attribute()
	@FormAttribute()
	public commentResolvedStatus: CommentResolvedStatus;

	@BelongsTo()
	@FormBelongsTo()
	public createdBy: ConsumerProfile | CountryProfile;

	@BelongsTo()
	@FormBelongsTo()
	public contentProfile: ConsumerProfile | CountryProfile;

	@BelongsTo()
	@FormBelongsTo()
	public recipe: Recipe;

	@HasMany()
	@FormHasMany()
	public historyItems: Array<HistoryItem>;

	@HasMany()
	@FormHasMany()
	public itemReports: Array<ReportedContent>;

	public get type(): AuthorType {
		if (this.createdBy) {
			return this.createdBy.type;
		}
	}

	public contentId: string;
	public contentType: string; // TODO check if this is used anywhere

	public constructor(datastore: JsonApiDatastore, data?: any) {
		super(datastore, data);

		this.contentType = this.getContentType(data);
	}

	private getContentType(rawResponse: any): string {
		if (!rawResponse || !rawResponse.relationships) {
			return null;
		}

		const content: any = rawResponse.relationships.content;

		if (!content || !content.data) {
			return null;
		}

		this.contentId = content.data.id;
	}
}
