/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, BelongsTo, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import { Attribute as FormAttribute, BelongsTo as FormBelongsTo } from 'ngx-form-object';

import { Language } from '../enums/language.enum';
import { TranslationStatus } from '../enums/translation-status.enum';
import { IModelTranslation } from '../interfaces/model-translation.interface';
import { Recipe } from '../models/recipe.model';

@JsonApiModelConfig({
	type: 'recipeTranslations',
})
export class RecipeTranslation extends JsonApiModel implements IModelTranslation {
	public config = null;

	@FormAttribute()
	public id: string;

	@Attribute()
	@FormAttribute()
	public title: string;

	@Attribute()
	@FormAttribute()
	public language: Language;

	@Attribute()
	@FormAttribute()
	public description: string;

	@Attribute({
		dataType: Date,
	})
	public modifiedAt: Date;

	@Attribute()
	@FormAttribute()
	public status: TranslationStatus;

	@Attribute()
	@FormAttribute()
	public viewCount: number;

	@Attribute()
	@FormAttribute()
	public favouriteCount: number;

	@Attribute()
	@FormAttribute()
	public keywords: string;

	@BelongsTo()
	@FormBelongsTo()
	public recipe: Recipe;
}
