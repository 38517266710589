/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pagination } from 'ngx-hal';

export class HalPagination extends Pagination {
	public firstPageNumber = 1;

	private get paginationData() {
		return this.rawResource.page;
	}

	public get pageNumber(): number {
		return this.paginationData.number;
	}

	public get pageSize(): number {
		return this.paginationData.size;
	}

	public get totalElements(): number {
		return this.paginationData.totalElements;
	}

	public get totalPages(): number {
		return this.paginationData.totalPages;
	}

	public resetPagination(): void {
		this.paginationData.number = this.firstPageNumber;
	}
}
