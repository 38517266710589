/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterBy' })
export class FilterBy implements PipeTransform {
	transform<T>(items: Array<T> = [], propertyPath: string, propertyValue: any): Array<T> {
		const propertyNames: Array<string> = propertyPath.split('.');

		return items.filter((item: T) => {
			let specifiedPropertyValue = item;

			propertyNames.forEach((propertyName: string) => {
				specifiedPropertyValue = specifiedPropertyValue[propertyName];
			});

			return specifiedPropertyValue === propertyValue;
		});
	}
}
