/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { BelongsTo, Attribute as FormAttribute, HasMany as FormHasMany } from 'ngx-form-object';
import { Attribute, HasMany, HasOne, ModelConfig } from 'ngx-hal';
import { Power } from '../classes/power.class';
import { IPressureInterface, IPressureRawInterface, Pressure } from '../classes/pressure.class';
import { IQuantifiableInterface } from '../classes/quantifiable.class';
import { Temperature } from '../classes/temperature.class';
import { AirSpeed } from '../enums/air-speed.enum';
import { DeviceCategoryEnum } from '../enums/device-category.enum';
import { ElectricSystem } from '../enums/electric-system.enum';
import { HighLevelCategoryEnum } from '../enums/high-level-category.enum';
import { Humidity } from '../enums/humidity.enum';
import { Pan } from '../enums/pan.enum';
import { PowerUnit } from '../enums/power-unit.enum';
import { TemperatureUnit } from '../enums/temperature-unit.enum';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';
import { compareByProperty } from '../utils/helpers/compare-by-property/compare-by-property';
import {
	transformDurationIsoStringToSeconds,
	transformSecondsToDurationIsoString,
} from '../utils/helpers/duration-iso-string-converter/duration-iso-string-converter';
import { compareCompatibleProducts } from '../utils/helpers/helpers';
import { AttributeConfig } from './attribute-config/attribute-config.model';
import { Category } from './category.model';
import { CompatibleProduct } from './compatible-product.model';
import { CookingMethod } from './cooking-method.model';
import { CookingVariableCategory } from './cooking-variable-category.model';
import { RecipeStep } from './recipe-step.model';

@ModelConfig({
	type: 'CookingVariable',
})
export class CookingVariable extends HalDatastoreModel {
	public static modelType = 'CookingVariable';

	@FormAttribute()
	public applianceType: Category;

	@HasOne({ propertyClass: CookingVariableCategory })
	@BelongsTo()
	public parent: CookingVariable;

	@HasMany({
		itemsType: CookingVariable,
		includeInPayload: true,
	})
	@FormHasMany()
	// This is used to save relationships to child CookingVariables and is not exposed on API GET level
	public cookingVariables: Array<CookingVariable>;

	@Attribute()
	@FormAttribute()
	public speed: number;

	@Attribute({
		useClass: Temperature,
		transformBeforeSave: (temperature: IQuantifiableInterface<TemperatureUnit>) =>
			// Recipes with devices that don't use temperature (e.g. Blenders) need to send temperature object
			// with unit value set anyway, but for pressure cookers temperature object must not be sent at all
			temperature.unit
				? {
						quantity: temperature.quantity,
						unit: temperature.unit,
					}
				: undefined,
	})
	@FormAttribute()
	public temperature: Temperature;

	@Attribute()
	@FormAttribute()
	public numberOfShakes: number;

	@Attribute()
	@FormAttribute()
	public electricSystem: ElectricSystem;

	@Attribute()
	@FormAttribute()
	public pan: Pan;

	@Attribute()
	@FormAttribute()
	public humidityLevel: Humidity;

	@Attribute()
	@FormAttribute()
	public airSpeed: AirSpeed;

	@Attribute({
		transformBeforeSave: (seconds: number) => transformSecondsToDurationIsoString(seconds),
		transformResponseValue: (isoString: string) => transformDurationIsoStringToSeconds(isoString),
	})
	@FormAttribute()
	public duration: string;

	@Attribute({
		transformBeforeSave: (pressure: IPressureInterface) =>
			pressure.unit
				? {
						quantity: pressure.quantity,
						unit: pressure.unit,
						buildDuration: transformSecondsToDurationIsoString(pressure.buildDuration),
						releaseDuration: transformSecondsToDurationIsoString(pressure.releaseDuration),
					}
				: undefined,
		transformResponseValue: (pressure: IPressureRawInterface) =>
			new Pressure(
				pressure && {
					quantity: pressure.quantity,
					unit: pressure.unit,
					buildDuration: transformDurationIsoStringToSeconds(pressure.buildDuration),
					releaseDuration: transformDurationIsoStringToSeconds(pressure.releaseDuration),
				},
			),
	})
	@FormAttribute()
	public pressure: Pressure;

	@Attribute({
		useClass: Power,
		transformBeforeSave: (power: IQuantifiableInterface<PowerUnit>) =>
			power.unit
				? {
						quantity: power.quantity,
						unit: power.unit,
					}
				: undefined,
	})
	@FormAttribute()
	public power: Power;

	@HasMany({
		itemsType: CompatibleProduct,
	})
	@FormHasMany()
	public compatibleProducts: Array<CompatibleProduct>;

	@FormHasMany({
		isChanged: (initial: Array<CompatibleProduct>, current: Array<CompatibleProduct>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: CompatibleProduct) => item?.accessory?.id || '',
			}),
	})
	public get compatibleAccessories(): Array<CompatibleProduct> {
		return (this.compatibleProducts || []).filter(
			(compatibleProduct: CompatibleProduct) => compatibleProduct.links.accessory,
		);
	}

	public set compatibleAccessories(value: Array<CompatibleProduct>) {
		// Do nothing
	}

	@FormHasMany({
		isChanged: (initial: Array<CompatibleProduct>, current: Array<CompatibleProduct>): boolean =>
			!compareArrays<CompatibleProduct>(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: CompatibleProduct) => item?.device?.id || '',
			}),
	})
	public get compatibleDevices(): Array<CompatibleProduct> {
		return (this.compatibleProducts || []).filter(
			(compatibleProduct: CompatibleProduct) => compatibleProduct.links.device,
		);
	}

	public set compatibleDevices(value: Array<CompatibleProduct>) {
		// Do nothing
	}

	// recipeStep relationship is not present on the backend
	@HasOne({ propertyClass: 'RecipeStep' })
	public recipeStep: RecipeStep;

	@HasOne({
		propertyClass: CookingMethod,
		includeInPayload: true,
	})
	@BelongsTo()
	public cookingMethod: CookingMethod;

	// Child cooking method is saved in Form only and set as main cooking method in CookingVariableFormObject.beforeSave
	@FormAttribute({
		isChanged: (a: CookingMethod, b: CookingMethod): boolean => a?.category !== b?.category,
	})
	public childCookingMethod: CookingMethod;

	@HasOne({
		propertyClass: AttributeConfig,
		includeInPayload: true,
	})
	@BelongsTo()
	public attributeConfig: AttributeConfig;

	@HasMany({
		itemsType: CookingVariableCategory,
	})
	@FormHasMany({
		isChanged: (
			initial: Array<CookingVariableCategory>,
			current: Array<CookingVariableCategory>,
		): boolean =>
			initial &&
			current &&
			!compareArrays<CookingVariableCategory>(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: CookingVariableCategory) => item?.category?.id || '',
			}),
	})
	public categories: Array<CookingVariableCategory>;

	@BelongsTo({
		isChanged: (initial: Category, current: Category): boolean =>
			initial && current && !compareByProperty<Category>(initial, current, 'slug'),
	})
	public get sourceProductCategory(): Category {
		return this.sourceProduct?.device?.categories?.find(
			(category: Category) => category?.parent?.parent?.slug === HighLevelCategoryEnum.DEVICE_GROUP,
		);
	}
	public set sourceProductCategory(_value: Category) {
		// Do nothing
	}

	@FormHasMany({
		isChanged: (initial: Array<Category>, current: Array<Category>): boolean =>
			!compareArrays<Category>(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Category) => item?.id || '',
			}),
	})
	public get productGroups(): Array<Category> {
		return (this.categories || [])
			.map((cookingVariableCategory: CookingVariableCategory) => cookingVariableCategory.category)
			.filter(
				(category: Category) =>
					category.parent?.parent?.slug === HighLevelCategoryEnum.DEVICE_GROUP,
			);
	}

	public set productGroups(value: Array<Category>) {
		// Do nothing
	}

	@BelongsTo({
		isChanged: (initialValue: CompatibleProduct, currentValue: CompatibleProduct): boolean => {
			const firstName: string = initialValue?.product?.name;
			const secondName: string = currentValue?.product?.name;
			return firstName !== secondName;
		},
	})
	public get sourceProduct(): CompatibleProduct {
		const products: Array<CompatibleProduct> = this.compatibleDevices || [];
		return products.find((product: CompatibleProduct) => product.isVerified);
	}

	public set sourceProduct(sourceProduct: CompatibleProduct) {
		const existingProduct = this.compatibleProducts.find((currentProduct: CompatibleProduct) =>
			compareCompatibleProducts(currentProduct, sourceProduct),
		);
		if (!existingProduct) {
			this.compatibleProducts.push(sourceProduct);
		}

		this.compatibleProducts.forEach((currentProduct: CompatibleProduct) => {
			if (compareCompatibleProducts(currentProduct, sourceProduct)) {
				currentProduct.isVerified = true;
			} else {
				currentProduct.isVerified = false;
			}
		});
	}

	public get deviceCategorySlug(): string {
		if (this.sourceProduct) {
			const deviceCategory: Category = this.sourceProduct.device.deviceCategory;
			// Return NO_DEVICE if product has no categories - this is a workaround for consumers with Generic Airfryer device
			return deviceCategory ? deviceCategory.slug : DeviceCategoryEnum.NO_DEVICE;
		} else {
			return DeviceCategoryEnum.NO_DEVICE;
		}
	}
}
