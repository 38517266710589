<mat-dialog-actions align="end">
	<button
		*ngIf="data.cancelButton"
		class="button js-test--cancel-button"
		color="primary"
		data-e2e-test-id="cancel-button"
		mat-raised-button
		type="button"
		[class.button--inverse]="data.cancelButton.inverse"
		(click)="onCancelClick()"
	>
		{{ data.cancelButton.label }}
	</button>

	<button
		*ngIf="data.saveButton"
		class="button js-test--confirm-button"
		color="primary"
		data-e2e-test-id="confirm-button"
		mat-raised-button
		type="button"
		[class.button--inverse]="data.saveButton.inverse"
		[disabled]="saveDisabled"
		(click)="onSaveClick()"
	>
		{{ data.saveButton.label }}
	</button>
</mat-dialog-actions>
