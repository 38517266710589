/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { ErrorModalComponent } from './error-modal.component';

import { PopupDialogModule } from 'app/components/popup-dialog/popup-dialog.module';
import { MaterialModule } from 'app/shared-modules/material.module';

@NgModule({
	imports: [CommonModule, TranslateModule, MaterialModule, PopupDialogModule],
	declarations: [ErrorModalComponent],
})
export class ErrorModalModule {}
