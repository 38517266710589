/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, BelongsTo, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import { ModelMetadata } from 'app/classes/model-metadata/model-metadata';
import { AuthorType } from 'app/enums/author-type.enum';
import { Domain } from 'app/models/domain.model';
import { DEFAULT_COUNTRY_PROFILE_NAME } from '../constants/default-county-profile-name.constant';
import { PHILIPS_PROFILE_NAME } from '../constants/philips-profile-name.constant';

@JsonApiModelConfig({
	type: 'countryProfiles',
	meta: ModelMetadata,
})
export class CountryProfile extends JsonApiModel {
	@Attribute()
	public name: string;

	@Attribute()
	public type: AuthorType;

	@BelongsTo()
	public domain: Domain;

	public get isDefaultProfileForItsCountry(): boolean {
		return this.name === PHILIPS_PROFILE_NAME || this.name === DEFAULT_COUNTRY_PROFILE_NAME;
	}
}
