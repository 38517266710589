/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export function escapeHtml(value = ''): string {
	if (typeof value !== 'string') {
		return value;
	}

	return value
		.replace(/&(?!amp;)(?!apos;)(?!quot;)(?!gt;)(?!lt;)/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/'/g, '&apos;')
		.replace(/"/g, '&quot;');
}
