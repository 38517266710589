/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { isArray } from '../is-array/is-array';
import { isObject } from '../object/object.helper';

export function transformObjectStrings(
	originalValue: any,
	functionToApply: Function = (value: any) => value,
): any {
	let transformedValue: any = originalValue;

	if (typeof originalValue === 'string') {
		transformedValue = functionToApply(originalValue);
	} else if (isObject(originalValue)) {
		transformedValue = Object.assign({}, originalValue);
		Object.keys(transformedValue).forEach((key: string) => {
			transformedValue[key] = transformObjectStrings(transformedValue[key], functionToApply);
		});
	} else if (isArray(originalValue)) {
		transformedValue = [].concat(originalValue);
		transformedValue.forEach((item: any, index: number) => {
			transformedValue[index] = transformObjectStrings(item, functionToApply);
		});
	}

	return transformedValue;
}
