/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as AttributeNgxHal, HasMany, HasOne, ModelConfig } from 'ngx-hal';
import { AttributeValueType } from '../../enums/attribute-value-type.enum';
import { CookingVariableDeviceOption } from '../../enums/device-option.enum';
import { PresentationType } from '../../enums/presentation-type.enum';
import { SelectionType } from '../../enums/selection-type.enum';
import { IGenericOption } from '../../interfaces/generic-option.interface';
import { HalDatastoreModel } from '../../services/datastore/models/hal-datastore-model.model';
import { getDeviceOptionControlName } from '../../utils/helpers/helpers';
import { AttributeOption } from './attribute-option.model';
import { IAttribute } from './attribute.interface';

@ModelConfig({
	type: 'Attribute',
	endpoint: 'AttributeConfig/Attribute',
})
export class Attribute extends HalDatastoreModel implements IAttribute {
	@AttributeNgxHal()
	public availableValues: Array<IGenericOption>;

	@AttributeNgxHal()
	public defaultValue: string | number;

	@AttributeNgxHal()
	public isAdjustable: boolean;

	@AttributeNgxHal()
	public isRequired: boolean;

	@AttributeNgxHal()
	public isVisible: boolean;

	@AttributeNgxHal()
	public label: string;

	@AttributeNgxHal()
	public name: string;

	@AttributeNgxHal()
	public placeholder: string;

	@AttributeNgxHal()
	public selectionType: SelectionType;

	@AttributeNgxHal()
	public valueType: AttributeValueType;

	@AttributeNgxHal()
	public presentationType: PresentationType;

	@HasOne({ propertyClass: Attribute })
	public cookingProfile: Attribute | null;

	@HasOne({ propertyClass: Attribute })
	public parent: Attribute | null;

	@HasOne({ propertyClass: Attribute })
	public prerequisite: Attribute | null;

	@HasMany({
		itemsType: 'CookingVariableAttributeOption',
	})
	public options: Array<AttributeOption>;

	// TODO: when implementing a new attribute config implementation, have this be a base class
	// and move cooking variable specific stuff to a subclass and replace it all over the steps code
	public get formFieldName(): string {
		return getDeviceOptionControlName(this.name as CookingVariableDeviceOption);
	}

	public get isLinkType(): boolean {
		return this.valueType === AttributeValueType.LINK;
	}

	public get isEnumType(): boolean {
		return this.valueType === AttributeValueType.ENUM;
	}

	public get isObjectType(): boolean {
		return this.valueType === AttributeValueType.OBJECT;
	}

	public get isDurationType(): boolean {
		return this.valueType === AttributeValueType.DURATION;
	}

	public get isSingleSelect(): boolean {
		return this.selectionType === SelectionType.SINGLE;
	}

	public get isMultiSelect(): boolean {
		return this.selectionType === SelectionType.MULTIPLE;
	}

	public get isNotSelectable(): boolean {
		return this.selectionType === SelectionType.NONE;
	}

	public get isGrouped(): boolean {
		return this.presentationType === PresentationType.GROUPED;
	}

	public get isStandalone(): boolean {
		return this.presentationType === PresentationType.STANDALONE;
	}
}
