/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum HttpCode {
	INFO_100_CONTINUE = 100,
	INFO_101_SWITCHING_PROTOCOLS = 101,
	INFO_102_processing = 102,

	SUCCESS_200_OK = 200,
	SUCCESS_201_CREATED = 201,
	SUCCESS_202_ACCEPTED = 202,
	SUCCESS_203_NON_AUTHORITATIVE_INFORMATION = 203,
	SUCCESS_204_NO_CONTENT = 204,
	SUCCESS_205_RESET_CONTENT = 205,
	SUCCESS_206_PARTIAL_CONTENT = 206,
	SUCCESS_207_MULTI_STATUS = 207,
	SUCCESS_208_ALREADY_REPORTED = 208,
	SUCCESS_226_IM_USED = 226,

	REDIRECTION_300_MULTIPLE_CHOICES = 300,
	REDIRECTION_301_MOVED_PERMANENTLY = 301,
	REDIRECTION_302_FOUND = 302,
	REDIRECTION_303_SEE_OTHER = 303,
	REDIRECTION_304_NOT_MODIFIED = 304,
	REDIRECTION_305_USE_PROXY = 305,
	REDIRECTION_306_SWITCH_PROFX = 306,
	REDIRECTION_307_TEMPORARY_REDIRECT = 307,
	REDIRECTION_308_PERMANENT_REDIRECT = 308,

	CLIENT_400_BAD_REQUEST = 400,
	CLIENT_401_UNAUTHORIZED = 401,
	CLIENT_402_PAYMENT_REQUIRED = 402,
	CLIENT_403_FORBIDDEN = 403,
	CLIENT_404_NOT_FOUND = 404,
	CLIENT_405_METHOD_NOT_ALLOWED = 405,
	CLIENT_406_NOT_ACCEPTABLE = 406,
	CLIENT_407_PROXY_AUTHENTICATION_REQUIRED = 407,
	CLIENT_408_REQUEST_TIMEOUT = 408,
	CLIENT_409_CONFLICT = 409,
	CLIENT_410_GONE = 410,
	CLIENT_411_LENGTH_REQUIRED = 411,
	CLIENT_412_PRECONDITION_FAILED = 412,
	CLIENT_413_PAYLOAD_TOO_LARGE = 413,
	CLIENT_414_URI_TOO_LONG = 414,
	CLIENT_415_UNSUPPORTED_MEDIA_TYPE = 415,
	CLIENT_416_RANGE_NOT_SATISFIABLE = 416,
	CLIENT_417_EXPECTATION_FAILED = 417,
	CLIENT_418_TEAPOT = 418,
	CLIENT_421_MISDIRECTED_REQUEST = 421,
	CLIENT_422_UNPROCESSABLE_ENTITY = 422,
	CLIENT_423_LOCKED = 423,
	CLIENT_424_FAILED_DEPENDENCY = 424,
	CLIENT_426_UPGRADE_REQUIRED = 426,
	CLIENT_428_PRECONDITION_REQUIRED = 428,
	CLIENT_429_TOO_MANY_REQUESTS = 429,
	CLIENT_431_REQUEST_HEADER_FIELDS_TOO_LARGE = 431,
	CLIENT_451_UNABAILABLE_FOR_LEAGAL_REASONS = 451,

	SERVER_500_INTERNAL = 500,
	SERVER_501_NOT_IMPLEMENTED = 501,
	SERVER_502_BAD_GATEWAY = 502,
	SERVER_503_SERVICE_UNAVAILABLE = 503,
	SERVER_504_GATEWAY_TIMEOUT = 504,
	SERVER_505_HTTP_VERSION_NOT_SUPPORTED = 505,
	SERVER_506_VARIANT_ALSO_NEGOTIATES = 506,
	SERVER_507_INSUFFICIENT_STORAGE = 507,
	SERVER_508_LOOP_DETECTED = 508,
	SERVER_510_NOT_EXTENDED = 510,
	SERVER_511_NETWORK_AUTHENTICATION_PROBLEM = 511,
}
