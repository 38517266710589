/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Overrides } from './overrides.interface';

export interface DatastoreConfig {
	apiVersion?: string;
	baseUrl?: string;
	models?: object;
	overrides?: Overrides;
}
