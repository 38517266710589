/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import {
	Component,
	Input,
	ContentChild,
	TemplateRef,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
	selector: 'form-field',
	templateUrl: 'form-field.component.html',
	styleUrls: ['form-field.component.scss'],
})
export class FormFieldComponent implements OnChanges {
	@Input() containerClass: string;
	@Input() label: string;
	@Input() labelClass: string;
	@Input() labelDirection = 'left';
	@Input() errorMessageHidden: boolean;
	@Input() errorMessage: string;
	@Input() form: UntypedFormGroup;
	@Input() fieldName: string;
	@Input() labelTooltip: string = null;
	@Input() warningMessage: string;
	@Input() warningMessageHidden: boolean;

	@ContentChild('errorsTemplate') errorsTemplate: TemplateRef<any>;
	@ContentChild('errorsWithIconTemplate') errorsWithIconTemplate: TemplateRef<any>;

	public formControl: AbstractControl;

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.form) {
			this.formControl = this.form ? this.form.controls[this.fieldName] : null;
		}
	}
}
