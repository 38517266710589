/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import * as duration from 'duration-fns';

export function transformSecondsToDurationIsoString(seconds: number): string {
	return duration.toString(duration.normalize({ seconds: seconds ?? 0 }));
}

export function transformDurationIsoStringToSeconds(isoString: string): number {
	if (!isoString) {
		return 0;
	}
	return duration.toSeconds(duration.parse(isoString));
}

export function transformDurationIsoStringToHours(isoString: string): number {
	if (!isoString) {
		return 0;
	}
	return duration.toHours(duration.parse(isoString));
}
