/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injector } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
	BuildControl,
	BuildRelationshipFormObject,
	ExtendedFormArray,
	FormObjectOptions,
} from 'ngx-form-object';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { UserRole } from '../../enums/user-role.enum';
import { Role } from '../../models/role.model';
import { isNumberInRange } from '../../validators/validators';
import { BaseFormObject } from '../base-form-object/base.form-object';
import { RoleFormObject } from '../role/role.form-object';
import { Administrator } from './../../models/administrator.model';
import { RoleManipulationService } from './../../services/role-manipulation/role-manipulation.service';
import { AdministratorFormStore } from './administrator.form-store';

export class AdministratorFormObject extends BaseFormObject<Administrator> {
	public formStoreClass = AdministratorFormStore;

	private readonly roleManipulationService: RoleManipulationService;
	private readonly translateService: TranslateService;

	constructor(
		public readonly model: Administrator,
		protected readonly options: FormObjectOptions,
		protected readonly injector: Injector,
	) {
		super(model, options);

		this.roleManipulationService = this.injector.get(RoleManipulationService);
		this.translateService = this.injector.get(TranslateService);
	}

	public validators: Record<string, ValidatorFn | Array<ValidatorFn>> = {
		name: Validators.required,
		email: [Validators.required, Validators.email],
		contact: [Validators.email],
		roles: (control) =>
			isNumberInRange(
				null,
				{
					min: 1,
					max: Object.values(UserRole).length,
					message: this.translateService.instant('administrator.role.errors.required'),
				},
				control.value.length,
			),
	};

	@BuildRelationshipFormObject('roles')
	public buildRolesFormObject(role: Role, options: FormObjectOptions): RoleFormObject {
		return new RoleFormObject(role, options, this.injector, this.model);
	}

	@BuildControl('roles')
	public buildRoles(roles: Array<Role> = [], options: FormObjectOptions): ExtendedFormArray {
		const validators: ValidatorFn | Array<ValidatorFn> = this.getValidators('roles');
		const propertyOptions = this.hasManyProperties.get('roles');

		const joinedRoles = this.roleManipulationService.joinRolesPerUserRole(roles);
		const roleForms = joinedRoles.map((role) => this.buildRolesFormObject(role, options).form);

		return new ExtendedFormArray(roleForms, validators, null, propertyOptions);
	}

	protected save(model: Administrator): Observable<Administrator> {
		return model.save().pipe(
			switchMap((savedModel) => {
				if (savedModel.hasRelationshipUrls(['roles'])) {
					return of(model);
				}

				return model.refetch();
			}),
		);
	}

	protected afterSave(
		administrator: Administrator,
		administratorForm: AdministratorFormStore,
	): Observable<Administrator> {
		return this.saveRelationships(administratorForm).pipe(map(() => administrator));
	}

	private saveRelationships(form: AdministratorFormStore): Observable<unknown> {
		const relationshipChanges: Array<Observable<unknown>> = [];

		const roleChanges = this.roleManipulationService.getRelationshipChanges(
			form.roleForms,
			form.model,
		);

		const delete$ = roleChanges.deletes.length ? combineLatest(roleChanges.deletes) : of(null);
		const create$ = roleChanges.adds.length ? combineLatest(roleChanges.adds) : of(null);
		const roleSave$ = delete$.pipe(switchMap(() => create$));

		relationshipChanges.push(roleSave$);

		return combineLatest(relationshipChanges);
	}
}
