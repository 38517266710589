/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { INutrientData } from '../custom-types/nutrient-data.type';
import { IDictionary } from '../interfaces/dictionary.interface';

export enum NutrientCode {
	ALCOHOL = 'ALCOHOL',
	CALCIUM = 'CALCIUM',
	CALORIES = 'CALORIES',
	CARBOHYDRATES = 'CARBOHYDRATES',
	CHOLESTEROL = 'CHOLESTEROL',
	DIETARY_FIBER = 'DIETARY_FIBER',
	FAT = 'FAT',
	IRON = 'IRON',
	MAGNESIUM = 'MAGNESIUM',
	OMEGA_3_FATTY_ACIDS = 'OMEGA_3_FATTY_ACIDS',
	ORGANIC_ACIDS = 'ORGANIC_ACIDS',
	POTASSIUM = 'POTASSIUM',
	PROTEIN = 'PROTEIN',
	SALT = 'SALT',
	SATURATED_FAT = 'SATURATED_FAT',
	SELENIUM = 'SELENIUM',
	SODIUM = 'SODIUM',
	SUGAR = 'SUGAR',
	UNSATURATED_FATTY_ACIDS = 'UNSATURATED_FATTY_ACIDS',
	VITAMIN_A = 'VITAMIN_A',
	VITAMIN_B1 = 'VITAMIN_B1',
	VITAMIN_B12 = 'VITAMIN_B12',
	VITAMIN_B2 = 'VITAMIN_B2',
	VITAMIN_B3 = 'VITAMIN_B3',
	VITAMIN_B5 = 'VITAMIN_B5',
	VITAMIN_B6 = 'VITAMIN_B6',
	VITAMIN_B7 = 'VITAMIN_B7',
	VITAMIN_B9 = 'VITAMIN_B9',
	FOLIC_ACID = 'FOLIC_ACID',
	VITAMIN_C = 'VITAMIN_C',
	VITAMIN_D = 'VITAMIN_D',
	VITAMIN_E = 'VITAMIN_E',
	VITAMIN_K = 'VITAMIN_K',
	WATER = 'WATER',
	ZINC = 'ZINC',
}

export enum NutrientType {
	MACRONUTRIENT = 'MACRONUTRIENT',
	MICRONUTRIENT_MINERAL = 'MICRONUTRIENT_MINERAL',
	MICRONUTRIENT_VITAMIN = 'MICRONUTRIENT_VITAMIN',
}

export enum NutrientTypeHal {
	MINERAL = 'MINERAL',
	VITAMIN = 'VITAMIN',
}

export const nutrientCodeData: IDictionary<INutrientData> = {
	[NutrientCode.ALCOHOL]: {
		id: NutrientCode.ALCOHOL,
		type: NutrientType.MACRONUTRIENT,
		translateKey: 'nutrient.alcohol',
		warningTranslationKey: null,
	},
	[NutrientCode.CALCIUM]: {
		id: NutrientCode.CALCIUM,
		type: NutrientType.MICRONUTRIENT_MINERAL,
		translateKey: 'mineral.calcium',
		warningTranslationKey: null,
	},
	[NutrientCode.CALORIES]: {
		id: NutrientCode.CALORIES,
		type: NutrientType.MACRONUTRIENT,
		translateKey: 'nutrient.calories',
		warningTranslationKey: null,
	},
	[NutrientCode.CARBOHYDRATES]: {
		id: NutrientCode.CARBOHYDRATES,
		type: NutrientType.MACRONUTRIENT,
		translateKey: 'nutrient.carbohydrates',
		warningTranslationKey: null,
	},
	[NutrientCode.CHOLESTEROL]: {
		id: NutrientCode.CHOLESTEROL,
		type: NutrientType.MACRONUTRIENT,
		translateKey: 'nutrient.cholesterol',
		warningTranslationKey: null,
	},
	[NutrientCode.DIETARY_FIBER]: {
		id: NutrientCode.DIETARY_FIBER,
		type: NutrientType.MACRONUTRIENT,
		translateKey: 'nutrient.dietaryFibers',
		warningTranslationKey: null,
	},
	[NutrientCode.FAT]: {
		id: NutrientCode.FAT,
		type: NutrientType.MACRONUTRIENT,
		translateKey: 'nutrient.fat',
		warningTranslationKey: 'qualityIndicator.hover.fat',
	},
	[NutrientCode.IRON]: {
		id: NutrientCode.IRON,
		type: NutrientType.MICRONUTRIENT_MINERAL,
		translateKey: 'mineral.iron',
		warningTranslationKey: null,
	},
	[NutrientCode.MAGNESIUM]: {
		id: NutrientCode.MAGNESIUM,
		type: NutrientType.MICRONUTRIENT_MINERAL,
		translateKey: 'mineral.magnesium',
		warningTranslationKey: null,
	},
	[NutrientCode.OMEGA_3_FATTY_ACIDS]: {
		id: NutrientCode.OMEGA_3_FATTY_ACIDS,
		type: NutrientType.MACRONUTRIENT,
		translateKey: 'nutrient.omega3FattyAcids',
		warningTranslationKey: null,
	},
	[NutrientCode.ORGANIC_ACIDS]: {
		id: NutrientCode.ORGANIC_ACIDS,
		type: NutrientType.MACRONUTRIENT,
		translateKey: 'nutrient.organicAcids',
		warningTranslationKey: null,
	},
	[NutrientCode.POTASSIUM]: {
		id: NutrientCode.POTASSIUM,
		type: NutrientType.MICRONUTRIENT_MINERAL,
		translateKey: 'mineral.potassium',
		warningTranslationKey: null,
	},
	[NutrientCode.PROTEIN]: {
		id: NutrientCode.PROTEIN,
		type: NutrientType.MACRONUTRIENT,
		translateKey: 'nutrient.protein',
		warningTranslationKey: 'qualityIndicator.hover.protein',
	},
	[NutrientCode.SALT]: {
		id: NutrientCode.SALT,
		type: NutrientType.MICRONUTRIENT_MINERAL,
		translateKey: 'mineral.salt',
		warningTranslationKey: null,
	},
	[NutrientCode.SATURATED_FAT]: {
		id: NutrientCode.SATURATED_FAT,
		type: NutrientType.MACRONUTRIENT,
		translateKey: 'nutrient.saturatedFat',
		warningTranslationKey: null,
	},
	[NutrientCode.SELENIUM]: {
		id: NutrientCode.SELENIUM,
		type: NutrientType.MICRONUTRIENT_MINERAL,
		translateKey: 'mineral.selenium',
		warningTranslationKey: null,
	},
	[NutrientCode.SODIUM]: {
		id: NutrientCode.SODIUM,
		type: NutrientType.MICRONUTRIENT_MINERAL,
		translateKey: 'mineral.sodium',
		warningTranslationKey: 'qualityIndicator.hover.sodium',
	},
	[NutrientCode.SUGAR]: {
		id: NutrientCode.SUGAR,
		type: NutrientType.MACRONUTRIENT,
		translateKey: 'nutrient.sugar',
		warningTranslationKey: null,
	},
	[NutrientCode.UNSATURATED_FATTY_ACIDS]: {
		id: NutrientCode.UNSATURATED_FATTY_ACIDS,
		type: NutrientType.MACRONUTRIENT,
		translateKey: 'nutrient.unsaturatedFattyAcids',
		warningTranslationKey: null,
	},
	[NutrientCode.VITAMIN_A]: {
		id: NutrientCode.VITAMIN_A,
		type: NutrientType.MICRONUTRIENT_VITAMIN,
		translateKey: 'vitamin.vitaminA',
		warningTranslationKey: null,
	},
	[NutrientCode.VITAMIN_B1]: {
		id: NutrientCode.VITAMIN_B1,
		type: NutrientType.MICRONUTRIENT_VITAMIN,
		translateKey: 'vitamin.thiamin',
		warningTranslationKey: null,
	},
	[NutrientCode.VITAMIN_B12]: {
		id: NutrientCode.VITAMIN_B12,
		type: NutrientType.MICRONUTRIENT_VITAMIN,
		translateKey: 'vitamin.vitaminB12',
		warningTranslationKey: null,
	},
	[NutrientCode.VITAMIN_B2]: {
		id: NutrientCode.VITAMIN_B2,
		type: NutrientType.MICRONUTRIENT_VITAMIN,
		translateKey: 'vitamin.riboflavin',
		warningTranslationKey: null,
	},
	[NutrientCode.VITAMIN_B3]: {
		id: NutrientCode.VITAMIN_B3,
		type: NutrientType.MICRONUTRIENT_VITAMIN,
		translateKey: 'vitamin.niacin',
		warningTranslationKey: null,
	},
	[NutrientCode.VITAMIN_B5]: {
		id: NutrientCode.VITAMIN_B5,
		type: NutrientType.MICRONUTRIENT_VITAMIN,
		translateKey: 'vitamin.pantothenicAcid',
		warningTranslationKey: null,
	},
	[NutrientCode.VITAMIN_B6]: {
		id: NutrientCode.VITAMIN_B6,
		type: NutrientType.MICRONUTRIENT_VITAMIN,
		translateKey: 'vitamin.vitaminB6',
		warningTranslationKey: null,
	},
	[NutrientCode.VITAMIN_B7]: {
		id: NutrientCode.VITAMIN_B7,
		type: NutrientType.MICRONUTRIENT_VITAMIN,
		translateKey: 'vitamin.biotin',
		warningTranslationKey: null,
	},
	[NutrientCode.VITAMIN_B9]: {
		id: NutrientCode.VITAMIN_B9,
		type: NutrientType.MICRONUTRIENT_VITAMIN,
		translateKey: 'vitamin.folicAcid',
		warningTranslationKey: null,
	},
	[NutrientCode.FOLIC_ACID]: {
		id: NutrientCode.FOLIC_ACID,
		type: NutrientType.MICRONUTRIENT_VITAMIN,
		translateKey: 'vitamin.folicAcid',
		warningTranslationKey: null,
	},
	[NutrientCode.VITAMIN_C]: {
		id: NutrientCode.VITAMIN_C,
		type: NutrientType.MICRONUTRIENT_VITAMIN,
		translateKey: 'vitamin.vitaminC',
		warningTranslationKey: null,
	},
	[NutrientCode.VITAMIN_D]: {
		id: NutrientCode.VITAMIN_D,
		type: NutrientType.MICRONUTRIENT_VITAMIN,
		translateKey: 'vitamin.vitaminD',
		warningTranslationKey: null,
	},
	[NutrientCode.VITAMIN_E]: {
		id: NutrientCode.VITAMIN_E,
		type: NutrientType.MICRONUTRIENT_VITAMIN,
		translateKey: 'vitamin.vitaminE',
		warningTranslationKey: null,
	},
	[NutrientCode.VITAMIN_K]: {
		id: NutrientCode.VITAMIN_K,
		type: NutrientType.MICRONUTRIENT_VITAMIN,
		translateKey: 'vitamin.vitaminK',
		warningTranslationKey: null,
	},
	[NutrientCode.WATER]: {
		id: NutrientCode.WATER,
		type: NutrientType.MACRONUTRIENT,
		translateKey: 'nutrient.water',
		warningTranslationKey: null,
	},
	[NutrientCode.ZINC]: {
		id: NutrientCode.ZINC,
		type: NutrientType.MICRONUTRIENT_MINERAL,
		translateKey: 'mineral.zinc',
		warningTranslationKey: null,
	},
};

export const qualityIndicatorsData = {
	[NutrientCode.FAT]: nutrientCodeData[NutrientCode.FAT],
	[NutrientCode.PROTEIN]: nutrientCodeData[NutrientCode.PROTEIN],
	[NutrientCode.SODIUM]: nutrientCodeData[NutrientCode.SODIUM],
};

export const deprecatedNutrients: Array<NutrientCode> = [NutrientCode.CHOLESTEROL];
