/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { sortDirectionData } from '../../../enums/sort-direction.enum';
import { IDictionary } from '../../../interfaces/dictionary.interface';

export function createQueryParams(
	page: IDictionary<any>,
	filter: IDictionary<any>,
	sort: IDictionary<any>,
): IDictionary<any> {
	const queryParams: IDictionary<any> = { ...filter };

	if (page.number) {
		queryParams.page = page.number;
	}

	if (page.size) {
		queryParams.size = page.size;
	}

	if (sort && sort.fieldName) {
		const sortName: string = sort.fieldValue || sort.fieldName;
		queryParams.sort = `${sortName},${sortDirectionData[sort.direction].value}`;
	}

	return queryParams;
}
