/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable, inject } from '@angular/core';

import { AuthService } from 'app/services/auth/auth.service';
import { SSO_PROVIDERS_DATA_TOKEN, SsoProvider } from '../../enums/sso-provider.enum';

@Injectable()
export class SsoLandingPageGuard {
	private readonly authService = inject(AuthService);
	private readonly ssoProvidersData = inject(SSO_PROVIDERS_DATA_TOKEN);

	public canActivate(): boolean {
		const availableProviders = Object.keys(this.ssoProvidersData).filter(
			(provider) => this.ssoProvidersData[provider].available,
		) as Array<SsoProvider>;

		if (!availableProviders.length) {
			throw new Error('No providers available');
		}

		if (availableProviders.length === 1) {
			this.authService.goToLogin(this.authService.redirectUrlAfterLogin, availableProviders[0]);
			return false;
		}

		return true;
	}
}
