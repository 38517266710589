/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiModelConfig, JsonApiModel, Attribute, BelongsTo, HasMany } from 'angular2-jsonapi';

import { IngredientUnit } from '../enums/ingredient-unit.enum';
import { RecipeNutritionInfo } from './recipe-nutrition-info.model';

import { ModelMetadata } from 'app/classes/model-metadata/model-metadata';
import { Nutrient } from 'app/models/nutrient.model';
import { NutritionClaimContent } from 'app/models/nutrition-claim-content.model';
import { NutritionClaimLimitType } from 'app/enums/nutrition-claim-limit-type.enum';
import { NutritionClaimGuidelineSource } from 'app/enums/nutrition-claim-guideline-source.enum';
import { HealthClaimGuideline } from 'app/models/health-claim-guideline.model';
import { NutritionalClaimType } from 'app/enums/nutritional-claim-type.enum';

@JsonApiModelConfig({
	type: 'nutritionClaimGuidelines',
	meta: ModelMetadata,
})
export class NutritionClaimGuideline extends JsonApiModel {
	public config = null;

	@Attribute()
	public limitType: NutritionClaimLimitType;

	@Attribute()
	public referenceUnit: IngredientUnit;

	@Attribute()
	public referenceValue: any;

	@Attribute()
	public source: NutritionClaimGuidelineSource;

	@Attribute()
	public unit: IngredientUnit;

	@Attribute()
	public value: any;

	@Attribute()
	public type: NutritionalClaimType;

	@BelongsTo()
	public recipeNutritionInfo: RecipeNutritionInfo;

	@BelongsTo()
	public nutrient: Nutrient;

	@BelongsTo()
	public nutritionClaimContent: NutritionClaimContent;

	@HasMany()
	public healthClaimGuidelines: Array<HealthClaimGuideline>;
}
