/**
 * Copyright (C) 2021 - 2021 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { isNumberInRange } from 'app/validators/is-number-in-range/is-number-in-range.validator';
import { requiredForApproving } from 'app/validators/required-for-approving/required-for-approving.validator';
import { languageCode } from 'app/validators/language-code/language-code.validator';
import { isInteger } from 'app/validators/is-integer/is-integer.validator';

export { isNumberInRange, isInteger, requiredForApproving, languageCode };
