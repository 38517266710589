/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'highlightMatch' })
export class HighlightMatchPipe implements PipeTransform {
	public transform(value: string, expression: string): string {
		if (value && expression) {
			const wordExpr = new RegExp(this.escapeRegExp(expression), 'gi');

			return value.replace(wordExpr, (match) => `<span class="matched">${match}</span>`);
		}

		return value;
	}

	private escapeRegExp(s) {
		return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
	}
}
