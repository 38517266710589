/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { environment } from '../../../../environments/environment';

export function isProduction(): boolean {
	return environment.production;
}
