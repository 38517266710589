/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { SortDirection } from 'app/enums/sort-direction.enum';
import { ISortParam } from 'app/components/list-view/interfaces/sort-param.interface';

export function showSortingArrow(field: string, currentSortParam: ISortParam): string {
	if (!currentSortParam) {
		return '';
	}

	if (currentSortParam.fieldName !== field) {
		return '';
	}

	let sortingClass: string;
	switch (currentSortParam.direction) {
		case SortDirection.ASC:
			sortingClass = 'sort-asc';
			break;
		case SortDirection.DESC:
			sortingClass = 'sort-desc';
			break;
		default:
			sortingClass = '';
	}

	return sortingClass;
}
