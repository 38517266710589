/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export function languageCode(formControl: UntypedFormControl | UntypedFormGroup) {
	const fieldValue: string = formControl.value;

	const isValid = /[a-z]{2}_[A-Z]{2}/g.test(fieldValue);

	return isValid
		? null
		: {
				invalidLanguageCode: Boolean(fieldValue) && !isValid,
		  };
}
