/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';

import { ModelMetadata } from '../classes/model-metadata/model-metadata';
import { IngredientUnit } from '../enums/ingredient-unit.enum';
import { deprecatedNutrients } from '../enums/nutrient-code.enum';
import { contains } from '../utils/helpers/helpers';

@JsonApiModelConfig({
	type: 'nutrients',
	modelEndpointUrl: 'nutrient',
	meta: ModelMetadata,
})
export class Nutrient extends JsonApiModel {
	public config = null;

	@Attribute()
	public code: string;

	@Attribute()
	public name: string;

	@Attribute()
	public synonym: string;

	@Attribute()
	public unit: IngredientUnit;

	public get isDeprecated(): boolean {
		return contains(deprecatedNutrients, this.code);
	}
}
