/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { AbstractControl, ValidationErrors } from '@angular/forms';
import { nonDomainRelatedRoles, UserRole } from '../../../enums/user-role.enum';
import { isNumberInRange } from '../../../validators/validators';

export function roleOrganizationValidator(
	control: AbstractControl,
	message: string,
): ValidationErrors | null {
	if (!control?.parent) {
		return null;
	}

	const selectedRole: UserRole = control.parent.get('role').value;
	const isNonDomainRelatedRole = nonDomainRelatedRoles.includes(selectedRole);

	if (!selectedRole || isNonDomainRelatedRole) {
		return null;
	}

	return isNumberInRange(
		null,
		{
			message,
			min: 1,
			max: null,
		},
		control.value.length,
	);
}
