/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { AccessDeniedComponent } from 'app/components/access-denied/access-denied.component';

@NgModule({
	imports: [CommonModule, TranslateModule],
	declarations: [AccessDeniedComponent],
	exports: [AccessDeniedComponent],
})
export class AccessDeniedModule {}
