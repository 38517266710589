<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
	<p class="mat-dialog-subtitle">
		{{ data.content }}
	</p>

	<p class="mat-dialog-select-label">
		{{ data.extra?.reasonSelectLabel || 'comment.flag.subtitle' | translate }}
	</p>

	<form [formGroup]="form">
		<select-field
			*ngIf="isSingleSelect; else multipleSelection"
			[optionsFn]="reportReasonOptionsFn"
			placeholder="{{ 'modal.pleaseSelectReason' | translate }}"
			[searchEnabled]="false"
			(selected)="onReportReasonSelect($event)"
		></select-field>
		<ng-template #multipleSelection>
			<checkbox-group-field
				formControlName="reportReasons"
				[items]="reportReasons"
			></checkbox-group-field>
		</ng-template>

		<p class="mat-dialog-subtitle">
			{{
				data.subtitle !== undefined
					? data.subtitle
					: ('reportedContent.dialog.subtitle' | translate)
			}}
		</p>

		<textarea-field className="box" formControlName="comment" placeholder="Reason" rows="6">
		</textarea-field>
	</form>
</mat-dialog-content>

<confirmation-actions
	[data]="data"
	[saveDisabled]="!isSuccessButtonEnabled"
	(cancel)="onCancelClick()"
	(save)="onSaveClick()"
></confirmation-actions>
