/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { UntypedFormArray, UntypedFormBuilder, ValidatorFn, UntypedFormControl } from '@angular/forms';

export function generateDefaultValueFormArray(
	fb: UntypedFormBuilder,
	arrayLength: number,
	defaultValue: any,
	controlsValidator?: ValidatorFn,
): UntypedFormArray {
	const controlArray: Array<UntypedFormControl> = new Array<UntypedFormControl>();
	for (let i = 0; i < arrayLength; i++) {
		controlArray.push(fb.control(defaultValue, controlsValidator));
	}
	return fb.array(controlArray);
}
