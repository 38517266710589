/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { UntypedFormGroup } from '@angular/forms';

import { isArray } from '../is-array/is-array';

export function extractFormErrors(
	form: UntypedFormGroup,
	errors: Array<any> = [],
	stepCounter = 0,
	maxSteps = 500,
): Array<any> {
	stepCounter++;
	if (stepCounter >= maxSteps) {
		return errors;
	}

	if (form.errors) {
		const errorObjects: Array<string> = Object.keys(form.errors).map((errorKey: string) => {
			const errorObject = form.errors[errorKey];

			if (errorObject) {
				return errorObject;
			}
		});

		errors.push(...errorObjects);
	}

	if (form.controls && isArray(form.controls)) {
		const formControls: Array<any> = <any>form.controls;

		formControls.forEach((formControl) => {
			const errorObjects: Array<Object> = extractFormErrors(formControl, [], stepCounter, maxSteps);

			errors.push(...errorObjects);
		});
	} else if (form.controls) {
		Object.keys(form.controls).forEach((formPropertyKey: string) => {
			const formControl: UntypedFormGroup = form.controls[formPropertyKey] as UntypedFormGroup;
			const errorObjects: Array<Object> = extractFormErrors(formControl, [], stepCounter, maxSteps);

			errors.push(...errorObjects);
		});
	}

	return errors;
}
