/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from '../../services/datastore/models/hal-datastore-model.model';

@ModelConfig({
	type: 'AttributeOptionDummyValue',
	endpoint: '',
})
export class AttributeOptionDummyValue extends HalDatastoreModel {}
