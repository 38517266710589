/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, BelongsTo, HasMany, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import {
	Attribute as FormAttribute,
	BelongsTo as FormBelongsTo,
	HasMany as FormHasMany,
} from 'ngx-form-object';
import { ModelMetadata } from '../classes/model-metadata/model-metadata';
import { AuthorType } from '../enums/author-type.enum';
import { CollectionType } from '../enums/collection-type.enum';
import { ContentStatus } from '../enums/content-status.enum';
import { DeviceCategoryEnum } from '../enums/device-category.enum';
import { ModelTypePrefix } from '../enums/model-type-prefix.enum';
import { IContentModel } from '../interfaces/content-model.interface';
import { IMutableHistory } from '../interfaces/mutable-history.interface';
import { HistoryItem } from '../models/history-item.model';
import { Recipe } from '../models/recipe.model';
import { Datastore } from '../services/datastore/datastore.service';
import { CollectionTranslation } from './collection-translation.model';
import { ConsumerProfile } from './consumer-profile.model';
import { Consumer } from './consumer.model';
import { CountryProfile } from './country-profile.model';
import { Domain } from './domain.model';
import { Media } from './media.model';
import { User } from './user.model';

type PossibleProfileType = CountryProfile | ConsumerProfile;

@JsonApiModelConfig({
	type: 'collections',
	meta: ModelMetadata,
})
export class Collection extends JsonApiModel implements IContentModel, IMutableHistory {
	public config = null;

	public modelTypePrefix: ModelTypePrefix = ModelTypePrefix.COLLECTION;

	@FormAttribute()
	public id: string;

	@Attribute()
	public contentCategories: Array<string>;

	@Attribute()
	@FormAttribute()
	public shortId: string;

	@Attribute()
	@FormAttribute()
	public type: AuthorType;

	@Attribute()
	@FormAttribute()
	public collectionType: CollectionType;

	@Attribute()
	@FormAttribute()
	public status: ContentStatus;

	@Attribute()
	public modifiedAt: Date;

	@Attribute()
	public createdAt: Date;

	@Attribute()
	@FormAttribute()
	public viewCount: number;

	@Attribute()
	@FormAttribute()
	public favouriteCount: number;

	@Attribute()
	@FormAttribute()
	public recipeCount: number;

	@HasMany()
	@FormHasMany()
	public collectionTranslations: Array<CollectionTranslation> = [];

	@BelongsTo()
	@FormBelongsTo()
	public defaultTranslation: CollectionTranslation;

	@HasMany()
	@FormHasMany()
	public profiles: Array<PossibleProfileType>;

	@HasMany()
	@FormHasMany()
	public recipes: Array<Recipe> = [];

	@HasMany()
	@FormHasMany()
	public recipeImages: Array<Media> = [];

	@HasMany()
	@FormHasMany()
	public historyItems: Array<HistoryItem>;

	@BelongsTo()
	@FormBelongsTo()
	public domain: Domain;

	@BelongsTo()
	@FormBelongsTo()
	public authorUser: User;

	@BelongsTo()
	@FormBelongsTo()
	public authorConsumer: Consumer;

	@BelongsTo()
	@FormBelongsTo()
	public coverImage: Media;

	@Attribute()
	@FormAttribute()
	public get contentCategory(): string {
		if (this.contentCategories && this.contentCategories.length) {
			return this.contentCategories[0];
		} else {
			return null;
		}
	}

	public set contentCategory(category: string) {
		this.contentCategories = [category];
	}

	public get deviceFamily(): string {
		// Philips collections should always have contentCategories, but just in case :)
		// Consumer collections can't be edited through AdminUI anyway
		return this.contentCategories.length ? this.contentCategories[0] : DeviceCategoryEnum.NO_DEVICE;
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
	private _assignedProfiles: Array<PossibleProfileType>;

	public constructor(
		private readonly datastore: Datastore,
		data?: any,
	) {
		super(datastore, data);

		this._assignedProfiles = this.getParsedProfiles(data);
	}

	public get assignedProfiles(): Array<PossibleProfileType> {
		return this._assignedProfiles;
	}

	public set assignedProfiles(assignedProfiles: Array<PossibleProfileType>) {
		this._assignedProfiles = assignedProfiles;
	}

	public translationsPropertyName = 'collectionTranslations';

	public get translations(): Array<CollectionTranslation> {
		return this.collectionTranslations;
	}

	private getParsedProfiles(rawResponse: any): Array<PossibleProfileType> {
		if (!rawResponse || !rawResponse.relationships) {
			return null;
		}

		const profiles: any = rawResponse.relationships.profiles;

		if (!profiles || !profiles.data) {
			return null;
		}

		const profileIds: Array<string> = profiles.data.map(
			(profile: PossibleProfileType) => profile.id,
		);

		const parsedProfiles: Array<any> = profiles.data
			.map((profile: PossibleProfileType) => {
				if (profile instanceof ConsumerProfile) {
					return this.datastore.peekRecord(ConsumerProfile, profile.id);
				} else {
					return this.datastore.peekRecord(CountryProfile, profile.id);
				}
			})
			.filter((profile) => profile);

		return parsedProfiles.length === profileIds.length ? parsedProfiles : null;
	}

	public get displayImage(): Media {
		return this.coverImage || this.firstRecipeCoverImage;
	}

	private get firstRecipeCoverImage(): Media {
		if (!this.recipeImages.length) {
			return null;
		}

		return this.recipeImages[0];
	}
}
