/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Countries, CountriesData } from 'countries-data';

const ihutCountryData: any = {
	IH: { name: { common: 'Philips IHUT' } },
	II: { name: { common: 'Philips Nederland (IHUT)' } },
	IJ: { name: { common: 'Philips 中国 (IHUT)' } },
	QM: { name: { common: 'Philips Austria (IHUT)' } },
};
export const countriesData: Countries = { ...CountriesData, ...ihutCountryData };
