/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
	public transform(value = '', numberOfCharacters: number): string {
		let suffix = '';

		if (value.length > numberOfCharacters) {
			suffix = '...';
		}

		return `${value.slice(0, numberOfCharacters)}${suffix}`;
	}
}
