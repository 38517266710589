/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiMetaModel } from '../models/json-api-meta.model';
import { ModelConfig } from '../interfaces/model-config.interface';
import { JSON_API_MODEL_CONFIG } from '../constants/json-api.constants';
import { setObjProperty } from '../helpers/class-metadata.helper';

export function JsonApiModelConfig(config: ModelConfig) {
	return function (target: any) {
		if (typeof config.meta === 'undefined' || config.meta == null) {
			config.meta = JsonApiMetaModel;
		}

		setObjProperty(target, JSON_API_MODEL_CONFIG, config);
	};
}
