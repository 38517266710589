/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { BelongsTo, Attribute as FormAttribute } from 'ngx-form-object';
import { Attribute, HasMany, HasOne, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { CommentStatusHal } from '../enums/comment-status.enum';
import { ContentType } from '../enums/content-type.hal.enum';
import { ProfileType } from '../enums/profile-type.enum';
import { ReportedContentStatus } from '../enums/reported-content-status.enum';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Article } from './article.model';
import { CommentResolvedStatus } from './comment-resolved-status.model';
import { Flag } from './flag.model';
import { Profile } from './profile.hal.model';
import { ReactionSum } from './reaction-sum.model';
import { Reaction } from './reaction.model';
import { Recipe } from './recipe.hal.model';

@ModelConfig({
	type: 'Comment',
})
export class Comment extends HalDatastoreModel {
	public static modelType = 'Comment';

	public get modelEndpoints(): ModelEndpoints {
		return {
			singleResourceEndpoint: this.datastore.rootApi.singleCommentEndpoint,
			collectionEndpoint:
				this.recipe || this.article
					? this.commentSavingEndpoint
					: this.datastore.rootApi.commentsCollectionEndpoint,
		};
	}

	private get commentSavingEndpoint(): string {
		return this.recipe
			? this.datastore.rootApi.recipeCommentsCollectionEndpoint
			: this.datastore.rootApi.articleCommentsCollectionEndpoint;
	}

	@Attribute()
	@FormAttribute()
	public text: string;

	@Attribute()
	@FormAttribute()
	public status: CommentStatusHal;

	@Attribute()
	@FormAttribute()
	public state: CommentResolvedStatus;

	@Attribute()
	public createdAt: Date;

	@Attribute()
	public modifiedAt: Date;

	@Attribute()
	public replyCount: number;

	@HasOne({
		propertyClass: Article,
		includeInPayload: true,
	})
	@BelongsTo()
	public article: Article;

	@HasOne({
		propertyClass: Recipe,
		includeInPayload: true,
	})
	@BelongsTo()
	public recipe: Recipe;

	@HasOne({
		propertyClass: Profile,
		includeInPayload: true,
	})
	@BelongsTo()
	public author: Profile;

	@HasOne({
		propertyClass: Profile,
		includeInPayload: true,
	})
	@BelongsTo()
	public contentPublisher: Profile;

	@HasOne({ propertyClass: Profile })
	@BelongsTo()
	public removedBy: Profile;

	@HasMany({
		itemsType: Flag,
	})
	public flags: Array<Flag>;

	@HasOne({
		propertyClass: ReactionSum,
	})
	public reactionSum: ReactionSum;

	@HasMany({
		itemsType: Reaction,
	})
	public reactionsManaged: Array<Reaction>;

	@HasMany({
		itemsType: Reaction,
	})
	public reactions: Array<Reaction>;

	public get managed(): boolean {
		return this.author.profileType === ProfileType.COUNTRY;
	}

	public get content(): Article | Recipe {
		return this.article || this.recipe;
	}

	public get contentType(): ContentType {
		return this.article ? ContentType.ARTICLE : ContentType.RECIPE;
	}

	public get isResolved(): boolean {
		return this.state === CommentResolvedStatus.RESOLVED;
	}

	public get isReported(): boolean {
		if (!this.flags) {
			return false;
		}

		return this.flags.some((flag: Flag) => flag.status === ReportedContentStatus.REPORTED);
	}

	public get hasManagedLike(): boolean {
		return Boolean(this.reactionsManaged?.length);
	}

	public get isReply(): boolean {
		return Boolean(this.links.parent);
	}
}
