/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { NavigationExtras } from '@angular/router';

export interface IRedirectUrlData {
	redirectUrl: string;
	options: NavigationExtras;
}

export function redirectUrlSplitter(fullRedirectUrl: string): IRedirectUrlData {
	const options: NavigationExtras = {};
	let redirectUrl = fullRedirectUrl;

	const splitUrl = fullRedirectUrl.split('?');
	if (splitUrl.length > 1) {
		redirectUrl = splitUrl[0];

		const parameters: Array<string> = splitUrl[1].split('&');
		options.queryParams = {};
		parameters.forEach((parameter: string) => {
			const parameterSplit = parameter.split('=');
			options.queryParams[parameterSplit[0]] = parameterSplit[1];
		});
	}

	return {
		redirectUrl,
		options,
	};
}
