<h2 data-e2e-test-id="dialog-title" mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
	<div *ngIf="data.isHtmlContent" [innerHTML]="data.content"></div>

	<div *ngIf="!data.isHtmlContent">
		{{ data.content }}
	</div>
</mat-dialog-content>

<confirmation-actions [data]="data" (cancel)="onCancelClick()" (save)="onSaveClick()">
</confirmation-actions>
