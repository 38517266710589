/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute } from 'ngx-form-object';
import { Attribute } from 'ngx-hal';
import { Language } from '../enums/language.enum';
import { TranslationStatusHal } from '../enums/translation-status.enum';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import {
	transformLanguageForHalApi,
	transformLanguageForJsonApi,
} from '../utils/helpers/language-transformer/language-transformer.helper';

export class Translation extends HalDatastoreModel {
	@Attribute({
		transformResponseValue: transformLanguageForJsonApi,
		transformBeforeSave: transformLanguageForHalApi,
	})
	@FormAttribute()
	public language: Language;

	@Attribute()
	@FormAttribute()
	public status: TranslationStatusHal;

	@Attribute()
	public modifiedAt: Date;

	@Attribute()
	public aiGenerated: boolean;

	public get isApproved(): boolean {
		return this.status === TranslationStatusHal.APPROVED;
	}
}
