/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';

import { Observable, throwError, of } from 'rxjs';

import { PopupDialogService } from '../popup-dialog/popup-dialog.service';
import { arrayBufferToText } from '../../utils/helpers/helpers';
import { HttpCode } from './../../enums/http-codes.enum';
import { ErrorModalComponent } from './../../components/error-modal/error-modal.component';
import { ConstantService } from './../constant/constant.service';
import { StorageService } from './../storage/storage.service';
import { BindCurrentContext } from '../../decorators/bind-current-context.decorator.ts/bind-current-context.decorator';
import { WINDOW_LOCATION_TOKEN } from '../window-location/window-location.service';

@Injectable({
	providedIn: 'root',
})
export class ErrorService {
	public constructor(
		private readonly dialog: PopupDialogService,
		private readonly translateService: TranslateService,
		private readonly router: Router,
		private readonly storageService: StorageService,
		private readonly constantService: ConstantService,
		@Inject(WINDOW_LOCATION_TOKEN) private readonly location: Location,
	) {}

	@BindCurrentContext()
	public genericErrorHandler(
		response: HttpErrorResponse,
		propagateError: any = true,
	): Observable<any> {
		const message: string | object = this.getErrorFromResponse(response);

		if (propagateError) {
			this.openErrorDialog(response, message);
			return throwError(response);
		} else {
			return this.openErrorDialog(response, message);
		}
	}

	private forbiddenRequestHandler(): void {
		this.router.navigate(['access-denied']);
	}

	private unauthorizedRequestHandler(): void {
		this.storageService.remove(this.constantService.authTokenLocalStorageKey);
		this.location.reload();
	}

	private notFoundRequestHandler(): void {
		this.router.navigate(['not-found']);
	}

	@BindCurrentContext()
	public globalErrorHandler(response: HttpErrorResponse): Observable<unknown> {
		if (this.isAccessDenied(response)) {
			this.forbiddenRequestHandler();
			return of(null);
		}

		if (!this.isTokenValid(response)) {
			this.unauthorizedRequestHandler();
			return of(null);
		}

		if (this.isUnknownRoute(response)) {
			this.notFoundRequestHandler();
			return of(null);
		}

		return throwError(response);
	}

	private isTokenValid(response: HttpErrorResponse = {} as HttpErrorResponse): boolean {
		return response.status !== HttpCode.CLIENT_401_UNAUTHORIZED;
	}

	private isAccessDenied(response: HttpErrorResponse = {} as HttpErrorResponse): boolean {
		return response.status === HttpCode.CLIENT_403_FORBIDDEN;
	}

	private isUnknownRoute(response: HttpErrorResponse = {} as HttpErrorResponse): boolean {
		return response.status === HttpCode.CLIENT_404_NOT_FOUND;
	}

	private openErrorDialog(response: HttpErrorResponse, message: string | object): Observable<any> {
		return this.dialog
			.setCancelButton({ label: this.translateService.instant('button.ok') })
			.setExtraOptions({
				response,
				errorMessage: message,
			})
			.open(ErrorModalComponent)
			.afterClosed();
	}

	private getErrorFromResponse(response: HttpErrorResponse): string | object {
		let message: string;

		if (response.error instanceof ErrorEvent) {
			message = response.error.message || response.error.error;
		} else {
			if (response.error) {
				message = response.error.message || response.error.error || response.error.detail;
			} else {
				message = response.message || message;
			}

			if (response.error && response.error.text) {
				try {
					const json = arrayBufferToText(response.error.text);
					message = json || message;
				} catch (err) {
					message = 'Unknown type of error. Please check with the developer.';
					console.error(message, response, err);
				}
			}
		}

		return message || this.translateService.instant('error.generic');
	}
}
