/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, BelongsTo, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import { Attribute as FormAttribute } from 'ngx-form-object';

import { Language } from '../enums/language.enum';
import { Ingredient } from '../models/ingredient.model';

@JsonApiModelConfig({
	type: 'ingredientTranslations',
})
export class IngredientTranslation extends JsonApiModel {
	public config = null;

	@FormAttribute()
	public id: string;

	@Attribute()
	@FormAttribute()
	public nameSingular: string;

	@Attribute()
	@FormAttribute()
	public namePlural: string;

	@Attribute()
	@FormAttribute()
	public displayName: string;

	@Attribute()
	@FormAttribute()
	public language: Language;

	@BelongsTo()
	public ingredient: Ingredient;
}
