/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, ModelConfig } from 'ngx-hal';
import { IngredientUnit } from '../enums/ingredient-unit.enum';
import { TemperatureUnit } from '../enums/temperature-unit.enum';
import { UnitSystemSlug } from '../enums/unit-system.enum';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';

@ModelConfig({
	type: 'UnitSystem',
	endpoint: 'UnitSystem',
})
export class UnitSystem extends HalDatastoreModel {
	@Attribute()
	public unitSystem: UnitSystemSlug;

	@Attribute()
	public units: Array<IngredientUnit | TemperatureUnit>;

	@Attribute()
	public otherUnits: Array<IngredientUnit>;

	@Attribute()
	public unitsOfMass: Array<IngredientUnit>;

	@Attribute()
	public unitsOfTemperature: Array<TemperatureUnit>;

	@Attribute()
	public unitsOfVolume: Array<IngredientUnit>;

	public get isMetric(): boolean {
		return this.unitSystem === UnitSystemSlug.METRIC;
	}

	public get isImperial(): boolean {
		return this.unitSystem === UnitSystemSlug.US;
	}

	public get ingredientUnits(): Array<IngredientUnit> {
		return [...(this.unitsOfMass || []), ...(this.unitsOfVolume || [])];
	}
}
