/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HasOne, ModelConfig } from 'ngx-hal';
import { EnergyNutrient } from '../classes/energy-nutrient.class';
import { NutrientValue } from '../classes/nutrient-value.class';
import { NutrientUnit } from '../enums/nutrient-unit.enum';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Nutrient } from './nutrient.hal.model';

@ModelConfig({
	type: 'RecipeNutritionInformation',
	endpoint: 'RecipeNutritionInformation',
})
export class RecipeNutritionInformation extends HalDatastoreModel {
	@Attribute({
		useClass: NutrientValue,
	})
	public dailyValue: NutrientValue;

	@Attribute({
		useClass: EnergyNutrient,
	})
	public energyValue: EnergyNutrient;

	@Attribute()
	public unit: NutrientUnit;

	@Attribute()
	public value: number;

	@HasOne({
		propertyClass: Nutrient,
	})
	public nutrient: Nutrient;
}
