/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CharacterLimitComponent } from './character-limit.component';

@NgModule({
	imports: [CommonModule],
	declarations: [CharacterLimitComponent],
	exports: [CharacterLimitComponent],
})
export class CharacterLimitModule {}
