/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { MetaModelType } from '../models/json-api-meta.model';

export interface ModelConfig<T = any> {
	type: string;
	apiVersion?: string;
	baseUrl?: string;
	modelEndpointUrl?: string;
	meta?: MetaModelType<T>;
}
