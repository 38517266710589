/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

function getTimezoneOffsetMinutes(): number {
	return new Date().getTimezoneOffset();
}

export function addTimezoneOffset(utcDateString: string): string {
	const utcDate: Date = new Date(utcDateString);
	const dateWithTimezoneOffset: Date = new Date(
		utcDate.valueOf() + getTimezoneOffsetMinutes() * 60 * 1000,
	);
	return dateWithTimezoneOffset.toISOString();
}

export function removeTimezoneOffset(dateWithOffsetString: string): string {
	const dateWithTimezoneOffset: Date = new Date(dateWithOffsetString);
	const utcDate: Date = new Date(
		dateWithTimezoneOffset.valueOf() - getTimezoneOffsetMinutes() * 60 * 1000,
	);
	return utcDate.toISOString();
}
