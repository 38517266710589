/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { capitalize } from 'app/utils/helpers/capitalize/capitalize.helper';

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {
	public transform(value: string): string {
		return capitalize(value);
	}
}
