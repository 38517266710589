/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum EnvironmentVariable {
	ENV = 'ENV',
	API_HOST = 'API_HOST',
	DAM_API_HOST = 'DAM_API_HOST',
	BRANCH_KEY = 'BRANCH_KEY',
	ASSETS_BASE_HREF = 'ASSETS_BASE_HREF',
	LOGIN_ROUTE_ENABLED = 'LOGIN_ROUTE_ENABLED',
	AWS_RUM_GUEST_ROLE_ARN = 'AWS_RUM_GUEST_ROLE_ARN',
	AWS_RUM_IDENTITY_POOL_ID = 'AWS_RUM_IDENTITY_POOL_ID',
	AWS_RUM_ENDPOINT = 'AWS_RUM_ENDPOINT',
	AWS_RUM_APPLICATION_ID = 'AWS_RUM_APPLICATION_ID',
	AWS_RUM_APPLICATION_REGION = 'AWS_RUM_APPLICATION_REGION',
}
