/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiModelConfig, JsonApiModel, Attribute, BelongsTo } from 'angular2-jsonapi';

import { Comment } from './comment.model';
import { PreparedMeal } from './prepared-meal.model';

import { Tip } from 'app/models/tip.model';
import { Recipe } from 'app/models/recipe.model';
import { ConsumerProfile } from 'app/models/consumer-profile.model';
import { Collection } from 'app/models/collection.model';
import { ModelMetadata } from 'app/classes/model-metadata/model-metadata';

@JsonApiModelConfig({
	type: 'reportedItems',
	meta: ModelMetadata,
})
export class ReportedItem extends JsonApiModel {
	@Attribute({
		dataType: Date,
	})
	public reportedAt: Date;

	@Attribute()
	public reporterCount: number;

	@Attribute()
	public reportedCountriesCount: number;

	@Attribute()
	public reportedLanguagesCount: number;

	@Attribute()
	public type: string;

	@BelongsTo()
	public recipe: Recipe;

	@BelongsTo()
	public tip: Tip;

	@BelongsTo()
	public collection: Collection;

	@BelongsTo()
	public comment: Comment;

	@BelongsTo()
	public consumerProfile: ConsumerProfile;

	@BelongsTo()
	public preparedMeal: PreparedMeal;
}
