/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { UntypedFormGroup } from '@angular/forms';

import { extractFormErrors } from './extract-form-errors.helper';
export function extractErrorMessages(
	form: UntypedFormGroup,
	stepCounter = 0,
	maxSteps = 500,
): Array<string> {
	const errorMessages = extractFormErrors(form, [], stepCounter, maxSteps).map(
		({ message }) => message,
	);

	const uniqueErrors: Set<string> = new Set(errorMessages);
	return Array.from(uniqueErrors).filter((message) => Boolean(message));
}
