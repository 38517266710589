/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute } from 'ngx-form-object';
import { Attribute, HeaderAttribute, ModelConfig } from 'ngx-hal';
import { Language } from '../enums/language.enum';
import {
	transformLanguageForHalApi,
	transformLanguageForJsonApi,
} from '../utils/helpers/language-transformer/language-transformer.helper';
import { Translation } from './translation.model';

@ModelConfig({
	type: 'ContentContainerTranslation',
})
export class ContentContainerTranslation extends Translation {
	@Attribute()
	@FormAttribute()
	public title: string;

	@Attribute()
	@FormAttribute()
	public description: string;

	@HeaderAttribute({
		transformResponseValue: transformLanguageForJsonApi,
		transformBeforeSave: transformLanguageForHalApi,
		externalName: 'Content-Language',
	})
	// Use only for creation of the ContentContainerTranslation resource
	public initialLanguage: Language;
}
