/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { InjectionToken } from '@angular/core';

export interface ISsoProviderData {
	name: string;
	iconSrc?: string;
	available: boolean;
	loginUrl: string;
	apiv2: boolean;
}

export enum SsoProvider {
	VERSUNI = 'Versuni',
}

export const SSO_PROVIDERS_DATA_TOKEN = new InjectionToken<Record<SsoProvider, ISsoProviderData>>(
	'ssoProvidersData',
);

export const ssoProvidersData: Record<SsoProvider, ISsoProviderData> = {
	[SsoProvider.VERSUNI]: {
		name: 'Versuni',
		iconSrc: './assets/img/versuni-logo.svg',
		available: true,
		apiv2: true,
		loginUrl: 'login/saml2/authenticate/versuni',
	},
};
