/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Component, Inject, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { PopupDialogComponent } from 'app/components/popup-dialog/popup-dialog.component';
import { IDialogData } from 'app/components/popup-dialog/dialog-options.interface';

@Component({
	selector: 'sensitive-fields-checking-modal',
	templateUrl: './sensitive-fields-checking-modal.component.html',
	styleUrls: ['./sensitive-fields-checking-modal.component.scss'],
})
export class SensitiveFieldsCheckingModalComponent extends PopupDialogComponent {
	public constructor(
		public dialogRef: MatDialogRef<PopupDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IDialogData,
		protected renderer: Renderer2,
	) {
		super(dialogRef, data, renderer);
	}

	protected get callCancelOnKeypressEnter(): boolean {
		return true;
	}
}
