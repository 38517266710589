/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'findBy' })
export class FindBy implements PipeTransform {
	public transform(items: Array<Object> = [], propertyName: string, propertyValue: any): object {
		const foundItem = items.find((item: object) => item[propertyName] === propertyValue);
		return foundItem || {};
	}
}
