/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Language } from '../enums/language.enum';
import { transformLanguageForHalApi } from '../utils/helpers/language-transformer/language-transformer.helper';
import { IAppConfig, APP_CONFIG } from '../app.config';

@Injectable()
export class AcceptLanguageInterceptor implements HttpInterceptor {
	constructor(@Inject(APP_CONFIG) private readonly config: IAppConfig) {}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (
			request.url.includes(this.config.apiv2.host) &&
			!request.url.includes(this.config.api.endpoint) &&
			!request.headers.has('Accept-Language')
		) {
			return next.handle(
				request.clone({
					setHeaders: { 'Accept-Language': transformLanguageForHalApi(Language.ENGLISH) },
				}),
			);
		}
		return next.handle(request);
	}
}
