/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum ElectricSystem {
	'110V' = '110V',
	'220V' = '220V',
}

export const electricSystemData = {
	[ElectricSystem['110V']]: {
		id: ElectricSystem['110V'],
		label: '110V',
		translateKey: 'electricSystem.110V.longLabel',
		translateKeyShortLabel: 'electricSystem.110V.shortLabel',
	},
	[ElectricSystem['220V']]: {
		id: ElectricSystem['220V'],
		label: '220V',
		translateKey: 'electricSystem.220V.longLabel',
		translateKeyShortLabel: 'electricSystem.220V.shortLabel',
	},
};
