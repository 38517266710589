/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

// Note: (hardcoded) IDs are used in permission interfaces, i.e. in recipe-permissions.service
export enum UserRole {
	COMMUNITY_MANAGER = 'COMMUNITY_MANAGER',
	CONTENT_MANAGER = 'CONTENT_MANAGER',
	GLOBAL_CONTENT_MANAGER = 'GLOBAL_CONTENT_MANAGER',
	INGREDIENT_MANAGER = 'INGREDIENT_MANAGER',
	READ_ONLY = 'READ_ONLY',
	SYSTEM_ADMINISTRATOR = 'SYSTEM_ADMIN',
}

export const domainRelatedRoles = [UserRole.COMMUNITY_MANAGER, UserRole.CONTENT_MANAGER];

export const nonDomainRelatedRoles = [
	UserRole.GLOBAL_CONTENT_MANAGER,
	UserRole.INGREDIENT_MANAGER,
	UserRole.SYSTEM_ADMINISTRATOR,
	UserRole.READ_ONLY,
];

export const userRoleData = {
	[UserRole.COMMUNITY_MANAGER]: {
		id: UserRole.COMMUNITY_MANAGER,
		translationKey: 'role.communityManager',
	},
	[UserRole.CONTENT_MANAGER]: {
		id: UserRole.CONTENT_MANAGER,
		translationKey: 'role.contentManager',
	},
	[UserRole.GLOBAL_CONTENT_MANAGER]: {
		id: UserRole.GLOBAL_CONTENT_MANAGER,
		translationKey: 'role.globalContentManager',
	},
	[UserRole.INGREDIENT_MANAGER]: {
		id: UserRole.INGREDIENT_MANAGER,
		translationKey: 'role.ingredientManager',
	},
	[UserRole.READ_ONLY]: {
		id: UserRole.READ_ONLY,
		translationKey: 'role.readOnlyAccess',
	},
	[UserRole.SYSTEM_ADMINISTRATOR]: {
		id: UserRole.SYSTEM_ADMINISTRATOR,
		translationKey: 'role.systemAdministrator',
	},
};
