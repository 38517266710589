/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Language } from 'app/enums/language.enum';
import { Locale } from 'app/enums/locale.enum';

export function getTranslationForLanguage<T>(
	translations: Array<T>,
	language: Language | Locale,
	languageProperty = 'language',
	defaultLanguage: Language = Language.ENGLISH,
	skipDefault = false,
): T {
	let translation: T = translations.find((trans: T) => trans[languageProperty] === language);

	if (translation || skipDefault) {
		return translation;
	}

	const locale: string = language.split('_')[0];

	translation = translations.find((trans: T) => trans[languageProperty].split('_')[0] === locale);

	if (translation) {
		return translation;
	}

	translation = translations.find((trans: T) => trans[languageProperty] === defaultLanguage);

	return translation;
}
