/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { SimpleHalModel } from 'ngx-hal';
import { NutrientUnit } from '../enums/nutrient-unit.enum';

export class NutrientValue extends SimpleHalModel {
	public constructor(private readonly data: { unit: NutrientUnit; value: number } = {} as any) {
		super();
	}

	public get unit(): NutrientUnit {
		return this.data.unit;
	}

	public get value(): number {
		return this.data.value;
	}
}
