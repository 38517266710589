/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum ProductType {
	ACCESSORY = 'ACCESSORY',
	DEVICE = 'DEVICE',
	TOOL = 'TOOL',
}

export interface IProductTypeOptions {
	propertyName: string;
}

export const productTypeOptions: Record<ProductType, IProductTypeOptions> = {
	[ProductType.ACCESSORY]: {
		propertyName: 'accessory',
	},
	[ProductType.DEVICE]: {
		propertyName: 'device',
	},
	[ProductType.TOOL]: {
		propertyName: 'tool',
	},
};
