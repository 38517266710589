/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { CustomHeader } from '../enums/custom-header.enum';

interface IRequestRegexExpressionInfo {
	method: string;
	expression: RegExp;
}

@Injectable()
export class CacheBustInterceptor implements HttpInterceptor {
	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let requestToTrigger: HttpRequest<any> = request;

		const hasCacheBustHeader: boolean = request.headers.has(CustomHeader.CACHE_BUST);

		const shouldCacheBustRequest: boolean =
			hasCacheBustHeader ||
			requestsToCacheBust.some(
				(requestToCacheBust: IRequestRegexExpressionInfo) =>
					request.method === requestToCacheBust.method &&
					requestToCacheBust.expression.test(request.url),
			);

		if (shouldCacheBustRequest) {
			requestToTrigger = request.clone({
				params: request.params.set('ts', `${new Date().getTime()}`),
				headers: request.headers.delete(CustomHeader.CACHE_BUST),
			});
		}
		return next.handle(requestToTrigger);
	}
}

const requestsToCacheBust: Array<IRequestRegexExpressionInfo> = [
	{
		method: 'GET',
		expression: new RegExp(/Publication/),
	},
	{
		method: 'GET',
		expression: new RegExp(/PushMessage/),
	},
];
