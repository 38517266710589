/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export function unescapeHtml(value = ''): string {
	if (typeof value !== 'string') {
		return value;
	}

	/* eslint-disable @typescript-eslint/quotes */
	return value
		.replace(/&quot;/g, '"')
		.replace(/&lt;/g, '<')
		.replace(/&gt;/g, '>')
		.replace(/&amp;/g, '&')
		.replace(/&apos;/g, "'");
	/* eslint-enable @typescript-eslint/quotes */
}
