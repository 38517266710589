/**
 * Copyright (C) 2021 - 2021 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class HttpService extends HttpClient {}
