/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject } from 'rxjs';
import { localeData } from '../../enums/enums-data/locale-data.enum';
import { Language, languageData } from '../../enums/language.enum';
import { Locale } from '../../enums/locale.enum';

@Injectable()
export class LocaleService {
	private readonly defaultLocale: Locale = Locale.ENGLISH;

	constructor(private readonly translateService: TranslateService) {}

	public initTranslations(locale: Locale = this.defaultLocale): void {
		const language: string = this.getLanguageForLocale(locale);
		this.translateService.setDefaultLang(language);
	}

	public setTranslation(locale: Locale): Observable<any> {
		const language: string = this.getLanguageForLocale(locale);
		return this.translateService.use(language);
	}

	public getTranslationForLocale(
		key: string,
		locale: Locale,
		defaultLocale?: Locale,
	): Observable<string> {
		const translation$: ReplaySubject<string> = new ReplaySubject(1);

		const keys: Array<string> = key.split('.');

		const defaultString = `missing translation for ${key}`;

		this.translateService.getTranslation(locale).subscribe((translations) => {
			const localeTranslation: string = this.getNestedObjectProperty(keys, translations);

			if (localeTranslation) {
				translation$.next(localeTranslation);
			} else if (!localeTranslation && defaultLocale) {
				this.translateService.getTranslation(defaultLocale).subscribe((defaultTranslations) => {
					const defaultLocaleTranslation: string =
						this.getNestedObjectProperty(keys, defaultTranslations) || defaultString;

					translation$.next(defaultLocaleTranslation);
				});
			} else {
				translation$.next(defaultString);
			}
		});
		return translation$;
	}

	public getTranslationForLang(
		key: string,
		language: Language,
		defaultLanguage: Language = Language.ENGLISH,
	): Observable<string> {
		const selectedLanguageData = languageData[language] || languageData[defaultLanguage];
		const locale: Locale = selectedLanguageData.locale;
		return this.getTranslationForLocale(key, locale);
	}

	private getLanguageForLocale(locale: Locale): Language {
		const localeDataObject = localeData[locale];

		if (localeDataObject) {
			return localeDataObject.language;
		}

		return localeData[this.defaultLocale].language;
	}

	private getNestedObjectProperty(keys: Array<string>, obj: object): any {
		return keys.reduce((result, value) => (result ? result[value] : result), obj);
	}
}
