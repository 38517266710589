/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Observable } from 'rxjs';

import { IDialogCloseData } from '../../../../components/popup-dialog/dialog-options.interface';
import { IReportedContentModalOptions } from '../../../../components/reported-content-modal/reported-content-modal-options.interface';
import { ReportedContentModalComponent } from '../../../../components/reported-content-modal/reported-content-modal.component';
import { PopupDialogService } from '../../../../services/popup-dialog/popup-dialog.service';

export function openReportModal(
	dialog: PopupDialogService,
	options: IReportedContentModalOptions,
): Observable<IDialogCloseData> {
	return dialog
		.setTitle(options.title)
		.setContent(options.content)
		.setSubtitle(options.subtitle)
		.setCancelButton({
			label: options.cancelButtonLabel,
			inverse: true,
		})
		.setSaveButton({
			label: options.saveButtonLabel,
			callback: options.onSuccess,
		})
		.setExtraOptions(options.extra)
		.open(ReportedContentModalComponent)
		.afterClosed();
}
