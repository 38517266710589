/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, ModelConfig, HasOne } from 'ngx-hal';
import { Attribute as FormAttribute } from 'ngx-form-object';

import { Translation } from './translation.model';
import { RecipeStep } from './recipe-step.model';

@ModelConfig({
	type: 'RecipeStepTranslation',
})
export class RecipeStepTranslation extends Translation {
	@Attribute()
	@FormAttribute()
	public description: string;

	@Attribute()
	@FormAttribute()
	public title = 'Default title';

	@HasOne({ propertyClass: 'RecipeStep' })
	public step: RecipeStep;
}
