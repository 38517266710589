/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Component, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';

import { DevLoginService } from 'app/services/dev-login/dev-login.service';
import { KeyCode } from 'app/enums/key-code.enum';

@Component({
	selector: 'dev-login',
	templateUrl: './dev-login.component.html',
	styleUrls: ['./dev-login.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevLoginComponent {
	public devLoginForm: UntypedFormGroup;
	private lastPressedKey: KeyCode;
	public showShortcuts = false;
	public showFastLogins = false;
	public isLoading = false;

	public constructor(
		private readonly devLoginService: DevLoginService,
		private readonly fb: UntypedFormBuilder,
	) {
		this.createForm();
	}

	private createForm() {
		this.devLoginForm = this.fb.group({
			username: ['', Validators.required],
			password: ['', Validators.required],
		});
	}

	@HostListener('document:keypress', ['$event'])
	public handleLoginShortcuts(event: KeyboardEvent) {
		const pressedKey: number = event.keyCode;

		if (this.lastPressedKey === KeyCode.S && pressedKey === KeyCode.S) {
			this.showShortcuts = true;
		} else if (this.lastPressedKey === KeyCode.S && pressedKey === KeyCode.L) {
			this.showFastLogins = true;
		}

		this.lastPressedKey = pressedKey;

		setTimeout(() => {
			this.lastPressedKey = undefined;
		}, 1000);
	}

	public onSubmit() {
		const devLoginModel = this.devLoginForm.value;
		this.isLoading = true;
		this.devLoginService
			.login(devLoginModel.username, devLoginModel.password)
			.pipe(
				finalize(() => {
					this.isLoading = false;
				}),
			)
			.subscribe();
	}
}
