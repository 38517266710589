/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HasOne, Link, ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Media } from './media.hal.model';
import { RootApi } from './root-api.model';

@ModelConfig({
	type: 'Tenant',
})
export class Tenant extends HalDatastoreModel {
	@Attribute()
	public name: string;

	@HasOne({ propertyClass: RootApi })
	public rootApi: RootApi;

	@HasOne({ propertyClass: Media })
	public icon: Media;

	@Link({
		externalName: 'tenantLogin',
	})
	public tenantLoginEndpoint: string;
}
