/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { unescapeHtml, transformObjectStrings } from '../utils/helpers/helpers';

@Injectable()
export class UnescapeCharactersInterceptor implements HttpInterceptor {
	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(
			request.clone({
				body: transformObjectStrings(request.body, unescapeHtml),
			}),
		);
	}
}
