/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HAS_MANY } from '../constants/json-api.constants';
import { getObjProperty } from '../helpers/class-metadata.helper';

export function HasMany(config: any = {}) {
	return function (target: any, propertyName: string | symbol) {
		const annotations = getObjProperty(target, HAS_MANY, []);

		annotations.push({
			propertyName,
			relationship: config.key || propertyName,
		});
	};
}
