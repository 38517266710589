/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum ImportResultType {
	SOURCE_MISSING = 'SOURCE_MISSING',
	SOURCE_TRANSLATION_CHANGED = 'SOURCE_TRANSLATION_CHANGED',
	SOURCE_TRANSLATION_MISSING = 'SOURCE_TRANSLATION_MISSING',
	TRANSLATION_IMPORTED = 'TRANSLATION_IMPORTED',
	TRANSLATION_UPDATED = 'TRANSLATION_UPDATED',
	INVALID_TRANSLATION_PROVIDED = 'INVALID_TRANSLATION_PROVIDED',
	TARGET_TRANSLATION_MISSING = 'TARGET_TRANSLATION_MISSING',
	TRANSLATION_STILL_MISSING = 'TRANSLATION_STILL_MISSING',
	INVALID_FILE_PROVIDED = 'INVALID_FILE_PROVIDED',
	LANGUAGE_NOT_SUPPORTED = 'LANGUAGE_NOT_SUPPORTED',
	NUMBER_STEP_TRANSLATION_DOES_NOT_MATCH_SOURCE = 'NUMBER_STEP_TRANSLATION_DOES_NOT_MATCH_SOURCE',
	TARGET_LANGUAGE_MATCHES_SOURCE_LANGUAGE = 'TARGET_LANGUAGE_MATCHES_SOURCE_LANGUAGE',
	USER_DOES_NOT_HAVE_PERMISSION_TO_IMPORT = 'USER_DOES_NOT_HAVE_PERMISSION_TO_IMPORT',
}

export const importResultTypeData = {
	[ImportResultType.SOURCE_MISSING]: {
		id: ImportResultType.SOURCE_MISSING,
		translationKey: 'importResultType.sourceMissing',
	},
	[ImportResultType.SOURCE_TRANSLATION_CHANGED]: {
		id: ImportResultType.SOURCE_TRANSLATION_CHANGED,
		translationKey: 'importResultType.sourceTranslationChanged',
	},
	[ImportResultType.SOURCE_TRANSLATION_MISSING]: {
		id: ImportResultType.SOURCE_TRANSLATION_MISSING,
		translationKey: 'importResultType.sourceTranslationMissing',
	},
	[ImportResultType.TRANSLATION_IMPORTED]: {
		id: ImportResultType.TRANSLATION_IMPORTED,
		translationKey: 'importResultType.translationImported',
	},
	[ImportResultType.TRANSLATION_UPDATED]: {
		id: ImportResultType.TRANSLATION_UPDATED,
		translationKey: 'importResultType.translationUpdated',
	},
	[ImportResultType.INVALID_TRANSLATION_PROVIDED]: {
		id: ImportResultType.INVALID_TRANSLATION_PROVIDED,
		translationKey: 'importResultType.invalidTranslationProvided',
	},
	[ImportResultType.TARGET_TRANSLATION_MISSING]: {
		id: ImportResultType.TARGET_TRANSLATION_MISSING,
		translationKey: 'importResultType.targetTranslationMissing',
	},
	[ImportResultType.TRANSLATION_STILL_MISSING]: {
		id: ImportResultType.TRANSLATION_STILL_MISSING,
		translationKey: 'importResultType.translationStillMissing',
	},
	[ImportResultType.INVALID_FILE_PROVIDED]: {
		id: ImportResultType.INVALID_FILE_PROVIDED,
		translationKey: 'importResultType.invalidFileProvided',
	},
	[ImportResultType.LANGUAGE_NOT_SUPPORTED]: {
		id: ImportResultType.LANGUAGE_NOT_SUPPORTED,
		translationKey: 'importResultType.languageNotSupported',
	},
	[ImportResultType.NUMBER_STEP_TRANSLATION_DOES_NOT_MATCH_SOURCE]: {
		id: ImportResultType.NUMBER_STEP_TRANSLATION_DOES_NOT_MATCH_SOURCE,
		translationKey: 'importResultType.numberStepTranslationDoesNotMatchSource',
	},
	[ImportResultType.TARGET_LANGUAGE_MATCHES_SOURCE_LANGUAGE]: {
		id: ImportResultType.TARGET_LANGUAGE_MATCHES_SOURCE_LANGUAGE,
		translationKey: 'importResultType.targetLanguageMatchesSourceLanguage',
	},
	[ImportResultType.USER_DOES_NOT_HAVE_PERMISSION_TO_IMPORT]: {
		id: ImportResultType.USER_DOES_NOT_HAVE_PERMISSION_TO_IMPORT,
		translationKey: 'importResultType.userDoesNotHavePermissionToImport',
	},
};
