/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { NutritionClaimContentTranslation } from '../../../models/nutrition-claim-content-translations.model';
import { getTranslationForLanguage } from '../get-translation-for-language/get-translation-for-language.helper';

import { NutritionClaimGuideline } from 'app/models/nutrition-claim-guideline.model';
import { Language } from 'app/enums/language.enum';

export function getClaimsTooltip(
	claims: Array<NutritionClaimGuideline>,
	language: Language,
): string {
	if (!claims.length) {
		return '';
	}

	return claims
		.map((claim: NutritionClaimGuideline) =>
			getTranslationForLanguage(claim.nutritionClaimContent.translations, language),
		)
		.map((claimTranslation: NutritionClaimContentTranslation) => claimTranslation.title)
		.join(', ');
}
