/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConstantService } from 'app/services/constant/constant.service';
import { StorageService } from 'app/services/storage/storage.service';
import { CustomHeader } from '../enums/custom-header.enum';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
	constructor(
		private readonly storageService: StorageService,
		private readonly constantService: ConstantService,
	) {}

	public get authToken(): string {
		return this.storageService.get(this.constantService.authTokenLocalStorageKey);
	}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let authorizedRequest;

		if (this.authToken && !request.headers.has(CustomHeader.SKIP_AUTHORIZATION_TOKEN)) {
			authorizedRequest = request.clone({
				headers: request.headers.set('Authorization', `Bearer ${this.authToken}`),
			});
		}

		if (request.headers.has(CustomHeader.SKIP_AUTHORIZATION_TOKEN)) {
			authorizedRequest = request.clone({
				headers: request.headers.delete(CustomHeader.SKIP_AUTHORIZATION_TOKEN),
			});
		}

		return next.handle(authorizedRequest || request);
	}
}
