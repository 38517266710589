/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { ContentStatus, ContentStatusHal } from '../../enums/content-status.enum';
import { contentStatusConverter } from '../../utils/helpers/helpers';

@Pipe({ name: 'convertContentStatus' })
export class ContentStatusConverterPipe implements PipeTransform {
	public transform(status: ContentStatus | ContentStatusHal): string {
		return contentStatusConverter(status);
	}
}
