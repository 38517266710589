/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HttpResponse } from '@angular/common/http';
import { EtagHalStorage, EtagStorageModel, HalDocument, HalModel } from 'ngx-hal';
import { Observable, of } from 'rxjs';
import { HalPagination } from '../pagination';

interface ICacheFirstStorageModel<T extends HalModel<HalPagination>>
	extends EtagStorageModel<T, HalPagination> {
	timestamp: number;
}

export class CacheFirstStorage extends EtagHalStorage<HalPagination> {
	public makeGetRequestWrapper<T extends HalModel<HalPagination>>(
		urls: { originalUrl: string; cleanUrl: string; urlWithParams: string },
		cachedResource: T | HalDocument<T, HalPagination>,
		originalGetRequest$: Observable<T | HalDocument<T, HalPagination>>,
	): Observable<T | HalDocument<T, HalPagination>> {
		if (cachedResource) {
			return of(cachedResource);
		}

		return originalGetRequest$;
	}

	public save<T extends HalModel<HalPagination>>(
		model: T | HalDocument<T, HalPagination>,
		response?: HttpResponse<T>,
		alternateUniqueIdentifiers: Array<string> = [],
	): Array<ICacheFirstStorageModel<T>> {
		const storedModels: Array<EtagStorageModel<T, HalPagination>> = super.save(
			model,
			response,
			alternateUniqueIdentifiers,
		);
		const enrichedStoredModels: Array<ICacheFirstStorageModel<T>> = storedModels as Array<
			ICacheFirstStorageModel<T>
		>;

		enrichedStoredModels.forEach((storedModel: ICacheFirstStorageModel<T>) => {
			storedModel.timestamp = Date.now();
		});

		return enrichedStoredModels;
	}

	protected getRawStorageModel<T extends HalModel<HalPagination>>(
		uniqueModelIdentifier: string,
	): ICacheFirstStorageModel<T> {
		return super.getRawStorageModel(uniqueModelIdentifier) as ICacheFirstStorageModel<T>;
	}
}
