/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum NutrientUnit {
	GRAM = 'GRAM',
	KCAL = 'KCAL',
	MICROGRAM = 'MICROGRAM',
	MILLIGRAM = 'MILLIGRAM',
	PERCENT = 'PERCENT',
}

export const nutrientUnitData: any = {
	[NutrientUnit.GRAM]: {
		id: NutrientUnit.GRAM,
		translationKey: 'nutrientUnits.gram',
		conversionRatios: {
			[NutrientUnit.GRAM]: 1,
			[NutrientUnit.MILLIGRAM]: 1000,
			[NutrientUnit.MICROGRAM]: 1000000,
		},
	},
	[NutrientUnit.KCAL]: {
		id: NutrientUnit.KCAL,
		translationKey: 'nutrientUnits.kcal',
	},
	[NutrientUnit.MICROGRAM]: {
		id: NutrientUnit.MICROGRAM,
		translationKey: 'nutrientUnits.microgram',
		conversionRatios: {
			[NutrientUnit.GRAM]: 0.000001,
			[NutrientUnit.MILLIGRAM]: 0.001,
			[NutrientUnit.MICROGRAM]: 1,
		},
	},
	[NutrientUnit.MILLIGRAM]: {
		id: NutrientUnit.MILLIGRAM,
		translationKey: 'nutrientUnits.miligram',
		conversionRatios: {
			[NutrientUnit.GRAM]: 0.001,
			[NutrientUnit.MILLIGRAM]: 1,
			[NutrientUnit.MICROGRAM]: 1000,
		},
	},
	[NutrientUnit.PERCENT]: {
		id: NutrientUnit.PERCENT,
		translationKey: '%',
	},
};
