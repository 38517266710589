/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { ExtendedFormArray, FormStore } from 'ngx-form-object';
import { Administrator } from '../../models/administrator.model';
import { RoleFormStore } from '../role/role.form-store';

export class AdministratorFormStore extends FormStore {
	public get administrator(): Administrator {
		return this.model;
	}

	public get roleFormsControl(): ExtendedFormArray {
		return this.get('roles') as ExtendedFormArray;
	}

	public get roleForms(): Array<RoleFormStore> {
		return this.roleFormsControl.controls as Array<RoleFormStore>;
	}
}
