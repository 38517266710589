/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export * from './decorators/has-many.decorator';
export * from './decorators/belongs-to.decorator';
export * from './decorators/attribute.decorator';
export * from './decorators/json-api-model-config.decorator';
export * from './decorators/json-api-datastore-config.decorator';

export * from './models/json-api-meta.model';
export * from './models/json-api.model';
export * from './models/error-response.model';
export * from './models/json-api-query-data';

export * from './interfaces/overrides.interface';
export * from './interfaces/datastore-config.interface';
export * from './interfaces/model-config.interface';
export * from './interfaces/attribute-decorator-options.interface';
export * from './interfaces/property-converter.interface';

export * from './services/json-api-datastore.service';
