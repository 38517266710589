<div class="container">
	<div class="card">
		<h1>
			{{ 'ssoLandingPage.cardTitle' | translate }}
		</h1>

		<p>{{ 'ssoLandingPage.chooseProviderText' | translate }}</p>

		<div class="button-container">
			<sso-landing-page-button
				*ngFor="let ssoProvider of availableSsoProviders"
				class="button"
				[iconSrc]="ssoProvidersData[ssoProvider].iconSrc"
				[ssoProvider]="ssoProvider"
				(ssoProviderSelect)="onSsoProviderSelect($event)"
			></sso-landing-page-button>
		</div>
	</div>
</div>
