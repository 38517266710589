/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { UntypedFormControl } from '@angular/forms';

export function transformToFormControlArray<T>(items: Array<T> = []): Array<UntypedFormControl> {
	const arrayItems: Array<T> = [].concat(items);
	return arrayItems.map((item: T) => new UntypedFormControl(item));
}
