/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';

import { PopupDialogService } from 'app/services/popup-dialog/popup-dialog.service';

export function showCroppingWarning(
	dialog: PopupDialogService,
	translateService: TranslateService,
	content: string,
	saveButton: string,
): Observable<any> {
	return dialog
		.setTitle(translateService.instant('cropping.warning.title'))
		.setContent(content)
		.setCancelButton({
			label: translateService.instant('cropping.warning.keepCroppingButton'),
			inverse: true,
		})
		.setSaveButton({
			label: saveButton,
		})
		.open()
		.afterClosed();
}
