/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum ReportReason {
	INCORRECT_CONTENT = 'INCORRECT_CONTENT',
	INAPPROPRIATE_LANGUAGE = 'INAPPROPRIATE_LANGUAGE',
	INAPPROPRIATE_OR_ABUSIVE_CONTENT = 'INAPPROPRIATE_OR_ABUSIVE_CONTENT',
	INCOMPLETE_CONTENT = 'INCOMPLETE_CONTENT',
	SPAM = 'SPAM',
	OTHER = 'OTHER',
}

export const reportReasonData: { [key: string]: IReportReasonInfo } = {
	[ReportReason.INCORRECT_CONTENT]: {
		id: ReportReason.INCORRECT_CONTENT,
		label: 'Incorrect content',
		translateKey: 'reportReason.incorrectContent',
	},
	[ReportReason.INAPPROPRIATE_LANGUAGE]: {
		id: ReportReason.INAPPROPRIATE_LANGUAGE,
		label: 'Inappropriate language',
		translateKey: 'reportReason.inappropriateLanguage',
	},
	[ReportReason.INAPPROPRIATE_OR_ABUSIVE_CONTENT]: {
		id: ReportReason.INAPPROPRIATE_OR_ABUSIVE_CONTENT,
		label: 'Inappropriate or abusive content',
		translateKey: 'reportReason.inappropriateOrAbusiveContent',
	},
	[ReportReason.INCOMPLETE_CONTENT]: {
		id: ReportReason.INCOMPLETE_CONTENT,
		label: 'Incomplete content',
		translateKey: 'reportReason.incompleteContent',
	},
	[ReportReason.SPAM]: {
		id: ReportReason.SPAM,
		label: 'Spam',
		translateKey: 'reportReason.spam',
	},
	[ReportReason.OTHER]: {
		id: ReportReason.OTHER,
		label: 'Other',
		translateKey: 'reportReason.other',
	},
};

export interface IReportReasonInfo {
	id: ReportReason;
	label: string;
	translateKey: string;
}
