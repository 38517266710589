/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';

@Injectable()
export class WindowOnBeforeUnloadService {
	private eventListener: EventListener = null;

	public watch(
		shouldShowAlertFunction: () => boolean,
		message = 'There are unsaved changes. Are you sure you want to leave?',
	): void {
		if (this.eventListener) {
			this.unwatch();
		}

		this.eventListener = (event: Event) => {
			if (shouldShowAlertFunction()) {
				const dialogText = message;
				(event as any).returnValue = dialogText; // returnValue is non-standard
				event.preventDefault();
				return dialogText;
			}
		};

		if (window.location.href.indexOf('localhost') === -1) {
			window.addEventListener('beforeunload', this.eventListener);
		}
	}

	public unwatch(): void {
		window.removeEventListener('beforeunload', this.eventListener);
		this.eventListener = null;
	}
}
