/**
 * Copyright (C) 2021 - 2021 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

/* eslint-disable */

export function decimalAdjust(value: number, exp: number): number {
	// Please forgive me, this was ripped from MDN

	if (typeof exp === 'undefined' || Number(exp) === 0) {
		return Math.round(value);
	}

	value = Number(value);
	exp = Number(exp);

	if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
		return NaN;
	}

	if (value < 0) {
		return -decimalAdjust(-value, exp);
	}

	value = value.toString().split('e') as any;
	value = Math.round(Number(value[0] + 'e' + (value[1] ? Number(value[1]) - exp : -exp)));

	value = value.toString().split('e') as any;
	return Number(value[0] + 'e' + (value[1] ? Number(value[1]) + exp : exp));
}
