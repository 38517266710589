/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { TranslateService } from '@ngx-translate/core';

import { decimalAdjust } from '../decimal-adjust/decimal-adjust.helper';

import { IDictionary } from 'app/interfaces/dictionary.interface';
import { NutrientUnit, nutrientUnitData } from 'app/enums/nutrient-unit.enum';

interface IValueUnitPair {
	value: number;
	unit: string;
}

export function valueAndUnitOptimizer(
	value: number,
	unit: NutrientUnit,
	numberOfDecimals: number,
	translateService: TranslateService,
	unitData: any = nutrientUnitData,
): string {
	const conversionRatioObject: any = unitData[unit].conversionRatios;

	if (!conversionRatioObject || (value < 1000 && value >= 1)) {
		const roundedString: string = decimalAdjust(value, -numberOfDecimals).toString();
		return `${roundedString} ${translateService.instant(unitData[unit].translationKey)}`;
	}

	const conversionRatioKeys: Array<string> = Object.keys(conversionRatioObject);

	const availableConversionUnits: Array<NutrientUnit> = conversionRatioKeys.map(
		(key) => unitData[key].id,
	);

	const valueUnitPairs: IDictionary<IValueUnitPair> = {};

	availableConversionUnits.forEach((conversionUnit: NutrientUnit) => {
		const scale: number = unitData[unit].conversionRatios[conversionUnit];
		const rounded: number = decimalAdjust(scale * value, -numberOfDecimals);
		const unitTranslation: string = translateService.instant(
			unitData[conversionUnit].translationKey,
		);
		valueUnitPairs[conversionUnit] = {
			value: rounded,
			unit: unitTranslation,
		};
	});

	let optimalPair: IValueUnitPair;

	Object.keys(valueUnitPairs).forEach((valueUnitPairKey: string) => {
		const pair: IValueUnitPair = valueUnitPairs[valueUnitPairKey];
		if (pair.value < 1000 && pair.value >= 1) {
			optimalPair = pair;
		}
	});

	if (!optimalPair) {
		return `${decimalAdjust(value, -numberOfDecimals)} ${translateService.instant(
			unitData[unit].translationKey,
		)}`;
	}

	return `${optimalPair.value} ${optimalPair.unit}`;
}
