/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum AttributeValueType {
	OBJECT = 'OBJECT',
	INTEGER = 'INTEGER',
	DOUBLE = 'DOUBLE',
	DURATION = 'DURATION',
	BOOLEAN = 'BOOLEAN',
	STRING = 'STRING',
	ENUM = 'ENUM',
	LINK = 'LINK',
	COOKING_PROFILE = 'COOKING_PROFILE',
}
