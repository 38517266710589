/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { InjectionToken } from '@angular/core';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import changelogJson from '../../assets/changelog.json';
import { EnvironmentVariablesService } from '@infinum/ngx-nuts-and-bolts/env';
import { EnvironmentVariable } from '../../environments/environment-variable.enum';

function initializeAwsRum(
	envVarsService: EnvironmentVariablesService<EnvironmentVariable>,
): AwsRum {
	try {
		const config: AwsRumConfig = {
			sessionSampleRate: 1,
			guestRoleArn: envVarsService.get(EnvironmentVariable.AWS_RUM_GUEST_ROLE_ARN),
			identityPoolId: envVarsService.get(EnvironmentVariable.AWS_RUM_IDENTITY_POOL_ID),
			endpoint: envVarsService.get(EnvironmentVariable.AWS_RUM_ENDPOINT),
			telemetries: [
				'performance',
				'errors',
				[
					'http',
					{
						urlsToExclude: [new RegExp('.*/api/.{36}/Content/.{36}/Publication')],
					},
				],
			],
			allowCookies: true,
			enableXRay: false,
		};

		const APPLICATION_ID = envVarsService.get(EnvironmentVariable.AWS_RUM_APPLICATION_ID);
		const APPLICATION_VERSION: string = getApplicationVersion();
		const APPLICATION_REGION = envVarsService.get(EnvironmentVariable.AWS_RUM_APPLICATION_REGION);

		const awsRum: AwsRum = new AwsRum(
			APPLICATION_ID,
			APPLICATION_VERSION,
			APPLICATION_REGION,
			config,
		);

		return awsRum;
	} catch (error) {
		// Ignore errors thrown during CloudWatch RUM web client initialization
		console.log('AWS RUM not initialized.');
	}
}

function getApplicationVersion(): string {
	return changelogJson?.['majorVersions']?.[0]?.[0]?.versionNumber || '0.0.0';
}

export const CLOUDWATCH_RUM = new InjectionToken<AwsRum>('CLOUDWATCH_RUM');

export function provideCloudwatchRUM() {
	return {
		provide: CLOUDWATCH_RUM,
		useFactory: (envVarsService: EnvironmentVariablesService<EnvironmentVariable>) => {
			return initializeAwsRum(envVarsService);
		},
		deps: [EnvironmentVariablesService],
	};
}
