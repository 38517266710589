/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import {
	ExtendedFormArray,
	Attribute as FormAttribute,
	HasMany as FormHasMany,
} from 'ngx-form-object';
import { Attribute, HasMany, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { Language } from '../enums/language.enum';
import { UserStatus } from '../enums/user-status.enum';
import { RoleFormStore } from '../forms/role/role.form-store';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';
import { removeDuplicates } from '../utils/helpers/helpers';
import { sortByProperty } from '../utils/helpers/sort-by-property/sort-by-property.helper';
import { Country } from './country.hal.model';
import { Organization } from './organization.hal.model';
import { Role } from './role.model';

@ModelConfig({
	type: 'Administrator',
})
export class Administrator extends HalDatastoreModel {
	@Attribute()
	@FormAttribute()
	public email: string;

	@Attribute()
	@FormAttribute()
	public name: string;

	@Attribute()
	@FormAttribute()
	public contact: string;

	@Attribute()
	@FormAttribute()
	public company: string;

	@Attribute()
	public status: UserStatus;

	@Attribute()
	public createdAt: Date;

	@Attribute()
	public modifiedAt: Date;

	@HasMany({
		itemsType: Role,
	})
	@FormHasMany({
		isChanged: (initial: Array<Role>, current: Array<Role>, rolesFormArray: ExtendedFormArray) => {
			const isArrayItemChanged: boolean = rolesFormArray.controls.some(
				(item: RoleFormStore) => item && item.isChanged,
			);
			const areArraysDifferent = !compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item) => item.id,
			});

			// ignore initial empty role form creation
			const isInitialFormCreation =
				initial.length === 0 && current.length === 1 && !isArrayItemChanged;

			return isArrayItemChanged || (areArraysDifferent && !isInitialFormCreation);
		},
	})
	public roles: Array<Role>;

	public get modelEndpoints(): ModelEndpoints {
		return {
			singleResourceEndpoint: this.datastore.rootApi.singleAdministratorEndpoint,
			collectionEndpoint: this.datastore.rootApi.administratorCollectionEndpoint,
		};
	}

	public get displayName(): string {
		return `${this.name} (${this.email})`;
	}

	public get isActive(): boolean {
		return this.status === UserStatus.ACTIVE;
	}
}
