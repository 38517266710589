/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HasMany, ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Tenant } from './tenant.model';

@ModelConfig({
	type: 'AdministratorRoot',
})
export class AdministratorRoot extends HalDatastoreModel {
	@Attribute()
	public email: string;

	@HasMany({
		itemsType: Tenant,
	})
	public tenants: Array<Tenant>;

	public get hasMultipleTenants(): boolean {
		return this.tenants.length > 1;
	}
}
