/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { getTranslationForLanguage } from 'app/utils/helpers/helpers';
import { Language } from 'app/enums/language.enum';

@Pipe({ name: 'translatedModel' })
export class TranslatedModelPipe implements PipeTransform {
	transform<T>(items: Array<T>, languageDataPropertyName: string, language: string): Array<T> {
		const translations: Array<T> = items.map((item: T) => {
			const foundTranslation: any = getTranslationForLanguage(
				item[languageDataPropertyName],
				language as Language,
			);

			return foundTranslation ? foundTranslation.value : 'Unknown translation';
		});

		return translations;
	}
}
