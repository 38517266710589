/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { TranslateService } from '@ngx-translate/core';
import { IDuration } from '../../../interfaces/time-duration.interface';

export function formatDuration(
	{ hours, minutes }: IDuration,
	translateService: TranslateService,
): string {
	const minutePluralTranslation = translateService.instant('minute.abbrv.plural');
	const minuteSingularTranslation = translateService.instant('common.minuteAbbreviation');

	return hours
		? `${hours} ${translateService.instant('common.hourAbbreviation')} ${
				minutes ? displayMinutes(minutes, minuteSingularTranslation, minutePluralTranslation) : ''
		  }`
		: displayMinutes(minutes, minuteSingularTranslation, minutePluralTranslation);
}

function displayMinutes(
	minutesValue: number,
	minuteSingular: string,
	minutePlural: string,
): string {
	return `${minutesValue} ${minutesValue > 1 ? minutePlural : minuteSingular}`;
}
