/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

type propertyFn<T> = (a: T) => unknown;

export function removeDuplicatesBy<T>(
	items: Array<T>,
	property?: string | propertyFn<T>,
): Array<T> {
	const filteredItems: Array<T> = [];

	items.forEach((item: T) => {
		const existingItem: T = filteredItems.find((searchedItem: T) => {
			if (typeof property === 'function') {
				return property(searchedItem) === property(item);
			} else {
				return searchedItem[property || 'id'] === item[property || 'id'];
			}
		});

		if (!existingItem) {
			filteredItems.push(item);
		}
	});

	return filteredItems;
}
