/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { NgModule } from '@angular/core';
import { NoPreloading, Route, RouterModule, Routes } from '@angular/router';

import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { NotFoundContainerComponent } from './pages/not-found-container/not-found-container.component';

import { IsSecureRouteGuard } from 'app/guards/is-secure-route/is-secure-route.guard';
import { AccessDeniedContainerComponent } from 'app/pages/access-denied-container/access-denied-container.component';
import { SsoLandingPageComponent } from './pages/sso-landing-page/sso-landing-page.component';
import { SsoLandingPageGuard } from './pages/sso-landing-page/sso-landing-page.guard';

const ROUTES: Routes = [
	{
		path: '',
		canActivate: [IsSecureRouteGuard],
		children: [
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full',
			} as Route,
			{
				path: 'login',
				loadChildren: () => import('app/pages/login/login.module').then((m) => m.LoginModule),
			},
			{
				path: 'logout',
				loadChildren: () => import('app/pages/logout/logout.module').then((m) => m.LogoutModule),
			},
			{
				path: 'admin',
				loadChildren: () => import('app/pages/admin/admin.module').then((m) => m.AdminModule),
			},
			{ path: 'error', component: ErrorPageComponent },
			{ path: 'access-denied', component: AccessDeniedContainerComponent },
			{
				path: 'dev-login',
				loadChildren: () =>
					import('app/pages/dev-login/dev-login.module').then((m) => m.DevLoginModule),
			},
			{
				path: 'admin-login',
				loadChildren: () =>
					import('app/pages/dev-login/dev-login.module').then((m) => m.DevLoginModule),
			},
			{ path: 'providers', component: SsoLandingPageComponent, canActivate: [SsoLandingPageGuard] },
			{
				path: 'tenant-login',
				loadChildren: () =>
					import('app/pages/tenant-login/tenant-login.module').then((m) => m.TenantLoginModule),
			},
			{ path: '**', component: NotFoundContainerComponent },
		],
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(ROUTES, {
			useHash: false,
			preloadingStrategy: NoPreloading,
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
