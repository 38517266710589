/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

// params: value - string
// returns a new string with first letter decapitalized
export function decapitalize(value: string): string {
	let decapitalizedValue = value;
	if (value) {
		decapitalizedValue = value.charAt(0).toLowerCase() + value.slice(1);
	}

	return decapitalizedValue;
}
