/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Datastore } from '../datastore/datastore.service';

@Injectable()
export class DevLoginService {
	private readonly loginURL = 'login';

	constructor(
		private readonly datastore: Datastore,
		private readonly authService: AuthService,
		private readonly router: Router,
	) {}

	public login(username: string, password: string): Observable<void> {
		const body = {
			username,
			password,
		};

		return (
			this.datastore.makeHttpRequest('POST', this.loginURL, { body }) as unknown as Observable<{
				token: string;
			}>
		).pipe(
			tap((response) => {
				if (response) {
					this.authService.login(response.token);
					this.router.navigateByUrl('/');
				}
			}),
			map(() => undefined),
		);
	}
}
