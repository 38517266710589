/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'app/shared-modules/material.module';
import { PopupDialogComponent } from 'app/components/popup-dialog/popup-dialog.component';
import { PopupDialogService } from 'app/services/popup-dialog/popup-dialog.service';
import { ConfirmationActionsComponent } from 'app/components/popup-dialog/confirmation-actions/confirmation-actions.component';
import { ConfirmationDialogComponent } from 'app/components/popup-dialog/confirmation-dialog/confirmation-dialog.component';

@NgModule({
	imports: [CommonModule, MaterialModule],
	declarations: [ConfirmationActionsComponent, ConfirmationDialogComponent, PopupDialogComponent],
	providers: [PopupDialogService],
	exports: [ConfirmationActionsComponent, ConfirmationDialogComponent, PopupDialogComponent],
})
export class PopupDialogModule {}
