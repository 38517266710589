/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { TranslateService } from '@ngx-translate/core';
import { IDictionary } from '../../../interfaces/dictionary.interface';
import { ISelectInterface2 } from '../../../interfaces/select.interface';

type GetSelectLabelFunction = (item: IDictionary<any>) => string;
interface ISelectGeneratorOptions {
	getLabel?: GetSelectLabelFunction;
	sortByLabel?: boolean;
	metaItemName?: string;
	idPropertyName?: string;
}

export function getSelectOptionsFromEnumData<T>(
	enumData: object,
	translateService?: TranslateService,
	translationKeyPropertyName = 'translateKey',
	sortFunction: (option1, options2) => number = compareSelectOptions,
): Array<ISelectInterface2<T>> {
	const options = Object.keys(enumData).map((enumKey) => ({
		value: (enumKey as unknown) as T,
		label: translateService
			? translateService.instant(enumData[enumKey][translationKeyPropertyName])
			: enumData[enumKey].label,
		meta: enumData[enumKey],
	}));

	return options.sort(sortFunction);
}

export function getSelectOptionsFromArray<T>(
	items: Array<IDictionary<any>>,
	options: ISelectGeneratorOptions = {},
): Array<ISelectInterface2<{ id: T }>> {
	const result: Array<ISelectInterface2<{ id: T }>> = items.map((item: IDictionary<any>) => ({
		value: { id: item[options.idPropertyName || 'id'] },
		label: options.getLabel ? options.getLabel(item) : item.id.toString(),
		meta: { [options.metaItemName || 'item']: item },
	}));

	if (options.sortByLabel) {
		result.sort(compareSelectOptions);
	}

	return result;
}

function compareSelectOptions<T>(
	option1: ISelectInterface2<T>,
	option2: ISelectInterface2<T>,
): number {
	return option1.label.localeCompare(option2.label);
}
