/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import {
	Attribute as FormAttribute,
	BelongsTo as FormBelongsTo,
	HasMany as FormHasMany,
} from 'ngx-form-object';
import { Attribute, HasMany, HasOne, HeaderAttribute, ModelConfig, ModelEndpoints } from 'ngx-hal';
import {
	ContentContainerContentType,
	ContentContainerType,
} from '../enums/content-container-type.enum';
import { Language } from '../enums/language.enum';
import { StoreCategory } from '../enums/store-category.enum';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';
import {
	transformLanguageForHalApi,
	transformLanguageForJsonApi,
} from '../utils/helpers/language-transformer/language-transformer.helper';
import { AnnouncementTranslation } from './announcement-translation.hal.model';
import { Article } from './article.model';
import { Category } from './category.model';
import { Collection } from './collection.hal.model';
import { Content } from './content.model';
import { Media } from './media.hal.model';
import { Profile } from './profile.hal.model';
import { Recipe } from './recipe.hal.model';

@ModelConfig({
	type: 'Announcement',
})
export class Announcement extends Content {
	public static modelType = 'Announcement';

	public get modelEndpoints(): ModelEndpoints {
		return {
			singleResourceEndpoint: this.datastore.rootApi.singleAnnouncementEndpoint,
			collectionEndpoint: this.datastore.rootApi.announcementCollectionEndpoint,
		};
	}

	@HeaderAttribute({
		transformResponseValue: transformLanguageForJsonApi,
		transformBeforeSave: transformLanguageForHalApi,
		externalName: 'Content-language',
	})
	@FormAttribute()
	public language: Language;

	@Attribute({
		externalName: 'type',
	})
	@FormAttribute()
	public announcementType: ContentContainerType;

	@HasMany({
		itemsType: Category,
		includeInPayload: true,
	})
	@FormHasMany({
		isChanged: (initial: Array<Category>, current: Array<Category>) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
				propertyFn: (item: Category) => item?.slug || '',
			}),
	})
	public deviceCategories: Array<Category>;

	@Attribute()
	@FormAttribute()
	public url: string;

	@Attribute()
	@FormAttribute()
	public storeCategory: StoreCategory;

	@Attribute()
	@FormAttribute()
	public title: string;

	@Attribute()
	@FormAttribute()
	public description: string;

	@HasOne({
		externalName: 'media',
		includeInPayload: true,
		propertyClass: Media,
	})
	@FormBelongsTo()
	public image: Media;

	@HasOne({
		includeInPayload: true,
		propertyClass: Media,
	})
	@FormBelongsTo()
	public video: Media;

	@HasOne({
		propertyClass: Recipe,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public recipe: Recipe;

	@HasOne({
		propertyClass: Collection,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public collection: Collection;

	@HasOne({
		propertyClass: Article,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public article: Article;

	@HasOne({
		propertyClass: Profile,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public user: Profile;

	@HasMany({
		itemsType: AnnouncementTranslation,
		includeInPayload: true,
	})
	@FormHasMany()
	public translations: Array<AnnouncementTranslation>;

	@HasOne({
		propertyClass: AnnouncementTranslation,
	})
	@FormBelongsTo()
	public defaultTranslation: AnnouncementTranslation;

	// Used in forms, mapped to actual properties before saving
	@FormBelongsTo()
	public content: any;

	public get isTypeContent(): boolean {
		return this.announcementType === ContentContainerType.CONTENT;
	}

	public get extendedAnnouncementType(): ContentContainerType | ContentContainerContentType {
		if (!this.isTypeContent) {
			return this.announcementType;
		}

		let type: ContentContainerContentType;

		if (this.links.article) {
			type = ContentContainerContentType.ARTICLE;
		} else if (this.links.collection) {
			type = ContentContainerContentType.RECIPE_BOOK;
		} else if (this.links.recipe) {
			type = ContentContainerContentType.RECIPE;
		}

		return type;
	}

	public get relatedContentTypeTranslationKey(): string {
		return this.relatedContent ? this.relatedContent.displayNameTranslationKey : null;
	}

	public get relatedContentShortID(): string {
		return this.relatedContent ? this.relatedContent.shortId : null;
	}

	public get relatedContentTitle(): string {
		return this.relatedContent ? this.relatedContent.title : null;
	}

	public get relatedContentUrl(): string {
		if (this.article) {
			return `/admin/articles/${this.article.id}`;
		} else if (this.collection) {
			return `/admin/collections/${this.collection.id}`;
		} else if (this.recipe) {
			return `/admin/recipes/${this.recipe.id}`;
		}
	}

	private get relatedContent(): Article | Collection | Recipe {
		return this.article || this.collection || this.recipe;
	}

	public displayNameTranslationKey = 'contentTypeHal.announcement';
}
