/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, BelongsTo, HasMany, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import {
	Attribute as FormAttribute,
	BelongsTo as FormBelongsTo,
	HasMany as FormHasMany,
} from 'ngx-form-object';

import { ModelMetadata } from '../classes/model-metadata/model-metadata';
import { AuthorType } from '../enums/author-type.enum';
import { ContentDraftStatusReason } from '../enums/content-draft-status-reason.enum';
import { ContentStatus } from '../enums/content-status.enum';
import { ModelTypePrefix } from '../enums/model-type-prefix.enum';
import { IContentModel } from '../interfaces/content-model.interface';
import { IMutableHistory } from '../interfaces/mutable-history.interface';
import { ConsumerProfile } from '../models/consumer-profile.model';
import { Consumer } from '../models/consumer.model';
import { CountryProfile } from '../models/country-profile.model';
import { Device } from '../models/device.model';
import { Domain } from '../models/domain.model';
import { HistoryItem } from '../models/history-item.model';
import { Media } from '../models/media.model';
import { RecipeIngredient } from '../models/recipe-ingredient.model';
import { RecipeNutritionInfo } from '../models/recipe-nutrition-info.model';
import { RecipeTranslation } from '../models/recipe-translation.model';
import { User } from '../models/user.model';

@JsonApiModelConfig({
	type: 'recipes',
	meta: ModelMetadata,
})
export class Recipe extends JsonApiModel implements IContentModel, IMutableHistory {
	public config = null;

	public modelTypePrefix: ModelTypePrefix = ModelTypePrefix.RECIPE;

	@FormAttribute()
	public id: string;

	@Attribute({
		dataType: Date,
	})
	public createdAt: Date;

	@Attribute({
		dataType: Date,
	})
	public modifiedAt: Date;

	@Attribute()
	@FormAttribute()
	public publishedAt: any; // because null will result with "Invalid date"

	@Attribute()
	@FormAttribute()
	public easyToStartWith: boolean;

	@Attribute()
	@FormAttribute()
	public processingTimeSeconds: number;

	@Attribute()
	@FormAttribute()
	public preparationTimeSeconds: number;

	@Attribute()
	@FormAttribute()
	public passivePreparationTimeSeconds: number;

	@Attribute()
	@FormAttribute()
	public activeTimeSeconds: number;

	@Attribute()
	@FormAttribute()
	public contentCategory: string;

	@Attribute()
	@FormAttribute()
	public totalTimeSeconds: number;

	@Attribute()
	@FormAttribute()
	public country: string;

	@Attribute()
	@FormAttribute()
	public visibility: number;

	@Attribute()
	@FormAttribute()
	public status: ContentStatus;

	@Attribute()
	@FormAttribute()
	public source: number;

	@Attribute()
	public totalWeight: number;

	@Attribute()
	@FormAttribute()
	public type: AuthorType;

	@Attribute()
	@FormAttribute()
	public numberOfServings: number;

	@Attribute()
	@FormAttribute()
	public maxNumberOfServings: number;

	@Attribute()
	@FormAttribute()
	public private: boolean;

	@Attribute()
	@FormAttribute()
	public translationCount: number;

	@Attribute()
	@FormAttribute()
	public shortId: string;

	@Attribute()
	@FormAttribute()
	public viewCount: number;

	@Attribute()
	public statusReason: ContentDraftStatusReason;

	@Attribute()
	@FormAttribute()
	public favouriteCount: number;

	@Attribute()
	@FormAttribute()
	public commentCount: number;

	@Attribute()
	@FormAttribute()
	public shareCount: number;

	@Attribute()
	@FormAttribute()
	public nutritionalInfoComment: string;

	@HasMany()
	@FormHasMany()
	public recipeTranslations: Array<RecipeTranslation>;

	@BelongsTo()
	@FormBelongsTo()
	public defaultTranslation: RecipeTranslation;

	@HasMany()
	@FormHasMany()
	public recipeIngredients: Array<RecipeIngredient>;

	@HasMany()
	@FormHasMany()
	public recipeNutritionInfo: Array<RecipeNutritionInfo> = [];

	@BelongsTo()
	@FormBelongsTo()
	public coverImage: Media;

	@BelongsTo()
	@FormBelongsTo()
	public video: Media;

	@BelongsTo()
	@FormBelongsTo()
	public domain: Domain;

	@BelongsTo()
	@FormBelongsTo()
	public authorUser: User;

	@BelongsTo()
	@FormBelongsTo()
	public authorConsumer: Consumer;

	@BelongsTo()
	@FormBelongsTo()
	public lockedBy: User;

	@BelongsTo()
	@FormBelongsTo()
	public originalDevice: Device;

	@HasMany()
	@FormHasMany()
	public devices: Array<Device>;

	@HasMany()
	@FormHasMany()
	public profiles: Array<CountryProfile | ConsumerProfile>;

	@HasMany()
	@FormHasMany()
	public historyItems: Array<HistoryItem>;

	public translationsPropertyName = 'recipeTranslations';

	public get translations(): Array<RecipeTranslation> {
		return this.recipeTranslations;
	}
}
