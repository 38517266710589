/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { BelongsTo as FormBelongsTo } from 'ngx-form-object';
import { HasOne, ModelConfig } from 'ngx-hal';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Category } from './category.model';
import { CookingVariable } from './cooking-variable.model';

@ModelConfig({
	type: 'CookingVariableCategory',
})
export class CookingVariableCategory extends HalDatastoreModel {
	@HasOne({
		propertyClass: 'CookingVariable',
		includeInPayload: true,
	})
	@FormBelongsTo()
	public cookingVariable: CookingVariable;

	@HasOne({
		propertyClass: Category,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public category: Category;

	public get endpoint(): string {
		return `CookingVariable/${this.cookingVariable.id}/Category`;
	}
}
