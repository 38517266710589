/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HasMany, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Device } from './device.hal.model';

@ModelConfig({
	type: 'ProductRange',
})
export class ProductRange extends HalDatastoreModel {
	public static modelType = 'ProductRange';

	@Attribute()
	public name: string;

	@HasMany({ itemsType: 'Device' })
	public devices: Array<Device>;

	public get modelEndpoints(): ModelEndpoints {
		return {
			collectionEndpoint: this.datastore.rootApi.productRangeCollectionEndpoint,
		};
	}
}
