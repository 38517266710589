/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { HttpParameterCodec } from '@angular/common/http';

export class QueryParamsEncoder implements HttpParameterCodec {
	public encodeKey(key: string): string {
		return encodeURIComponent(key);
	}

	public encodeValue(value: string): string {
		return encodeURIComponent(value);
	}

	public decodeKey(key: string): string {
		return decodeURIComponent(key);
	}

	public decodeValue(value: string): string {
		return decodeURIComponent(value);
	}
}
