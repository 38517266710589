/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export function isCompactDevice(id: string): boolean {
	return compactDeviceIds.includes(id);
}

const compactDeviceIds: Array<string> = [
	'979fe7ed-ead0-5184-bbce-bd587d81d9e3', // Daily
	'd5ec6b75-ca0d-4c83-9c6a-85645e11cbcd', // Viva
	'00000002-ca0d-4c83-9c6a-85645e11cbcd', // Viva Digital
	'00000004-ca0d-4c83-9c6a-85645e11cbcd', // Viva TurboStar
	'282c0ca0-beea-4f49-912e-2ab1d608609d', // Viva Twin TurboStar
	'00000001-ca0d-4c83-9c6a-85645e11cbcd', // Avance TurboStar
	'182c0ca0-beea-4f49-912e-2ab1d608609d', // Avance Twin TurboStar
	'c0b57b8e-6fd6-44db-82d3-8cc4f1c83951', // Essential Digital
	'b2295cc5-f709-41f5-8e53-c026b8e82115', // Essential Analog
	'403528b4-ee61-45d5-8f36-2ead724845f2', // Essential Digital connected
];
