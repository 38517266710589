/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Observable } from 'rxjs';

import { CommentModalComponent } from '../../../../components/comment-modal/comment-modal.component';
import {
	IAlertData,
	IDialogCloseData,
} from '../../../../components/popup-dialog/dialog-options.interface';
import { PopupDialogService } from '../../../../services/popup-dialog/popup-dialog.service';
import { AlertType } from '../../../../components/alert/alert-type.enum';

export interface ICommentModalOptions {
	title: string;
	content: string;
	saveButtonLabel: string;
	cancelButtonLabel: string;
	validateComment: boolean;
	onSuccess?: Function;
	alert?: IAlertData;
}

export function openCommentModal(
	dialog: PopupDialogService,
	options: ICommentModalOptions,
): Observable<IDialogCloseData> {
	return dialog
		.setTitle(options.title)
		.setContent(options.content)
		.setAlert(options.alert)
		.setCancelButton({
			label: options.cancelButtonLabel,
			inverse: true,
		})
		.setSaveButton({
			label: options.saveButtonLabel,
			callback: options.onSuccess,
		})
		.setExtraOptions({
			validateComment: options.validateComment,
		})
		.open(CommentModalComponent)
		.afterClosed();
}
