/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiModelConfig, JsonApiModel, Attribute, BelongsTo } from 'angular2-jsonapi';

import { User } from './user.model';

import { Consumer } from 'app/models/consumer.model';
import { ContentStatus } from 'app/enums/content-status.enum';
import { HistoryAction } from 'app/enums/history-action.enum';
import { HistoryEvent } from 'app/enums/history-event.enum';

@JsonApiModelConfig({
	type: 'historyItems',
})
export class HistoryItem extends JsonApiModel {
	@Attribute()
	public action: HistoryAction;

	@Attribute()
	public event: HistoryEvent;

	@Attribute()
	public message: string;

	@Attribute()
	public metadata: {
		comment: string;
		changeReasons: Array<string>;
		oldStatus: ContentStatus;
		newStatus: ContentStatus;
		profileId: string;
		contentId: string;
		timestamp: Date;
	};

	@Attribute()
	public subjectUserId: string;

	@Attribute()
	public subjectConsumerId: string;

	@Attribute()
	public objectId: string;

	@Attribute({
		dataType: Date,
	})
	public createdAt: Date;

	@BelongsTo()
	public authorConsumer: Consumer;

	@BelongsTo()
	public authorUser: User;
}
