/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute } from 'ngx-form-object';
import { Attribute, HasOne, ModelConfig } from 'ngx-hal';

import { ArticleStep } from './article-step.model';
import { Translation } from './translation.model';

@ModelConfig({
	type: 'ArticleStepTranslation',
})
export class ArticleStepTranslation extends Translation {
	@Attribute()
	@FormAttribute()
	public description: string;

	@Attribute()
	@FormAttribute()
	public title: string;

	@HasOne({
		propertyClass: 'ArticleStep',
	})
	public step: ArticleStep;
}
