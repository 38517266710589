/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { FormStore } from 'ngx-form-object';

export function getFormByFormField<T extends FormStore = FormStore>(
	forms: Array<T>,
	formFieldName: string,
	formFieldValue: any,
): T {
	return forms.find((form: T) => {
		if (!form.controls[formFieldName]) {
			return false;
		}
		return form.controls[formFieldName].value === formFieldValue;
	});
}
