/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiModelConfig, JsonApiModel, Attribute, BelongsTo } from 'angular2-jsonapi';

import { TranslationStatus } from '../enums/translation-status.enum';
import { HealthClaimContent } from './health-claim-content.model';

import { ModelMetadata } from 'app/classes/model-metadata/model-metadata';
import { IModelTranslation } from 'app/interfaces/model-translation.interface';
import { Language } from 'app/enums/language.enum';

@JsonApiModelConfig({
	type: 'healthClaimContentTranslations',
	meta: ModelMetadata,
})
export class HealthClaimContentTranslation extends JsonApiModel implements IModelTranslation {
	public config = null;

	@Attribute()
	public description: string;

	@Attribute()
	public language: Language;

	@Attribute()
	public status: TranslationStatus;

	@Attribute()
	public title: string;

	@BelongsTo()
	public healthClaimContent: HealthClaimContent;
}
