/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export function getDirtyAttributes(attributesMetadata: any): { string: any } {
	const dirtyData: any = {};

	for (const propertyName in attributesMetadata) {
		if (attributesMetadata.hasOwnProperty(propertyName)) {
			const metadata: any = attributesMetadata[propertyName];

			const attributeName =
				metadata.serializedName != null ? metadata.serializedName : propertyName;
			dirtyData[attributeName] = metadata.serialisationValue
				? metadata.serialisationValue
				: metadata.newValue;
		}
	}
	return dirtyData;
}
