<h2 mat-dialog-title>{{ data.title }}</h2>
<alert *ngIf="data.alert" [alertType]="data.alert.alertType" [message]="data.alert.message"></alert>

<p class="mat-dialog-subtitle">{{ data.content }}</p>

<mat-dialog-content>
	<form class="dialog-content-item" [formGroup]="commentForm">
		<textarea-field className="box" formControlName="comment" placeholder="Comment" rows="10">
		</textarea-field>
	</form>
</mat-dialog-content>

<confirmation-actions
	[data]="data"
	[saveDisabled]="!isSuccessButtonEnabled"
	(cancel)="onCancelClick()"
	(save)="onSaveClick()"
></confirmation-actions>
