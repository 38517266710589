/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { arrayBufferToText } from './array-buffer-to-text/array-buffer-to-text.helper';
import { capitalize } from './capitalize/capitalize.helper';
import { decapitalize } from './capitalize/decapitalize.helper';
import { cleanLink } from './clean-link/clean-link';
import { compareCompatibleProducts } from './compare-compatible-products/compare-compatible-products';
import { contains } from './contains/contains.helper';
import { contentStatusConverter } from './content-status-converter/content-status-converter.helper';
import { convertDurationToSeconds } from './convert-duration-to-seconds/convert-duration-to-seconds.helper';
import { convertSecondsToDuration } from './convert-seconds-to-duration/convert-seconds-to-duration.helper';
import { decimalAdjust } from './decimal-adjust/decimal-adjust.helper';
import { deleteNullObjectProperties } from './delete-null-object-properties/delete-null-object-properties.helper';
import { difference } from './difference/difference.helper';
import { escapeHtml } from './escape-html/escape-html.helper';
import { eventPath } from './event-path/event-path.helpers';
import { openCloseConfirmationDialog } from './form/close-confirmation-dialog/close-confirmation-dialog.helper';
import { extractErrorMessages } from './form/extract-error-messages.helper';
import { extractFormErrors } from './form/extract-form-errors.helper';
import { openMissingInformationDialog } from './form/missing-information-dialog.helper';
import { transformToFormControlArray } from './form/transform-to-form-control-array/transform-to-form-control-array.helper';
import { generateDefaultValueFormArray } from './generate-default-value-form-array/generate-default-value-form-array';
import { getClaimsTooltip } from './get-claims-tooltip/get-claims-tooltip.helper';
import { getDeviceOptionControlName } from './get-device-option-control-name/get-device-option-control-name.helper';
import { getFormByFormField } from './get-form-by-form-field/get-form-by-form-field.helper';
import { getTranslatableProperty } from './get-translatable-property/get-translatable-property.helper';
import { getTranslationForLanguage } from './get-translation-for-language/get-translation-for-language.helper';
import { groupAsArrayByHelper } from './group-as-array-by/group-as-array-by.helper';
import { intersection } from './intersection/intersection.helper';
import { isArray } from './is-array/is-array';
import { isFunction } from './is-function/is-function.helper';
import { isNumber } from './is-number/is-number';
import { isProduction } from './is-production/is-production.helper';
import { isURLvalid } from './is-url-valid/is-url-valid.helper';
import { joinLastDifferent } from './join-last-different/join-last-different';
import {
	ICommentModalOptions,
	openCommentModal,
} from './modals/comment-modal/comment-modal.helper';
import { IConfirmationDialogOptions } from './modals/confirmation-dialog/confirmation-dialog-options.interface';
import { openConfirmationDialog } from './modals/confirmation-dialog/confirmation-dialog.helper';
import { showCroppingWarning } from './modals/cropping-warning/cropping-warning.helper';
import { IInformationDialogOptions } from './modals/information-dialog/information-dialog-options.interface';
import { openInformationDialog } from './modals/information-dialog/information-dialog.helper';
import { openReportModal } from './modals/report-modal/report-modal.helper';
import { moveItemPositionInArray } from './move-item-position-in-array/move-item-position-in-array.helper';
import { moveTranslationsToDraft } from './move-translations-to-draft/move-translations-to-draft.helper';
import { deepCopy, isObject, removeBlankAttributes } from './object/object.helper';
import { order } from './order/order.helper';
import { range } from './range/range.helper';
import {
	IRedirectUrlData,
	redirectUrlSplitter,
} from './redirect-url-splitter/redirect-url-splitter.helper';
import { removeDuplicatesBy } from './remove-duplicates-by/remove-duplicates-by.helper';
import { removeDuplicates } from './remove-duplicates/remove-duplicates.helper';
import { roundNumber } from './round-number/round-number.helper';
import { transformSecondsToMinutes } from './seconds-to-minutes/seconds-to-minutes.helper';
import { getSelectOptionsFromEnumData } from './select/select.helper';
import { showSortingArrow } from './sorting/show-sorting-arrow';
import { transformSortParams } from './sorting/sort-params-transformer';
import { statusOptions } from './status-options/status-options.helper';
import { stripSpaces } from './strip-spaces/strip-spaces.helper';
import { transformObjectStrings } from './transform-object-strings/transform-object-strings.helper';
import { unescapeHtml } from './unescape-html/unescape-html.helper';
import { generateUUID } from './uuid/uuid.helper';
import { valueAndUnitOptimizer } from './value-and-unit-optimizer/value-and-unit-optimizer.helper';

export {
	arrayBufferToText,
	capitalize,
	cleanLink,
	compareCompatibleProducts,
	contains,
	contentStatusConverter,
	convertDurationToSeconds,
	convertSecondsToDuration,
	decapitalize,
	decimalAdjust,
	deepCopy,
	deleteNullObjectProperties,
	difference,
	escapeHtml,
	eventPath,
	extractErrorMessages,
	extractFormErrors,
	generateDefaultValueFormArray,
	generateUUID,
	getClaimsTooltip,
	getDeviceOptionControlName,
	getFormByFormField,
	getSelectOptionsFromEnumData,
	getTranslatableProperty,
	getTranslationForLanguage,
	groupAsArrayByHelper,
	ICommentModalOptions,
	IConfirmationDialogOptions,
	IInformationDialogOptions,
	intersection,
	IRedirectUrlData,
	isArray,
	isFunction,
	isNumber,
	isObject,
	isProduction,
	isURLvalid,
	joinLastDifferent,
	moveItemPositionInArray,
	moveTranslationsToDraft,
	openCloseConfirmationDialog,
	openCommentModal,
	openConfirmationDialog,
	openInformationDialog,
	openMissingInformationDialog,
	openReportModal,
	order,
	range,
	redirectUrlSplitter,
	removeBlankAttributes,
	removeDuplicates,
	removeDuplicatesBy,
	roundNumber,
	showCroppingWarning,
	showSortingArrow,
	statusOptions,
	stripSpaces,
	transformObjectStrings,
	transformSecondsToMinutes,
	transformSortParams,
	transformToFormControlArray,
	unescapeHtml,
	valueAndUnitOptimizer,
};
