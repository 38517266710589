/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute } from 'ngx-form-object';
import { Attribute, HasOne, ModelConfig } from 'ngx-hal';
import { Translation } from './translation.model';

@ModelConfig({
	type: 'PushMessageTranslation',
})
export class PushMessageTranslation extends Translation {
	@Attribute()
	@FormAttribute()
	public title: string;

	@Attribute()
	@FormAttribute()
	public description: string;

	// Adding PushMessage type here will cause a bug:
	// See recipe.hal.model
	@HasOne({
		propertyClass: null,
	})
	public pushMessage: any;
}
