/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiModelConfig, JsonApiModel, Attribute, BelongsTo } from 'angular2-jsonapi';

import { Comment } from './comment.model';

import { Tip } from 'app/models/tip.model';
import { Recipe } from 'app/models/recipe.model';
import { ConsumerProfile } from 'app/models/consumer-profile.model';
import { Consumer } from 'app/models/consumer.model';
import { Country } from 'app/custom-types/country/country.type';
import { Language } from 'app/enums/language.enum';
import { ReportedContentStatus } from 'app/enums/reported-content-status.enum';
import { ModelMetadata } from 'app/classes/model-metadata/model-metadata';
import { User } from 'app/models/user.model';

@JsonApiModelConfig({
	type: 'reports',
	meta: ModelMetadata,
})
export class ReportedContent extends JsonApiModel {
	@Attribute()
	public additionalText: string;

	@Attribute()
	public itemType: string;

	@Attribute()
	public reportReason: string;

	@Attribute()
	public reportStatus: ReportedContentStatus;

	@Attribute({
		dataType: Date,
	})
	public reportedAt: Date;

	@Attribute({
		dataType: Country,
	})
	public reporterCountry: Country;

	@Attribute()
	public reporterLanguage: Language;

	@Attribute()
	public reviewedAt: any;

	@BelongsTo()
	public reportedItem: Recipe | Tip | ConsumerProfile | Comment;

	@BelongsTo()
	public reportedByConsumer: Consumer;

	@BelongsTo()
	public reportedByUser: User;

	public get reportedBy(): Consumer | User {
		return this.reportedByConsumer || this.reportedByUser;
	}
}
