/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { JsonApiModelConfig, JsonApiModel, Attribute, BelongsTo, HasMany } from 'angular2-jsonapi';

import { HealthClaimContentTranslation } from './health-claim-content-translations.model';

import { ModelMetadata } from 'app/classes/model-metadata/model-metadata';
import { ContentStatus } from 'app/enums/content-status.enum';
import { Consumer } from 'app/models/consumer.model';
import { User } from 'app/models/user.model';
import { HealthClaimGuideline } from 'app/models/health-claim-guideline.model';
import { Domain } from 'app/models/domain.model';
import { IContentModel } from 'app/interfaces/content-model.interface';
import { ModelTypePrefix } from 'app/enums/model-type-prefix.enum';

@JsonApiModelConfig({
	type: 'healthClaimContents',
	meta: ModelMetadata,
})
export class HealthClaimContent extends JsonApiModel implements IContentModel {
	public config = null;

	public modelTypePrefix: ModelTypePrefix = ModelTypePrefix.HEALTH_CLAIM_GUIDELINE;

	@Attribute()
	public shortId: string;

	@Attribute()
	public status: ContentStatus;

	@Attribute()
	public translationCount: number;

	@BelongsTo()
	public authorConsumer: Consumer;

	@BelongsTo()
	public authorUser: User;

	@BelongsTo()
	public defaultTranslation: HealthClaimContentTranslation;

	@BelongsTo()
	public domain: Domain;

	@HasMany()
	public healthClaimContentTranslations: Array<HealthClaimContentTranslation>;

	@BelongsTo()
	public healthClaimGuideline: HealthClaimGuideline;

	// NOT USED FOR HealthClaimContent
	profiles: Array<any>;

	public get translations(): Array<HealthClaimContentTranslation> {
		return this.healthClaimContentTranslations;
	}

	public translationsPropertyName = 'healthClaimContentTranslations';
}
