/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { BelongsTo, Attribute as FormAttribute } from 'ngx-form-object';
import { Attribute, HasOne, ModelConfig } from 'ngx-hal';
import { hiddenUnits, IngredientUnit } from '../enums/ingredient-unit.enum';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Content } from './content.model';
import { Food } from './food.model';

@ModelConfig({
	type: 'RecipeIngredient',
})
export class RecipeIngredient extends HalDatastoreModel {
	private internalParent: Content;

	@Attribute()
	@FormAttribute()
	public quantity: number;

	@Attribute()
	@FormAttribute()
	public unit: IngredientUnit;

	@Attribute()
	@FormAttribute()
	public mainIngredient: boolean;

	@Attribute()
	@FormAttribute()
	public mainIngredientSuggested: boolean;

	@HasOne({
		propertyClass: Food,
		externalName: 'food',
		includeInPayload: true,
	})
	@BelongsTo()
	public ingredient: Food;

	public get parent(): Content {
		return this.internalParent || ({ id: this.rawResponse.url.split('/')[5] } as Content);
	}

	public set parent(content: Content) {
		this.internalParent = content;
	}

	public get isVisible(): boolean {
		return hiddenUnits.indexOf(this.unit) === -1;
	}
}
