/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute as FormAttribute } from 'ngx-form-object';
import { Attribute, HasOne, ModelConfig } from 'ngx-hal';

import { Article } from './article.model';
import { Translation } from './translation.model';

@ModelConfig({
	type: 'ArticleTranslation',
})
export class ArticleTranslation extends Translation {
	@Attribute()
	@FormAttribute()
	public description: string;

	@Attribute()
	@FormAttribute()
	public title: string;

	@HasOne({ propertyClass: 'Article' })
	public article: Article;
}
