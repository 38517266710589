/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { ContentContainerContentType, ContentContainerType } from './content-container-type.enum';
import { ContentType, contentTypeData } from './content-type.hal.enum';
import { DeepLinkGeneratorContentType } from './deep-link-generator.enum';
import { HighLevelCategoryEnum } from './high-level-category.enum';
import { ScreenName } from './screen-name.enum';

export enum ContentContainerCategory {
	ANNOUNCEMENT = HighLevelCategoryEnum.ANNOUNCEMENT_CONTENT_CONTAINER_TYPE,
	HIGHLIGHT = HighLevelCategoryEnum.HIGHLIGHT_CONTENT_CONTAINER_TYPE,
	ONBOARDING_ANNOUNCEMENT = HighLevelCategoryEnum.ONBOARDING_ANNOUNCEMENT_CONTENT_CONTAINER_TYPE,
	PROMO_CODE = HighLevelCategoryEnum.PROMO_CODE_CONTENT_CONTAINER_TYPE,
	SHOP_ANNOUNCEMENT = HighLevelCategoryEnum.SHOP_ANNOUNCEMENT_CONTENT_CONTAINER_TYPE,
}

export interface IContentContainerCategoryFeatures {
	availableTypes: Array<ContentContainerType | ContentContainerContentType> | null;
	code: boolean;
	copyTranslations: boolean;
	deepLinkSupport: boolean;
	description: boolean;
	image: boolean;
	previewCard: boolean;
	previewFullscreen: boolean;
	requiredVideo: boolean;
	titleCharacterLimit: number | null;
	pinning: boolean;
}

export interface IContentContainerCategoryData {
	browsePageTitle: string;
	contentType: ContentType;
	deepLinkContentType: DeepLinkGeneratorContentType;
	features: IContentContainerCategoryFeatures;
	notFoundIcon: string;
	route: string;
	screenName: ScreenName;
}

export const contentContainerCategoryData: Record<
	ContentContainerCategory,
	IContentContainerCategoryData
> = {
	[ContentContainerCategory.ANNOUNCEMENT]: {
		browsePageTitle: 'announcement.browse.title',
		contentType: ContentType.ANNOUNCEMENT,
		deepLinkContentType: null,
		notFoundIcon: './assets/img/ic-not-found-announcement.svg',
		route: contentTypeData[ContentType.ANNOUNCEMENT].urlKey,
		screenName: ScreenName.ANNOUNCEMENT_LIST,
		features: {
			availableTypes: null,
			copyTranslations: false,
			code: false,
			deepLinkSupport: false,
			description: true,
			image: true,
			previewCard: true,
			previewFullscreen: true,
			requiredVideo: false,
			titleCharacterLimit: 45,
			pinning: true,
		},
	},
	[ContentContainerCategory.ONBOARDING_ANNOUNCEMENT]: {
		browsePageTitle: 'onboardingAnnouncement.browse.title',
		contentType: ContentType.ONBOARDING_ANNOUNCEMENT,
		deepLinkContentType: null,
		notFoundIcon: './assets/img/ic-not-found-onboarding-announcement.svg',
		route: contentTypeData[ContentType.ONBOARDING_ANNOUNCEMENT].urlKey,
		screenName: ScreenName.ONBOARDING_ANNOUNCEMENT_LIST,
		features: {
			availableTypes: null,
			code: false,
			copyTranslations: false,
			deepLinkSupport: false,
			description: true,
			image: true,
			previewCard: true,
			previewFullscreen: true,
			requiredVideo: false,
			titleCharacterLimit: 45,
			pinning: true,
		},
	},
	[ContentContainerCategory.HIGHLIGHT]: {
		browsePageTitle: 'highlight.browse.title',
		contentType: ContentType.HIGHLIGHT,
		deepLinkContentType: DeepLinkGeneratorContentType.HIGHLIGHT,
		notFoundIcon: './assets/img/ic-not-found-highlight.svg',
		route: contentTypeData[ContentType.HIGHLIGHT].urlKey,
		screenName: ScreenName.HIGHLIGHT_LIST,
		features: {
			availableTypes: null,
			code: false,
			copyTranslations: true,
			deepLinkSupport: true,
			description: false,
			image: false,
			previewCard: false,
			previewFullscreen: false,
			requiredVideo: true,
			titleCharacterLimit: null,
			pinning: false,
		},
	},
	[ContentContainerCategory.PROMO_CODE]: {
		browsePageTitle: 'common.browse.promoCodes',
		contentType: ContentType.PROMO_CODE,
		deepLinkContentType: null,
		notFoundIcon: './assets/img/ic-not-found-promo-code.svg',
		route: contentTypeData[ContentType.PROMO_CODE].urlKey,
		screenName: ScreenName.PROMO_CODE_LIST,
		features: {
			availableTypes: [ContentContainerType.LINK],
			code: true,
			copyTranslations: false,
			deepLinkSupport: false,
			description: true,
			image: true,
			previewCard: false,
			previewFullscreen: false,
			requiredVideo: false,
			titleCharacterLimit: 45,
			pinning: false,
		},
	},
	[ContentContainerCategory.SHOP_ANNOUNCEMENT]: {
		browsePageTitle: 'common.browse.shopAnnouncements',
		contentType: ContentType.SHOP_ANNOUNCEMENT,
		deepLinkContentType: null,
		notFoundIcon: './assets/img/ic-not-found-shop-announcement.svg',
		route: contentTypeData[ContentType.SHOP_ANNOUNCEMENT].urlKey,
		screenName: ScreenName.SHOP_ANNOUNCEMENT_LIST,
		features: {
			availableTypes: null,
			code: false,
			copyTranslations: false,
			deepLinkSupport: false,
			description: true,
			image: true,
			previewCard: true,
			previewFullscreen: false,
			requiredVideo: false,
			titleCharacterLimit: 45,
			pinning: true,
		},
	},
};
