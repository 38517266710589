/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import {
	Attribute as FormAttribute,
	BelongsTo as FormBelongsTo,
	HasMany as FormHasMany,
} from 'ngx-form-object';
import { Attribute, HasMany, HasOne, HeaderAttribute, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { HighLevelCategoryEnum } from '../enums/high-level-category.enum';
import { Language } from '../enums/language.enum';
import { ProductType } from '../enums/product-type.enum';
import { UnitSystemSlug } from '../enums/unit-system.enum';
import { removeDuplicates } from '../utils/helpers/helpers';
import {
	transformLanguageForHalApi,
	transformLanguageForJsonApi,
} from '../utils/helpers/language-transformer/language-transformer.helper';
import { sortByProperty } from '../utils/helpers/sort-by-property/sort-by-property.helper';
import { Accessory } from './accessory.hal.model';
import { Category } from './category.model';
import { Content } from './content.model';
import { Country } from './country.hal.model';
import { Device } from './device.hal.model';
import { Media } from './media.hal.model';
import { ProductMetrics } from './product-metrics.model';
import { ProductTranslation } from './product-translation.model';
import { ProductVariant } from './product-variant.model';
import { Tool } from './tool.model';

export type ProductSubclass = Device | Accessory | Tool;

@ModelConfig({
	type: 'Product',
})
export class Product extends Content {
	public static modelType = 'Product';
	public displayNameTranslationKey = 'Product';
	// Product model exists as an entity when fetching a list of Products. In that case device/accessory relationship
	// needs to be fetched as well. When fetched directly, both Accessory and Device extend Product class.
	public readonly isDevice = Boolean(this.getRelationshipUrl('device'));
	public readonly isAccessory = Boolean(this.getRelationshipUrl('accessory'));
	public readonly isTool = Boolean(this.getRelationshipUrl('tool'));

	@Attribute()
	public name: string;

	@Attribute()
	@FormAttribute()
	public model: string;

	@Attribute()
	@FormAttribute()
	public ecommerceModel: string;

	@HeaderAttribute({
		transformResponseValue: transformLanguageForJsonApi,
		transformBeforeSave: transformLanguageForHalApi,
		externalName: 'Content-Language',
	})
	// Use only for creation of the Device/Accessory resource
	public initialLanguage: Language;

	@HasOne({
		propertyClass: 'Device',
	})
	public device: Device;

	@HasOne({
		propertyClass: 'Accessory',
	})
	public accessory: Accessory;

	@HasOne({
		propertyClass: 'Tool',
	})
	public tool: Tool;

	@HasOne({
		propertyClass: Media,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public image: Media;

	@HasOne({ propertyClass: ProductMetrics })
	public metrics: ProductMetrics;

	@HasMany({
		itemsType: 'Category',
		includeInPayload: true,
	})
	public categories: Array<Category>;

	@HasMany({
		itemsType: ProductVariant,
	})
	@FormHasMany()
	public variants: Array<ProductVariant>;

	@HasMany({
		itemsType: Category,
	})
	@FormHasMany()
	public deviceCategories: Array<Category>;

	@HasOne({
		propertyClass: ProductTranslation,
		includeInPayload: true,
	})
	@FormBelongsTo()
	public defaultTranslation: ProductTranslation;

	@HasMany({
		itemsType: ProductTranslation,
	})
	@FormHasMany()
	public translations: Array<ProductTranslation>;

	public getAvailableInCountries(): Array<Country> {
		const uniqueCountries: Array<Country> = [];

		if (!this.variants?.length) {
			return [];
		}

		this.variants.forEach((variant) => {
			variant.availabilities.forEach((availability) => {
				const isCountryPresent = uniqueCountries.some(
					(country) => country.code === availability.country.code,
				);

				if (!isCountryPresent) {
					uniqueCountries.push(availability.country);
				}
			});
		});

		return uniqueCountries.sort(sortByProperty('name'));
	}

	public get deviceCtnLabel(): string {
		const variants: Array<ProductVariant> = this.variants || [];
		const combinedCtns = variants.map((variant) => variant.ctn);
		const ctns: Array<string> = variants.map((variant: ProductVariant) => {
			const isOnlyUniqueCtn: boolean =
				combinedCtns.filter((ctn) => ctn === variant.ctn).length === combinedCtns.length;
			return isOnlyUniqueCtn ? variant.ctn : `${variant.ctn.substring(0, variant.ctn.length - 1)}X`;
		});
		return removeDuplicates(ctns).join(', ');
	}

	public getDisplayName(language: Language, includeModel = false): string {
		const deviceCtns: string = this.deviceCtnLabel;
		const productLabel: string = super.getTranslatableProperty(language, 'name');

		return includeModel && deviceCtns ? `${productLabel} (${deviceCtns})` : productLabel;
	}

	@FormBelongsTo()
	public get deviceCategory(): Category {
		const categories: Array<Category> = this.categories || this.deviceCategories || [];

		return categories.find(
			(category) => category.categoryRoot?.slug === HighLevelCategoryEnum.DEVICE,
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public set deviceCategory(_value) {}

	@FormBelongsTo()
	public get deviceGroupCategory(): Category {
		const categories: Array<Category> = this.categories || [];

		return categories.find(
			(category) => category.categoryRoot?.slug === HighLevelCategoryEnum.DEVICE_GROUP,
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public set deviceGroupCategory(_value) {}

	// Product model does not depend on the selected unit system but its relationship (device) does.
	// Because of that, unitSystem is appended to the unique product model identificator.
	// This means that in the local storage, three different Product models with the same ID
	// but different unique product model identificators might exist.
	// Those are /Product/id?unitSystem=US, /Product/id?unitSystem=METRIC, /Product/id
	// The only difference between them is the device model which they are pointing to.
	// When removed, the bug occurs on the edit air cooker recipe, after changing the source product and saving the recipe
	public getUniqueModelIdentificator(): string {
		const identificator: string = super.getUniqueModelIdentificator();
		const deviceRelationshipUrl: string = this.getRelationshipUrl('device');

		if (deviceRelationshipUrl) {
			const deviceRelationshipUrlParts: Array<string> =
				this.removeTemplatedString(deviceRelationshipUrl).split('?');
			let deviceQueryParams: string =
				deviceRelationshipUrlParts.length > 1 ? deviceRelationshipUrlParts[1] : '';

			if (!deviceQueryParams) {
				deviceQueryParams = `unitSystem=${this.unitSystemFromResponse || UnitSystemSlug.METRIC}`;
			}

			return `${identificator}?${deviceQueryParams}`;
		}

		return identificator;
	}

	private get unitSystemFromResponse(): UnitSystemSlug {
		const url: string = this.rawResponse ? this.rawResponse.url : '';

		if (!url) {
			return;
		}

		const unitSystemRegExp = /unitSystem=[A-Z]+/;
		const unitSystemMatch = url.match(unitSystemRegExp);
		return unitSystemMatch ? (unitSystemMatch[0].split('=')[1] as UnitSystemSlug) : null;
	}

	private removeTemplatedString(url: string): string {
		return url.replace(/\s*\{.*?\}\s*/g, '');
	}

	public get productTypeUrl(): string {
		if (this.isDevice) {
			return 'device';
		} else if (this.isAccessory) {
			return 'accessory';
		} else if (this.isTool) {
			return 'tool';
		}

		return '';
	}

	public get productType(): ProductType | null {
		if (this.isDevice) {
			return ProductType.DEVICE;
		}
		if (this.isAccessory) {
			return ProductType.ACCESSORY;
		}
		if (this.isTool) {
			return ProductType.TOOL;
		}

		return null;
	}

	public get productSubclass(): ProductSubclass {
		return this.device || this.accessory || this.tool;
	}

	public get modelEndpoints(): ModelEndpoints {
		return {
			collectionEndpoint: this.datastore.rootApi.productCollectionEndpoint,
		};
	}
}
