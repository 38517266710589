/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { decimalAdjust } from '../decimal-adjust/decimal-adjust.helper';

export function transformSecondsToMinutes(seconds: number, numberOfDecimalPlaces = 0): number {
	const minutes: number = seconds ? seconds / 60 : null;
	return decimalAdjust(minutes, -numberOfDecimalPlaces);
}
