/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { decimalAdjust } from 'app/utils/helpers/helpers';

@Pipe({
	name: 'round',
})
export class RoundPipe implements PipeTransform {
	public transform(value: number, numberOfDecimals = 0): number {
		return decimalAdjust(value, -numberOfDecimals);
	}
}
