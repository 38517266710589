/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export function roundNumber(value, precision = 2): number {
	const precisionFactor = Math.pow(10, precision);

	return Math.round(value * precisionFactor) / precisionFactor;
}
