/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'unique' })
export class UniquePipe implements PipeTransform {
	public transform(values: Array<any>): Array<any> {
		return values.filter((value, index, array) => array.indexOf(value) === index);
	}
}
