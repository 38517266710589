/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'isNotEmpty' })
export class IsNotEmptyPipe implements PipeTransform {
	public transform(value: Array<any>): boolean {
		return value && value.length > 0;
	}
}
