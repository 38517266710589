/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { TranslateService } from '@ngx-translate/core';
import { IContentStatusData } from '../../../enums/content-status.enum';
import { ISelectInterface2 } from '../../../interfaces/select.interface';

export function statusOptions<T extends string | number>(
	contentStatusData: Partial<Record<T, IContentStatusData<T>>>,
	translateService: TranslateService,
): Array<ISelectInterface2<{ id: T }>> {
	return Object.keys(contentStatusData)
		.filter((key: string) => contentStatusData[key].visibleInFilter)
		.map((key: string) => ({
			value: {
				id: contentStatusData[key].id,
			},
			label: translateService.instant(contentStatusData[key].translateKey),
		}));
}
