/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DevLoginComponent } from './dev-login.component';
import { DevLoginGuard } from './dev-login-guard/dev-login.guard';

const ROUTES: Routes = [{ path: '', component: DevLoginComponent, canActivate: [DevLoginGuard] }];

@NgModule({
	imports: [RouterModule.forChild(ROUTES)],
	exports: [RouterModule],
})
export class DevLoginRoutingModule {}
