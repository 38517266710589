/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export const JSON_API_MODEL_CONFIG = 'JsonApiModelConfig';
export const ATTRIBUTE_CONFIG = 'Attribute';
export const BELONGS_TO = 'BelongsTo';
export const HAS_MANY = 'HasMany';
export const ATTRIBUTE_MAPPING_CONFIG = 'AttributeMapping';
export const JSON_API_DATASTORE_CONFIG = 'JsonApiDatastoreConfig';
