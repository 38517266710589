/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'groupItems' })
export class GroupItemsPipe implements PipeTransform {
	transform<T>(items: Array<T> = [], groupSize = 1): Array<Array<T>> {
		if (groupSize < 1) {
			throw new RangeError('Group size must be greater than 1');
		}

		const groupedItems: Array<Array<T>> = [];

		let index = 0;

		while (index < items.length) {
			const group: Array<T> = items.slice(index, index + groupSize);
			groupedItems.push(group);

			index += groupSize;
		}

		return groupedItems;
	}
}
