/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, BelongsTo, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import { Attribute as FormAttribute, BelongsTo as FormBelongsTo } from 'ngx-form-object';
import { RgbColor } from '../classes/rgb-color/rgb-color';
import { Recipe } from '../models/recipe.model';

@JsonApiModelConfig({
	type: 'media',
})
export class Media extends JsonApiModel {
	public config = null;

	@FormAttribute()
	public id: string;

	@Attribute()
	@FormAttribute()
	public url: string;

	@Attribute()
	@FormAttribute()
	public availableSizes: object;

	@Attribute()
	@FormAttribute()
	public primaryRed: number;

	@Attribute()
	@FormAttribute()
	public primaryGreen: number;

	@Attribute()
	@FormAttribute()
	public primaryBlue: number;

	@BelongsTo()
	@FormBelongsTo()
	public recipe: Recipe;

	public file: File;

	public get backgroundColor(): RgbColor {
		return new RgbColor({
			red: this.primaryRed,
			green: this.primaryGreen,
			blue: this.primaryBlue,
		});
	}
}
