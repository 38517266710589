/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { IDictionary } from '../interfaces/dictionary.interface';
import { IEnumData } from '../custom-types/enum-data.type';

export enum UnitSystemSlug {
	METRIC = 'METRIC',
	IMP = 'IMP',
	US = 'US',
}

export const unitSystemData: IDictionary<IEnumData<UnitSystemSlug>> = {
	[UnitSystemSlug.METRIC]: {
		id: UnitSystemSlug.METRIC,
		label: 'Metric',
		translateKey: 'unitSystem.metric',
	},
	[UnitSystemSlug.IMP]: {
		id: UnitSystemSlug.IMP,
		label: 'Imperial',
		translateKey: 'unitSystem.imperial',
	},
	[UnitSystemSlug.US]: {
		id: UnitSystemSlug.US,
		label: 'US Customary',
		translateKey: 'unitSystem.usCustomary',
	},
};
