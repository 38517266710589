/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';

@Injectable()
export class BrowserDetectorService {
	public isIEorEdge(navigator: NavigatorID): boolean {
		return (
			/Microsoft Internet Explorer/i.test(navigator.appName) ||
			/Trident/i.test(navigator.userAgent) ||
			/rv:11/i.test(navigator.userAgent) ||
			/MSIE/i.test(navigator.userAgent) ||
			/Edge/i.test(navigator.userAgent)
		);
	}
}
