/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export class JsonApiQueryData<T> {
	constructor(protected jsonApiModels: Array<T>, protected metaData?: any) {}

	public getModels(): Array<T> {
		return this.jsonApiModels;
	}

	public getMeta(): any {
		return this.metaData;
	}
}
