/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum KeyCode {
	ENTER = 13,
	ESCAPE = 27,
	L = 108,
	S = 115,
}
