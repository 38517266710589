/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HasMany, HasOne, ModelConfig } from 'ngx-hal';
import { ContentStatusHal } from '../enums/content-status.enum';
import { RecipePreparationStatus } from '../enums/recipe-preparation-status.enum';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Device } from './device.hal.model';
import { Flag } from './flag.model';
import { Media } from './media.hal.model';
import { Profile } from './profile.hal.model';
import { Recipe } from './recipe.hal.model';

@ModelConfig({
	type: 'RecipePreparation',
	endpoint: 'Recipe/Preparation',
})
export class RecipePreparation extends HalDatastoreModel {
	@Attribute()
	public status: RecipePreparationStatus;

	@Attribute()
	public createdAt: string;

	@Attribute()
	public modifiedAt: string;

	@HasOne({ propertyClass: Profile })
	public profile: Profile;

	@HasMany({ itemsType: Flag })
	public flags: Array<Flag>;

	@HasOne({ propertyClass: Media })
	public image: Media;

	@HasOne({ propertyClass: Device })
	public device: Device;

	@HasOne({ propertyClass: 'Recipe' })
	public recipe: Recipe;

	public get isFlagged(): boolean {
		return this.status === ContentStatusHal.LIVE_FLAGGED;
	}
}
