<div class="form-field-container label-{{ labelDirection }}" [ngClass]="containerClass">
	<div *ngIf="label" class="label {{ labelClass }}" [matTooltip]="labelTooltip">
		<label>{{ label }}</label>
	</div>

	<div class="element">
		<ng-content></ng-content>
	</div>
</div>

<div
	*ngIf="!warningMessageHidden && warningMessage && warningMessage.length"
	class="error-container"
>
	<div class="error error--warning">
		<mat-icon class="error__icon" svgIcon="ic-warning-info"></mat-icon>
		<span class="error__text">{{ warningMessage }}</span>
	</div>
</div>

<div *ngIf="!errorMessageHidden && formControl?.invalid" class="error-container">
	<div class="error">
		<ng-container
			*ngIf="errorsWithIconTemplate; else defaultErrorContainerTemplate"
			[ngTemplateOutlet]="errorsWithIconTemplate"
			[ngTemplateOutletContext]="{ errors: formControl?.errors }"
		></ng-container>
	</div>
</div>

<ng-template #defaultErrorContainerTemplate>
	<mat-icon class="error__icon" svgIcon="ic-close-dark-tag"></mat-icon>
	<span *ngIf="!errorsTemplate && errorMessage; else errorsTemplateContainer" class="error__text">{{
		errorMessage
	}}</span>
</ng-template>

<ng-template #errorsTemplateContainer>
	<span class="error__text">
		<ng-container
			[ngTemplateOutlet]="errorsTemplate"
			[ngTemplateOutletContext]="{ errors: formControl?.errors }"
		></ng-container>
	</span>
</ng-template>
