/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Injectable } from '@angular/core';
import { FileType } from '../../enums/file-type.enum';
import { INumberRange } from '../../interfaces/number-range.interface';

interface IAllowedMimeTypes {
	archive: Array<string>;
	content: Array<string>;
	contentTranslation: Array<string>;
	customTableDataImport: Array<string>;
}

export const ingredientsHalEnabled = false;
export const TEMPORARY_SPACE_ID = '0921897c-a457-443b-b555-5bbc7cd62985';

@Injectable()
export class ConstantService {
	public authTokenLocalStorageKey = 'auth_token';
	public loggedInTenantIdLocalStorageKey = 'tenant_id';
	public tabAll = 'all';
	public tabRecipes = 'recipe';
	public tabTips = 'tip';
	public tabConsumers = 'profile';
	public tabCollections = 'collection';
	public tabComments = 'comment';
	public tabPreparedMeal = 'prepared_meal';
	public domainFilterName = 'my-domain';
	public recipeFilterName = 'recipe';
	public tipFilterName = 'tip';
	public consumerFilterName = 'profile';
	public collectionFilterName = 'collection';
	public commentFilterName = 'comment';
	public downloadableFileNameHeader = 'Content-Disposition';
	public maximumFileSize = 20971520;
	public minTipLiveRecipes = 5;
	public defaultToastMessageDuration = 2500;
	public maxNumberOfCountries = 1000;
	public defaultAcceptHeaderValue = 'application/vnd.oneka.v2.0+json';
	public zipContentTypeHeaderValue = 'application/zip';
	public maxFractionDenominator = 99;
	public calendarMonthViewDefaultTime = 16;

	public allowedMimeTypesForUploadingRecipe: Array<string> = [
		'application/zip',
		'application/x-zip-compressed',
		'application/octet-stream',
	];

	public mimeTypeTranslationMapping: Record<string, string> = {
		'application/zip': 'mimeType.zip',
		'application/x-zip-compressed': 'mimeType.zip',
		'application/octet-stream': 'mimeType.zip',
		'application/xml': 'mimeType.xml',
		'text/xml': 'mimeType.xml',
	};

	public allowedMimeTypes: IAllowedMimeTypes = {
		archive: [
			FileType.zip.toString(),
			FileType.zipCompressed.toString(),
			FileType.octetStream.toString(),
		],
		content: [
			FileType.zip.toString(),
			FileType.zipCompressed.toString(),
			FileType.octetStream.toString(),
		],
		contentTranslation: [FileType.xml.toString(), FileType.textXml.toString()],
		customTableDataImport: [FileType.xlsx.toString()],
	};

	public features: any = {
		// example: {
		//   enabled: true
		// }
		ingredientsHal: {
			enabled: ingredientsHalEnabled,
		},
	};

	public numberOfLinkedContentInArticle: INumberRange = {
		min: null,
		max: 15,
	};
}
