/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'lastElement' })
export class LastElementPipe implements PipeTransform {
	public transform(value: Array<any>): any {
		if (value.length) {
			return value[value.length - 1];
		}
	}
}
