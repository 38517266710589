/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { TranslateService } from '@ngx-translate/core';

import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ExtendedFormArray, FormStore } from 'ngx-form-object';

import { SensitiveFieldsCheckingModalComponent } from '../../../components/sensitive-fields-checking-modal/sensitive-fields-checking-modal.component';
import { TranslationStatus, TranslationStatusHal } from '../../../enums/translation-status.enum';
import { ContentFormStore } from '../../../forms/content-form-object/content.form-store';
import { TranslationFormStore } from '../../../forms/translation/translation.form-store';
import { IContentModel } from '../../../interfaces/content-model.interface';
import { IModelTranslation } from '../../../interfaces/model-translation.interface';
import { Translation } from '../../../models/translation.model';
import { PopupDialogService } from '../../../services/popup-dialog/popup-dialog.service';

export function moveTranslationsToDraft(
	checkingResultsReasons: Array<string>,
	contentForm: FormStore,
	translateService: TranslateService,
	dialog: PopupDialogService,
	translationsField?: string,
): Observable<boolean> {
	const movedTranslationsToDraft$: Subject<boolean> = new Subject();

	const model: IContentModel = contentForm.model as IContentModel;
	const contentTranslationsForms: ExtendedFormArray = contentForm.controls[
		translationsField || `${model.modelTypePrefix}Translations`
	] as ExtendedFormArray;

	const formsToMoveToDraft: Array<FormStore> = (contentTranslationsForms.controls as Array<
		FormStore
	>).filter((contentTranslationForm: FormStore) => {
		const isDefaultTranslation: boolean =
			contentTranslationForm.controls.language.value === model.defaultTranslation.language;
		const isTranslationApproved: boolean =
			contentTranslationForm.controls.status.value.toString() ===
			TranslationStatus.APPROVED.toString();

		return !isDefaultTranslation && isTranslationApproved;
	});

	if (!formsToMoveToDraft.length) {
		return of(false);
	}

	dialog
		.setCancelButton({ label: translateService.instant('button.ok') })
		.setExtraOptions({
			checkingResultsReasons,
		})
		.open(SensitiveFieldsCheckingModalComponent, {
			width: 'auto',
		})
		.afterClosed()
		.subscribe(() => {
			const translationSaving$: Array<Observable<IModelTranslation>> = [];

			formsToMoveToDraft.forEach((contentTranslationForm: FormStore) => {
				contentTranslationForm.controls.status.setValue(TranslationStatus.DRAFT);
				translationSaving$.push(contentTranslationForm.save() as Observable<IModelTranslation>);
			});

			combineLatest(...translationSaving$)
				.pipe(
					catchError((error: any) => {
						movedTranslationsToDraft$.error(error);
						return EMPTY;
					}),
				)
				.subscribe((_) => {
					movedTranslationsToDraft$.next(true);
				});
		});

	return movedTranslationsToDraft$;
}

export function moveHalTranslationsToDraft<
	T extends ContentFormStore<IContentModel>,
	K extends TranslationFormStore,
	H extends Translation
>(
	checkingResultsReasons: Array<string>,
	contentForm: T,
	translateService: TranslateService,
	dialog: PopupDialogService,
	changeStatus: (contentTranslationForm: H, newStatus: TranslationStatusHal) => Observable<H>,
): Observable<boolean> {
	const movedTranslationsToDraft$: Subject<boolean> = new Subject();

	const contentTranslationsFormControl: ExtendedFormArray = contentForm.get(
		'translations',
	) as ExtendedFormArray;
	const contentTranslationsForms: Array<K> = contentTranslationsFormControl.controls as Array<K>;
	const formsToMoveToDraft: Array<K> = contentTranslationsForms.filter(
		(contentTranslationForm: K) => {
			const isDefaultTranslation: boolean =
				contentTranslationForm.get('language').value === contentForm.defaultLanguage;
			const isTranslationApproved: boolean =
				contentTranslationForm.get('status').value === TranslationStatusHal.APPROVED;

			return !isDefaultTranslation && isTranslationApproved;
		},
	);

	if (!formsToMoveToDraft.length) {
		return of(false);
	}

	dialog
		.setCancelButton({ label: translateService.instant('button.ok') })
		.setExtraOptions({
			checkingResultsReasons,
		})
		.open(SensitiveFieldsCheckingModalComponent, {
			width: 'auto',
		})
		.afterClosed()
		.subscribe(() => {
			const translationSaving$: Array<Observable<H>> = [];

			formsToMoveToDraft.forEach((contentTranslationForm: K) => {
				translationSaving$.push(
					changeStatus(contentTranslationForm.model, TranslationStatusHal.DRAFT),
				);
			});

			combineLatest(...translationSaving$)
				.pipe(
					catchError((error: any) => {
						movedTranslationsToDraft$.error(error);
						return EMPTY;
					}),
				)
				.subscribe((_) => {
					movedTranslationsToDraft$.next(true);
				});
		});

	return movedTranslationsToDraft$;
}
