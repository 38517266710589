/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { IDuration } from '../../../interfaces/time-duration.interface';

export function convertSecondsToDuration(durationInSeconds: number): IDuration {
	const durationInMinutes: number = durationInSeconds / 60;
	const hours: number = Math.floor(durationInMinutes / 60);
	const minutes: number = durationInMinutes - hours * 60;
	return {
		hours,
		minutes,
	};
}
