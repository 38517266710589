/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { BelongsTo, Attribute as FormAttribute, HasMany as FormHasMany } from 'ngx-form-object';
import { Attribute, HasMany, HasOne, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { ScheduledEventStatus } from '../enums/scheduled-event-status.enum';
import { ScheduledEventType } from '../enums/scheduled-event-type.enum';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import {
	addTimezoneOffset,
	removeTimezoneOffset,
} from '../utils/helpers/timezone-offset/timezone-offset.helper';
import { Article } from './article.model';
import { Author } from './author.model';
import { Collection } from './collection.hal.model';
import { ContentContainer } from './content-container.model';
import { Content } from './content.model';
import { Country } from './country.hal.model';
import { PushMessage } from './push-message.model';
import { Recipe } from './recipe.hal.model';
import { ScheduledEventCountry } from './scheduled-event-country.model';

@ModelConfig({
	type: 'ScheduledEvent',
	endpoint: 'ScheduledEvent',
})
export class ScheduledEvent extends HalDatastoreModel {
	public static modelType = 'ScheduledEvent';

	public get modelEndpoints(): ModelEndpoints {
		return {
			singleResourceEndpoint: this.datastore.rootApi.singleScheduledEventEndpoint,
			collectionEndpoint: this.datastore.rootApi.collectionScheduledEventEndpoint,
		};
	}

	@Attribute()
	@FormAttribute()
	public title: string;

	@Attribute({
		externalName: 'type',
	})
	@FormAttribute()
	public scheduledEventType: ScheduledEventType;

	@Attribute()
	public status: ScheduledEventStatus;

	@HasMany({
		itemsType: ScheduledEventCountry,
		externalName: 'countries',
	})
	@FormHasMany()
	public scheduledEventCountries: Array<ScheduledEventCountry>;

	@BelongsTo()
	public content: Content;

	@HasOne({
		propertyClass: ContentContainer,
		includeInPayload: true,
	})
	@BelongsTo()
	public announcement: ContentContainer;

	@HasOne({
		propertyClass: ContentContainer,
		includeInPayload: true,
	})
	@BelongsTo()
	public highlight: ContentContainer;

	@HasOne({
		propertyClass: ContentContainer,
		includeInPayload: true,
	})
	@BelongsTo()
	public onboardingAnnouncement: ContentContainer;

	@HasOne({
		propertyClass: ContentContainer,
		includeInPayload: true,
	})
	@BelongsTo()
	public shopAnnouncement: ContentContainer;

	@HasOne({
		propertyClass: Article,
		includeInPayload: true,
	})
	@BelongsTo()
	public article: Article;

	@HasOne({
		propertyClass: Collection,
		includeInPayload: true,
	})
	@BelongsTo()
	public collection: Collection;

	@HasOne({
		propertyClass: Recipe,
		includeInPayload: true,
	})
	@BelongsTo()
	public recipe: Recipe;

	@HasOne({
		propertyClass: PushMessage,
		includeInPayload: true,
	})
	@BelongsTo()
	public pushMessage: PushMessage;

	@Attribute()
	public createdAt: Date;

	@Attribute({
		transformResponseValue: (utcDateString: string) => {
			if (!utcDateString) {
				return utcDateString;
			}
			return addTimezoneOffset(utcDateString);
		},
		transformBeforeSave: (dateWithOffsetString: string) => {
			if (!dateWithOffsetString) {
				return dateWithOffsetString;
			}
			return removeTimezoneOffset(dateWithOffsetString);
		},
	})
	@FormAttribute()
	public scheduledAt: Date;

	// Only used with type = MAINTENANCE
	@Attribute()
	@FormAttribute()
	public duration: string;

	@HasOne({ propertyClass: Author })
	public author: Author;

	public get assignedContent():
		| Article
		| Collection
		| ContentContainer
		| Recipe
		| PushMessage
		| null {
		return (
			this.announcement ||
			this.article ||
			this.collection ||
			this.highlight ||
			this.recipe ||
			this.pushMessage ||
			this.onboardingAnnouncement ||
			this.shopAnnouncement
		);
	}

	public get countries(): Array<Country> {
		if (this.pushMessage) {
			return this.assignedContent.countries;
		}
		return this.scheduledEventCountries.map(
			(scheduledEventCountry: ScheduledEventCountry) => scheduledEventCountry.country,
		);
	}

	public get hasError(): boolean {
		return [ScheduledEventStatus.FAILED, ScheduledEventStatus.PARTIALLY_SUCCESSFUL].includes(
			this.status,
		);
	}

	public get isFullyProcessed(): boolean {
		return [
			ScheduledEventStatus.FAILED,
			ScheduledEventStatus.PARTIALLY_SUCCESSFUL,
			ScheduledEventStatus.SUCCESSFUL,
		].includes(this.status);
	}

	public displayNameTranslationKey = 'contentScheduler.scheduledEvent';
}
