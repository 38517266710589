/**
 * Copyright (C) 2021 - 2022 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export function range(start: number, end?: number, step?: number): Array<number> {
	if (end === undefined) {
		end = start;
		start = 0;
	}

	if (step === undefined) {
		step = Math.sign(end - start);
	}

	return baseRange(start, end, step);
}

function baseRange(start, end, step): Array<number> {
	let index = -1;
	let length = Math.max(Math.ceil((end - start) / (step || 1)), 0);
	const result: Array<number> = new Array(length);

	while (length--) {
		result[++index] = start;
		start += step;
	}

	return result;
}
