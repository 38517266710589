/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HasMany, JsonApiModel, JsonApiModelConfig } from 'angular2-jsonapi';
import { Attribute as FormAttribute, HasMany as FormHasMany } from 'ngx-form-object';

import { ModelMetadata } from '../classes/model-metadata/model-metadata';
import { Allergen, UnofficialAllergen } from '../enums/allergen.enum';
import { IngredientCreator } from '../enums/ingredient-creator.enum';
import { IngredientStatus } from '../enums/ingredient-status.enum';
import { IngredientType } from '../enums/ingredient-type.enum';
import { Language, languageData } from '../enums/language.enum';
import { IngredientTranslation } from '../models/ingredient-translation.model';
import { NutritionInfo } from '../models/nutrition-info.model';
import { compareArrays } from '../utils/helpers/compare-arrays/compare-arrays';
import { IngredientHistoryItem } from './ingredient-history-item.model';
import { SpecificConversion } from './specific-conversion.model';

@JsonApiModelConfig({
	type: 'ingredients',
	meta: ModelMetadata,
})
export class Ingredient extends JsonApiModel {
	public config = null;

	@FormAttribute()
	public id: string;

	@Attribute()
	@FormAttribute()
	public shortId: string;

	@Attribute()
	@FormAttribute()
	public status: IngredientStatus;

	@Attribute()
	@FormAttribute()
	public type: IngredientType;

	@Attribute()
	@FormAttribute()
	public upc: string;

	@Attribute()
	@FormAttribute()
	public referencedRecipesCount: number;

	@Attribute()
	@FormAttribute({
		isChanged: (
			initial: Array<Allergen | UnofficialAllergen>,
			current: Array<Allergen | UnofficialAllergen>,
		) =>
			!compareArrays(initial, current, {
				ignoreOrder: true,
			}),
	})
	public allergens: Array<Allergen | UnofficialAllergen>;

	@Attribute()
	@FormAttribute()
	public comment: string;

	@Attribute()
	@FormAttribute()
	public createdBy: IngredientCreator;

	@Attribute()
	@FormAttribute()
	public dbMappingAnalysed: boolean;

	@Attribute()
	@FormAttribute()
	public unitConversionsAnalysed: boolean;

	@Attribute()
	@FormAttribute()
	public allergensAnalysed: boolean;

	@Attribute()
	@FormAttribute()
	public notTranslatable: boolean;

	@Attribute()
	@FormAttribute()
	public lockedIngredient: boolean;

	@HasMany()
	@FormHasMany()
	public ingredientTranslations: Array<IngredientTranslation> = [];

	@HasMany()
	@FormHasMany()
	public historyItems: Array<IngredientHistoryItem>;

	@HasMany()
	@FormHasMany()
	public nutritionInfo: Array<NutritionInfo> = [];

	@HasMany()
	@FormHasMany()
	public specificConversions: Array<SpecificConversion> = [];

	@Attribute({
		dataType: Date,
	})
	public modifiedAt: Date;

	public getTranslation(translationLanguage?: Language): IngredientTranslation {
		let language = translationLanguage;

		if (!this.ingredientTranslations.length) {
			return {
				nameSingular: '-',
				namePlural: '-',
				language: null,
			} as IngredientTranslation;
		}

		if (!language) {
			language = this.ingredientTranslations[0].language;
		}

		const matchedTranslations: Array<IngredientTranslation> = this.ingredientTranslations.filter(
			(ingredientTranslation) => ingredientTranslation.language === language,
		);

		if (matchedTranslations.length) {
			return matchedTranslations[0];
		}

		return this.ingredientTranslations[0];
	}

	// TODO use languageService for this
	public getLanguage(language: Language = Language.GERMAN): string {
		const translation = this.getTranslation(language);
		const languageDataObject = languageData[translation.language];

		if (languageDataObject) {
			return languageDataObject.label;
		}

		return translation.language;
	}
}
