/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum ModelTypePrefix {
	ANNOUNCEMENT = 'announcement',
	ARTICLE = 'article',
	COLLECTION = 'collection',
	HEALTH_CLAIM_GUIDELINE = 'healthClaimGuideline',
	NUTRITION_CLAIM_CONTENT = 'nutritionClaimContent',
	RECIPE = 'recipe',
	TIP = 'tip',
}

export const modelTypePrefixData = {
	[ModelTypePrefix.ANNOUNCEMENT]: {
		id: ModelTypePrefix.ANNOUNCEMENT,
		translationKey: 'contentType.announcement',
	},
	[ModelTypePrefix.ARTICLE]: {
		id: ModelTypePrefix.ARTICLE,
		translationKey: 'contentType.article',
	},
	[ModelTypePrefix.RECIPE]: {
		id: ModelTypePrefix.RECIPE,
		translationKey: 'contentType.recipe',
	},
	[ModelTypePrefix.TIP]: {
		id: ModelTypePrefix.TIP,
		translationKey: 'contentType.tip',
	},
	[ModelTypePrefix.COLLECTION]: {
		id: ModelTypePrefix.COLLECTION,
		translationKey: 'contentType.collection',
	},
	[ModelTypePrefix.NUTRITION_CLAIM_CONTENT]: {
		id: ModelTypePrefix.NUTRITION_CLAIM_CONTENT,
		translationKey: 'contentType.nutritionClaimContent',
	},
	[ModelTypePrefix.HEALTH_CLAIM_GUIDELINE]: {
		id: ModelTypePrefix.HEALTH_CLAIM_GUIDELINE,
		translationKey: 'contentType.healthClaimGuideline',
	},
};
