/**
 * Copyright (C) 2021 - 2023 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { BELONGS_TO } from '../constants/json-api.constants';
import { getObjProperty } from '../helpers/class-metadata.helper';

export function BelongsTo(config: any = {}) {
	return function (target: any, propertyName: string | symbol) {
		const annotations = getObjProperty(target, BELONGS_TO, []);

		annotations.push({
			propertyName,
			relationship: config.key || propertyName,
		});
	};
}
