/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum FileType {
	octetStream = 'application/octet-stream',
	textXml = 'text/xml',
	xml = 'application/xml',
	zip = 'application/zip',
	zipCompressed = 'application/x-zip-compressed',
	xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}
