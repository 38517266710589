/**
 * Copyright (C) 2021 - 2024 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import {
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import attributeJson from '../../mocks/v2/attribute.json';
import attributeConfigJson from '../../mocks/v2/attributeConfig.json';
import attributeOptionJson from '../../mocks/v2/attributeOption.json';
import attributeOptionsJson from '../../mocks/v2/attributeOptions.json';
import attributesJson from '../../mocks/v2/attributes.json';

import administratorJson from '../../mocks/v2/administrator.json';
import administratorRootJson from '../../mocks/v2/administratorRoot.json';
import administratorsJson from '../../mocks/v2/administrators.json';
import apiJson from '../../mocks/v2/api.json';
import category1TranslationsJson from '../../mocks/v2/category1Translations.json';
import category2TranslationsJson from '../../mocks/v2/category2Translations.json';
import cookingMethodsJson from '../../mocks/v2/cookingMethods.json';
import countryJson from '../../mocks/v2/country.json';
import deviceJson from '../../mocks/v2/device.json';
import deviceCookingMethodsJson from '../../mocks/v2/deviceCookingMethods.json';
import importJobJson from '../../mocks/v2/importJob.json';
import nutrimaxProductsJson from '../../mocks/v2/nutrimaxProducts.json';
import pushMessageJson from '../../mocks/v2/pushMessage.json';
import pushMessageManagingCountriesJson from '../../mocks/v2/pushMessageManagingCountries.json';
import pushMessageTranslationJson from '../../mocks/v2/pushMessageTranslation.json';
import pushMessageTranslationsJson from '../../mocks/v2/pushMessageTranslations.json';
import pushMessagesJson from '../../mocks/v2/pushMessages.json';
import recipeJson from '../../mocks/v2/recipe.json';
import recipeCategoriesJson from '../../mocks/v2/recipeCategories.json';
import recipeIngredientsJson from '../../mocks/v2/recipeIngredients.json';
import recipeNutritionJson from '../../mocks/v2/recipeNutrition.json';
import recipeNutritionInformationJson from '../../mocks/v2/recipeNutritionInformation.json';
import recipeSearchJson from '../../mocks/v2/recipeSearch.json';
import recipeStep1TranslationsJson from '../../mocks/v2/recipeStep1Translations.json';
import recipeStep2TranslationsJson from '../../mocks/v2/recipeStep2Translations.json';
import recipeStepsJson from '../../mocks/v2/recipeSteps.json';
import recipeTranslationJson from '../../mocks/v2/recipeTranslation.json';
import recipeTranslationsJson from '../../mocks/v2/recipeTranslations.json';

interface IMockItem {
	url: string;
	json: any;
	mockEnabled: boolean;
	headers?: Record<string, string | number | Array<string | number>>;
}

const urls: Array<IMockItem> = [
	{
		url: 'https://dev.backend.ka.philips.com/api/Recipe/cc70e9d0-da24-49a9-b342-b3984cd3bad2',
		json: recipeJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/Recipe/cc70e9d0-da24-49a9-b342-b3984cd3bad2/Translation',
		json: recipeTranslationsJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/User/7a171d0e-76b6-4d80-9658-b49e8e513121',
		json: recipeTranslationsJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/Recipe/7a171d0e-76b6-4d80-9658-b49e8e513121/Ingredient',
		json: recipeIngredientsJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/RecipeTranslation/recipeTranslationId213434233',
		json: recipeTranslationJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/Recipe/7a171d0e-76b6-4d80-9658-b49e8e513121/Category',
		json: recipeCategoriesJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/Category/categoryID-756-332/Translation',
		json: category1TranslationsJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/Category/categoryID-098-123/Translation',
		json: category2TranslationsJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/Recipe/cc70e9d0-da24-49a9-b342-b3984cd3bad2/Step',
		json: recipeStepsJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/Recipe/Step/435d6rft7gy8hu/Translation',
		json: recipeStep1TranslationsJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/Recipe/Step/hh9u92jj00022iw/Translation',
		json: recipeStep2TranslationsJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/Recipe/b26d0fc5-a3bf-43f1-b5c2-ddb4241e4654/Nutrition',
		json: recipeNutritionJson,
		mockEnabled: false,
	},
	{
		url:
			// eslint-disable-next-line max-len
			'https://dev.backend.ka.philips.com/api/Recipe/Nutrition/00000000-0000-0000-0000-000000000000/Information?nutrient=CALORIES,CARBOHYDRATES,FAT,PROTEIN,ALCOHOL,ORGANIC_ACIDS&valuePer=SERVING',
		json: recipeNutritionInformationJson,
		mockEnabled: false,
	},
	{
		url:
			// eslint-disable-next-line max-len
			'https://dev.backend.ka.philips.com/api/Recipe/Nutrition/00000000-0000-0000-0000-000000000000/Information?nutrient=CALORIES,CARBOHYDRATES,SUGAR,DIETARY_FIBER,FAT,SATURATED_FAT,UNSATURATED_FATTY_ACIDS,OMEGA_3_FATTY_ACIDS,PROTEIN,SODIUM,SALT{&valuePer}',
		json: recipeNutritionInformationJson,
		mockEnabled: false,
	},
	{
		url:
			// eslint-disable-next-line max-len
			'https://dev.backend.ka.philips.com/api/Recipe/Nutrition/00000000-0000-0000-0000-000000000000/Information?nutrient=VITAMIN_A,VITAMIN_B1,VITAMIN_B2,VITAMIN_B3,VITAMIN_B5,VITAMIN_B6,VITAMIN_B7,FOLIC_ACID,VITAMIN_B12,VITAMIN_C,VITAMIN_D,VITAMIN_E,VITAMIN_K,CALCIUM,IRON,MAGNESIUM,POTASSIUM,ZINC{&valuePer}',
		json: recipeNutritionInformationJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2',
		json: pushMessageJson,
		mockEnabled: true,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/Content/5e0057d6-f1de-4559-bc76-cf7066f0eec6/Country',
		json: pushMessageManagingCountriesJson,
		mockEnabled: true,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/PushMessage/Translation/nhfds8-ca60-46e8-889d-6564h',
		json: pushMessageTranslationJson,
		mockEnabled: true,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/PushMessage',
		json: pushMessagesJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/PushMessage/ydhsd29-da24-49a9-2321gg-b3984cd3bad2/Translation',
		json: pushMessageTranslationsJson,
		mockEnabled: true,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/Product?categories=NUTRIMAX%2CDEVICE_PRODUCT',
		json: nutrimaxProductsJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/Product?categories=NUTRIMAX%2CDEVICE_PRODUCT',
		json: nutrimaxProductsJson,
		mockEnabled: false,
	},
	{
		url:
			// eslint-disable-next-line max-len
			'https://dev.backend.ka.philips.com/api/CookingMethod?unitSystem=METRIC&electricSystem=220V&page=0&size=100',
		json: cookingMethodsJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/Device/067d933d-cbc4-43aa-bdc9-5876ff248828',
		json: deviceJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/AttributeConfig/00000000-0000-0000-0000-000000000000',
		json: attributeConfigJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/AttributeConfig/00000000-0000-0000-0000-000000000000/Attribute',
		json: attributesJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000002',
		json: attributeJson,
		mockEnabled: false,
	},
	{
		url:
			// eslint-disable-next-line max-len
			'https://dev.backend.ka.philips.com/api/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000002/Option',
		json: attributeOptionsJson,
		mockEnabled: false,
	},
	{
		url:
			// eslint-disable-next-line max-len
			'https://dev.backend.ka.philips.com/AttributeConfig/Attribute/00000000-0000-0000-0000-000000000002/Option/00000000-0000-0000-0000-000000000001',
		json: attributeOptionJson,
		mockEnabled: false,
	},
	{
		url:
			// eslint-disable-next-line max-len
			'https://dev.backend.ka.philips.com/api/Device/1e916772-af2d-4994-9327-0e2567446dc5/CookingMethod?unitSystem=METRIC&electricSystem=220V&size=1000&type=RECIPE',
		json: deviceCookingMethodsJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/00000001-0000-0000-0000-d00000000000/Administrator/0000-administrator-id-0000',
		json: administratorJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/AdministratorRoot/self',
		json: administratorRootJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/00000001-0000-0000-0000-d00000000000/Administrator',
		json: administratorsJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api',
		json: apiJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Administrator/self',
		json: administratorJson,
		mockEnabled: false,
	},
	{
		url: 'http://localhost:8080/api/0921897c-a457-443b-b555-5bbc7cd62985/search/Recipe',
		json: recipeSearchJson,
		mockEnabled: false,
	},
	{
		// Country by id - Germany
		url: 'https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Country/c0000001-0000-0000-0000-cc0000000000',
		json: countryJson,
		mockEnabled: false,
	},
	{
		url: 'https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Translation/ImportJob',
		json: importJobJson,
		mockEnabled: false,
		headers: {
			Location:
				// eslint-disable-next-line max-len
				'https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Translation/ImportJob/00000000-0000-0000-0000-000000000000',
		},
	},
	{
		// eslint-disable-next-line max-len
		url: 'https://dev.backend.ka.philips.com/api/0921897c-a457-443b-b555-5bbc7cd62985/Translation/ImportJob/00000000-0000-0000-0000-000000000000',
		json: importJobJson,
		mockEnabled: false,
	},
];

@Injectable()
export class MockHttpInterceptor implements HttpInterceptor {
	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		for (const element of urls) {
			if (element.mockEnabled && request.url.split('?')[0] === element.url.split('?')[0]) {
				console.log(`Loaded mock: ${element.url}`);
				return of(
					new HttpResponse({
						status: 200,
						body: element.json,
						headers: new HttpHeaders(element.headers || {}),
					}),
				);
			}
		}

		return next.handle(request);
	}
}
